import { get_bulk_settings } from "./Component/Helper";

/******************************* REGEX ***********************************/
export const MOBILE_REGEX =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const EMAIL_REGEX = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
export const DATE_REGEX =
  /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/;

/************************ UTILS FUNCTIONS ***********************/
export function is_plural_base_on_count(count) {
  return Number(count) > 1;
}

export function is_num_even(num) {
  return num % 2 === 0;
}

export function sentenceCase(str) {
  const strArr = str.split(" ");
  return strArr
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function maskString(maskType, maskString, str, startIndex) {
  if (!str) return "";
  const strArr = str.split("");
  if (maskType === "email") {
    const emailLastIndex = strArr.indexOf("@");
    return strArr.fill(maskString, startIndex, emailLastIndex).join("");
  } else if (maskType === "mobile") {
    const mobileLastIndex = -4;
    return strArr.fill(maskString, startIndex, mobileLastIndex).join("");
  } else {
    return strArr.fill(maskString).join("");
  }
}

export function DATA_TYPE(data) {
  if (Array.isArray(data)) {
    return "array";
  } else if (data === "" || data === undefined || data === null) {
    return null;
  } else if (
    typeof data === "object" &&
    (Object.keys(data).length === 0 || Object.keys(data).length > 0)
  ) {
    return "object";
  } else {
    return typeof data;
  }
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function check_is_myc_web() {
  const host = window.location.origin;
  //'https://appv2.jewelflow.link || https://appv2.jewelflow.pro' || 'https://staging.jewelflow.link/'
  return (
    host === "http://localhost:4001" ||
    host === "https://appv2.jewelflow.pro" ||
    host === "http://appv2.jewelflow.pro" || 
    !host.includes("website") && host.includes("jewelflow.link")||
    false
  );
}

export function displayed_product_in_matrix_myc_for_secure() {
  const {
    mycat_secure_lock_image_count_based_on,
    mycat_secure_lock_image_count,
  } = get_bulk_settings([
    "mycat_secure_lock_image_count_based_on",
    "mycat_secure_lock_image_count",
  ]);

  const MIN_SCREEN_SIZE = 350;
  const MAX_SCREEN_SIZE = 1920;
  let product_count = 1;
  const displayed_product_arr = [
    { screen: MIN_SCREEN_SIZE, product: 1, mode: "less-than" },
    { screen: [MIN_SCREEN_SIZE, 767], product: 2 },
    { screen: [768, 1439], product: 3 },
    { screen: [1440, 1749], product: 4 },
    { screen: [1750, MAX_SCREEN_SIZE], product: 5 },
    { screen: MAX_SCREEN_SIZE, product: 6, mode: "greater-than" },
  ];

  if(window.innerWidth <= MIN_SCREEN_SIZE){
    const active_screen = displayed_product_arr.find(prod => typeof prod.screen === "number" && prod.screen === MIN_SCREEN_SIZE)
    product_count = active_screen.product
  }else if(window.innerWidth >= MAX_SCREEN_SIZE){
    const active_screen = displayed_product_arr.find(prod => typeof prod.screen === "number" && prod.screen === MAX_SCREEN_SIZE)
    product_count = active_screen.product
  }else{
    displayed_product_arr.forEach((prod) => {
        if (
            Array.isArray(prod.screen) &&
            window.innerWidth >= prod.screen[0] &&
            window.innerWidth <= prod.screen[1]
          ) {
            product_count = prod.product;
          }
    })
  }
 
//   displayed_product_arr.forEach((prod) => {
//     if (prod?.mode && prod?.mode === "less-than") {
//       if (window.innerWidth < prod.screen) {
//         product_count = prod.product;
//       }
//     } else if (prod?.mode && prod?.mode === "greater-than") {
//       if (window.innerWidth > prod.screen) {
//         product_count = prod.product;
//       }
//     } else {
//       if (
//         window.innerWidth >= prod.screen[0] &&
//         window.innerWidth <= prod.screen[1]
//       ) {
//         product_count = prod.product;
//       }
//     }
//   });

  if(mycat_secure_lock_image_count_based_on === "row"){
     return product_count * Number(mycat_secure_lock_image_count);
  }else{
    //mycat_secure_lock_image_count_based_on === "product"
    return Number(mycat_secure_lock_image_count)
  }
}

const companies_which_have_website = 
[
  {company_name:"SHANKESH", company_website: "shankeshjewellers.com"}, 
  {company_name:"KALASHAONLINE", company_website: "kalashaonline.com"}, 
  {company_name:"TGC", company_website: "tgcwebsite.jewelflow.link"}, 
  {company_name:"COPL", company_website: "coplwebsite.jewelflow.link"}, 
  {company_name:"BULLIONNERVE", company_website: "bullionnervewebsite.jewelflow.link"}, 
]
export function get_company_code_from_website_domain(){
    const website = window.location.hostname;
    const company = companies_which_have_website.find(company => website.includes(company.company_website))
    if(company){
      return btoa(company.company_name)
    }else{
      return null;
    }
}
