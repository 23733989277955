import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import {useNavigate} from "react-router-dom"
import { createSearchURLParams, get_property_settings } from '../Helper';
import { IoMdArrowRoundForward } from "react-icons/io";

function CategoryListGroup({subCategories, setShowCategoryModal}) {
  const navigate = useNavigate()
  const show_hide_category_counts = get_property_settings("show_hide_category_counts") === "show";
  
  return (
    <ListGroup>
      {
        subCategories.map(category => (
        <ListGroup.Item key={category.id}
        onClick={()=>{
          
            if(Array.isArray(category?.subcategory) && category?.subcategory.length > 0){
              navigate(createSearchURLParams('home', {cat_id: category?.id}))
            }else{
              setShowCategoryModal(false)
              navigate(createSearchURLParams('matrix-page', {cat_id: category?.id, mode:'filter', page: 0}))
            }
        }}
        >
          <a href="" onClick={(e)=>e.preventDefault()}>{category.cat_name}</a>
          {show_hide_category_counts && <span className='product-count'>
                  {category?.product_count || 0}
          </span>}
          {Array.isArray(category?.subcategory) && category?.subcategory.length > 0 && <IoMdArrowRoundForward className="forword-arrow"/>}
        </ListGroup.Item>
        ))
      }
      
    </ListGroup>
  )
}

export default CategoryListGroup