import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  createSearchURLParams,
  delete_informationFromSessionID,
  get_informationFromSessionID,
} from "../../Helper";


function MobileSingleMenuList({ category, setOpenMobileMenu }) {
  const [openList, showOpenList] = useState(false);
  

  function NestedList({ cat }) {
    const [openInnerList, showOpenInnerList] = useState(false);
    if (cat.subcategory && cat.subcategory.length > 0) {
      return (
        <ul>
          <li className={`dropdown ${openInnerList ? "active" : ""}`}>
            <span
              className="menu-toggle"
              onClick={() => showOpenInnerList((pre) => !pre)}
            ></span>
            <a href="" onClick={(e) => {
              e.preventDefault();
              showOpenInnerList((pre) => !pre)
            }}>
              {cat?.cat_name}
            </a>
            <ul
              className="sub-menu"
              style={{ display: openInnerList ? "block" : "none" }}
            >
              {cat.subcategory.map((cat) => (
                <li key={cat?.id}>
                  <Link
                    to={createSearchURLParams("matrix-page", {
                      cat_id: cat.id,
                      mode: "filter",
                      page: 0,
                    })}
                    onClick={() => {
                      setOpenMobileMenu(false);
                    }}
                    className="categoryLinks"
                    key={cat.id}
                  >
                    {cat.cat_name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      );
    } else {
      return (
        <Link
          to={createSearchURLParams("matrix-page", {
            cat_id: cat.id,
            mode: "filter",
            page: 0,
          })}
          onClick={() => {
            delete_informationFromSessionID("filterParams");
            // setTimeout(() => window.location.reload(), 100)
            setOpenMobileMenu(false);
          }}
          className="categoryLinks"
        >
          {cat.cat_name}
        </Link>
      );
    }
  }

  return (
    <li className={`dropdown ${openList ? "active" : ""}`}>
      <span
        className="menu-toggle"
        onClick={() => showOpenList((pre) => !pre)}
      ></span>
      <a href="" onClick={(e) => {e.preventDefault(); showOpenList((pre) => !pre)}}>
        {category?.cat_name}
      </a>
      <ul className="sub-menu" style={{ display: openList ? "block" : "none" }}>
        {
          <li className="ec-menu-content">
            {category?.subcategory?.map((cat) => {
              return <NestedList cat={cat} key={cat.id} />;
            })}
          </li>
        }

      </ul>
    </li>
  );
}

export default MobileSingleMenuList;
