import { useDispatch, useSelector } from "react-redux";
import { get_fsv_products_calc, get_fsv_quantity, get_is_myc_web, set_FSV_Handler } from "../../../../../../../redux/Reducer/reducer";
import { calculate_prod, FSV_ACTIONS, FSV_data_url, handle_fsv_button_value } from "../../../../../Helper";
import { useNavigate } from "react-router-dom";


function Button({ short_code, selectedProduct, item, register }) {
  const product_calc = useSelector(get_fsv_products_calc);
  const fsv_count = useSelector(get_fsv_quantity);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const is_myc_web = useSelector(get_is_myc_web);
  const mode = is_myc_web ? "my_catalogue" : "filter";

  return (
    <div
      className="ec-pro-variation-content col-sm-7 overflow-auto"
      id="btnView"
    >
      <div
        id="inner-btnView"
        className="product-details-btn-value"
        style={{ minWidth: "fit-content" }}
      >
        {selectedProduct[item.short_code].map((value, i) => {
          let button_value = handle_fsv_button_value(short_code, value);
          button_value = button_value.constructor === Object ? (button_value?.label === "" ? {...button_value, label: " "} : button_value) : button_value
          console.log(button_value)
          return (
            <div key={i}>
              <input
                type="radio"
                id={short_code + (button_value?.value || button_value)}
                {...register(short_code)}
                value={button_value?.value || button_value}
                style={{ display: "none" }}
              />
              <label
                id="btn-span"
                htmlFor={short_code + (button_value?.value || button_value)}
                className={
                  // selected_prod_calc[short_code] === value
                  //   ? `filter-buttons btn_primary_foreground_color btn_primary_color button_border border_radius selected btn-span`
                  //   : "filter-buttons secondary_button border_radius btn-span"
                  "filter-buttons secondary_button border_radius btn-span"
                }
                onClick={() => {
                  if(short_code === 'selection_variant_catalogue'){
                    navigate(FSV_data_url({product_id: (button_value?.value || button_value), mode}))
                  }
                  else if(short_code === 'cc_hook' || short_code === 'tone'){

                    dispatch(
                      set_FSV_Handler({
                        type: FSV_ACTIONS.SET_PRODUCT_CALC,
                        payload: {
                          product_name: selectedProduct?.category_name,
                          data: { [short_code]: (button_value?.value || button_value) },
                        },
                      })
                    );
                  }else{

                    calculate_prod(selectedProduct, product_calc, short_code, (button_value?.value || button_value), fsv_count, dispatch)
                  }
                }}
              >
                {(button_value?.label || button_value)}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Button;
