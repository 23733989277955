import { get_informationFromSessionID } from "../Component/Helper";
import { Company } from "./CompanyCustomization";

export const CustomizedComapaniesList = {
  KALASHAONLINE: new Company({
    new_arrivals: "KALASHA BEST SELLER",
    custom_menu: [
      { name: "Gold Schemes", link: "https://kalashajewels.com/savings-scheme.php" },
      { name: "Book Video Call", link: "https://kalashajewels.com/videocall.php" },
    ],
    horizontal_menu: {status: true, custom_dropdown_style: {mega_menu: {width: 'min(1000px, 100vw)'}, columns: {gridTemplateColumns: 'repeat(4, 1fr)'}}, LIST_MAX_COUNT: 5},
    hide_elements: ["Qty_Remark"]
  }),
};



export const getCustomValue = (value) => {
  const companyCode = get_informationFromSessionID("companyCode");

  const decryptCompanyName = atob(companyCode);
  const companyCustom = CustomizedComapaniesList[decryptCompanyName];
  if (typeof value === "string" && companyCustom) {
    return companyCustom[value];
  }

  return null;
};
