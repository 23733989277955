import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { ApiList} from "../../Api/ApiList";

import {
  get_informationFromSessionID,
  ToastError,
} from "../../Component/Helper";
import { fetch_data_from_api } from "../../Api/APICalls";
import { maskString } from "../../utils";

function RegistrationVerification({ ResOTP, formData, refetchOTP}) {
  const [show, setShow] = useState(false);

  const [otp, setOtp] = useState("");
  const [showResendButton, setShowResendButton] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const [resData, setResData] = useState();
  const navigate = useNavigate();
  const company_code = get_informationFromSessionID("companyCode");

  const handleResend = () => {
    setOtp("")
    refetchOTP()
    setShowResendButton(false);
    setCountdown(60);
  };

  const startCountdown = () => {
    setTimeout(() => {
      setShowResendButton(true);
    }, 60000);
  };

  useEffect(() => {
    if (!showResendButton) {
      startCountdown();
    }
  }, [showResendButton]);

  useEffect(() => {
    if (!showResendButton && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [showResendButton, countdown]);

  const verifyOTP = async () => {
    if (ResOTP === otp) {
        const userRegistration_response = await fetch_data_from_api(`${ApiList.UserRegistration}`, 'registration', {
          login_from: "web",
          lat: 0,
          long: 0,
          device_model: "",
          device_ip: "",
          ref_code: "",
          device_id: "",
          "one-signal-user-id": "",
          gen_referral_code: "",
          string_random_internal: 7723595534,
          ...formData
        })
       
        
        if (userRegistration_response?.error) {
          return ToastError(userRegistration_response?.error)
        }else{
          setResData(userRegistration_response);
          setShow(true);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }

    }
  };

  const handleKeyDown = (event) => {
    const { ctrlKey, shiftKey, key } = event;
    const lowercaseKey = key.toLowerCase();

    if (ctrlKey && shiftKey && lowercaseKey === "i") {
      event.preventDefault();
    }

    if (ctrlKey && shiftKey && lowercaseKey === "j") {
      event.preventDefault();
    }

    if (ctrlKey && lowercaseKey === "u") {
      event.preventDefault();
    }
    if (event.key === "F12" || event.keyCode === 123) {
      event.preventDefault();

      // console.log(
      //   "Opening developer tools is disabled. Please do not use developer tools without proper authorization."
      // );
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();

    // console.log(
    //   "Right-click is disabled. Developer tools should not be accessed without proper authorization."
    // );
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="container" id="Verification">
      <div className="outer-box w-100 d-flex justify-content-center align-items-center">
        <div className="box text-center border rounded d-flex flex-column justify-content-center align-items-center p-5">
          <div className="h1 m-1 ">VERIFICATION CODE</div>
          <div className="m-1 font-weight-light">
            Please enter 6 digit code sent on
            <span className="font-weight-normal">
              {" "}
              {formData?.country_code} {maskString("mobile", "X", formData?.mobile_no, 0)}
            </span> and{" "}
            <span className="font-weight-normal"> {maskString("email", "X", formData?.email_id, 4)}</span>
          </div>
          <span className="ec-login-wrap">
          <div className="otp-inputs">
            <OTPInput
              id="input"
              inputType="number"
              value={otp}
              onChange={setOtp}
              isInputNum={true}
              numInputs={6}
              // renderSeparator={<div className="mx-1 mx-sm-3"></div>}
              shouldAutoFocus
              renderInput={(props) => (
                <input type="number" {...props} className="no-selection fieldValue input-opt-box" />
              )}
              inputStyle={{ border: "1px solid #eee" }}
            />
          </div>
          </span>
          <div className="m-1">
            <button
              disabled={otp.length === 6 ? false : true}
              onClick={() => {
                verifyOTP();
              }}
              className="btn btn-primary primary_button"
            >
              Verify OTP
            </button>
          </div>
          <div className="m-1">
            {showResendButton ? (
              <button onClick={handleResend}>Resend Verification Code</button>
            ) : (
              <p>Resend in {countdown} seconds</p>
            )}
          </div>
          <div className="m-1">
            <button className="font-weight-bold">No Code?</button>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        centered
        close
        onHide={() => setShow(false)}
        id="verificationModal"
      >
        <ModalHeader closeButton={true}></ModalHeader>
        <ModalBody className="d-flex flex-column justify-content-center">
          <div className="CheckIcon">
            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "green" }} />
          </div>
          <div className="h1">Welcome</div>
          <div className="font-weight-light h4">{resData && resData.msg}</div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default RegistrationVerification;
