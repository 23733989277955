import { useDispatch, useSelector } from "react-redux";
import {
  get_fsv_products_calc,
  get_fsv_quantity,
  get_is_myc_web,
  set_FSV_Handler,
} from "../../../../../../../redux/Reducer/reducer";
import { calculate_prod, FSV_ACTIONS, FSV_data_url, handle_fsv_button_value } from "../../../../../Helper";
import { useNavigate } from "react-router-dom";


function ButtonFlow({ short_code, selectedProduct, item, register }) {
  const product_calc = useSelector(get_fsv_products_calc);
  const fsv_count = useSelector(get_fsv_quantity);
  const is_myc_web = useSelector(get_is_myc_web);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  
  const mode = is_myc_web ? "my_catalogue" : "filter";
  // if (!selected_prod_calc) return null;
  
  return (
    <div className="ec-pro-variation-content col-sm-7 buttonflow">
      <ul>
        {selectedProduct[item.short_code].length > 0 &&
          selectedProduct[item.short_code].map((value, i) => {
            let button_value = handle_fsv_button_value(short_code, value);
            button_value = button_value.constructor === Object ? (button_value?.label === "" ? {...button_value, label: " "} : button_value) : button_value
            //console.log(button_value)
            return (
              <li key={i}>
                <input
                  type="radio"
                  id={short_code + (button_value?.value || button_value)}
                  {...register(short_code)}
                  value={button_value?.value || button_value}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor={short_code + (button_value?.value || button_value)}
                  className="buttonflow-btn"
                  
                  onClick={() => {
                    if(short_code === 'selection_variant_catalogue'){
                      navigate(FSV_data_url({product_id: (button_value?.value || button_value), mode}))
                    } else if(short_code === 'cc_hook' || short_code === 'tone'){

                      dispatch(
                        set_FSV_Handler({
                          type: FSV_ACTIONS.SET_PRODUCT_CALC,
                          payload: {
                            product_name: selectedProduct?.category_name,
                            data: { [short_code]: (button_value?.value || button_value) },
                          },
                        })
                      );
                    }else{

                      calculate_prod(selectedProduct, product_calc, short_code, (button_value?.value || button_value), fsv_count, dispatch)
                    }

                  }}
                >
                  <span id="btn-span" className="buttonflow-btn-inner btn-span">
                    {(button_value?.label || button_value)}
                  </span>
                </label>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default ButtonFlow;
