import React from "react";
import { get_bulk_settings, formatPriceWithRupee } from "../../../../../Component/Helper";

function OtherChargesDetails({data, calc_GST_amt}) {
    const {fsv_other_charges_details_title, labour_amount_column_show_rupee_symbol} = get_bulk_settings(["fsv_other_charges_details_title","labour_amount_column_show_rupee_symbol"]) || {};
    
  return (
    <div className="details-block">
      {(data?.other_label.length !== 0 ||
        data?.taxes.length !== 0) && (
          <div className="heading">
            <h5 className="ec-single-title">
              {fsv_other_charges_details_title}
            </h5>
          </div>
        )}
      {data?.other_label && data?.other_label.length > 0 && (
        <>
          {data?.other_label &&
            data?.other_label.length > 0 &&
            data?.other_label.map((item, i) => {
              return (
                <div key={i}>
                  <div
                    className="d-flex justify-content-between product-other-details-bottom-group"
                    key={i}
                  >
                    <div className="label product-details-label">
                      <span>{item}</span>{" "}
                    </div>
                    <div className="ml-auto product-details-value">
                      {labour_amount_column_show_rupee_symbol === "Yes" ? (
                        <span>
                          {" "}
                          {data?.other_values[i] &&
                            formatPriceWithRupee(
                              Math.ceil(data?.other_values[i])
                            )}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          {data?.other_values[i] &&
                            Math.ceil(data?.other_values[i])}
                        </span>
                      )}
                    </div>
                    {/* <div className='ml-auto'><span > {data?.other_values[i] && Math.ceil(data?.other_values[i])}</span></div> */}
                    <br />
                  </div>
                </div>
              );
            })}
        </>
      )}

      {data?.taxes &&
        data?.taxes.length > 0 &&
        data?.taxes.map((item, i) => {
          return (
            <div key={i}>
              <div
                className="d-flex justify-content-between product-other-details-bottom-group"
                key={i}
              >
                <div className="label product-details-label">
                  <span>{item.tax_name}</span>{" "}
                </div>
                <div className="ml-auto product-details-value">
                  {labour_amount_column_show_rupee_symbol === "Yes" ? (
                    <span>
                      {" "}
                      {formatPriceWithRupee(
                        item.tax_name.toUpperCase() === "GST"
                          ? calc_GST_amt || item.final_cost
                          : item.final_cost
                      )}
                    </span>
                  ) : (
                    <span>
                      {" "}
                      {item.tax_name.toUpperCase() === "GST"
                        ? calc_GST_amt || item.final_cost
                        : item.final_cost}
                    </span>
                  )}
                </div>
                <br />
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default React.memo(OtherChargesDetails);
