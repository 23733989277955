import React from 'react'
import UPSSingleCard from './UPSSingleCard';
import CardHeader from '../CardHeader/CardHeader';
import { get_property_settings } from '../../Helper';


function USPSection({uspData}) {

const usp_heading = get_property_settings("USP_HEADING");
const cdn = get_property_settings("cdn");



return (
    <section className="section ec-services-section section-space-p usp-section">
        <div className="container-fluid">
        <CardHeader title={usp_heading} clsName={'mb-2 mb-lg-0'}/>
            <div className="row mb-minus-30">
                {uspData?.map( usp => {
                  const img = `${cdn}uploads/usp_uploads/${usp?.usp_img}`
                  
                  return <UPSSingleCard title={usp?.title} desc={usp?.description} img={img} key={usp?.position}/>

                })}
            </div>
        </div>
    </section>
  )
}

export default React.memo(USPSection);
