import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function ZoomImageSection({ imagepath }) {
  return (
    
      <TransformWrapper>
        <TransformComponent>
          <img src={`${imagepath}`} alt={"zoom-img"} className="zoom-img" />
        </TransformComponent>
      </TransformWrapper>
    
  );
}

export default ZoomImageSection;
