import React, { useCallback, useEffect, useState } from "react";
import MobileSingleMenuList from "./MobileSingleMenuList";
import { Link } from "react-router-dom";
import {
  closeMenuWhenClickedOutside,
  get_informationFromSessionID,
  get_property_settings,
  get_userData_from_activeUser,
  logOutUser,
} from "../../Helper";
import ModalDialog from "../../ModalDialog";
import { useSelector } from "react-redux";
import {
  getCategories,
  get_is_myc_web,
  get_myc_allow_new_registration,
} from "../../../redux/Reducer/reducer";
import BrowseByCategory from "./BrowseByCategory";
import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";
import { FaVideo } from "react-icons/fa";

function HeaderMobileMenu({ setOpenMobileMenu, openMobileMenu, categories }) {
  const social_fb_link = get_property_settings("social_fb_link") || "/";
  const social_insta_link = get_property_settings("social_insta_link") || "/";
  const social_twitter_link =
    get_property_settings("social_twitter_link") || "/";

  const userID = get_informationFromSessionID("userID");

  const showBrowseByCategory =
    userID &&
    get_userData_from_activeUser().show_design === "Yes" &&
    get_userData_from_activeUser().show_inventory === "Yes";
  const is_myc_web = useSelector(get_is_myc_web);

  const [showModal, setShowModal] = useState(false);
  const userName = userID && get_userData_from_activeUser().full_name;

  const [showCurrency, setShowCurrency] = useState(false);
  let myc_allow_new_registration = useSelector(get_myc_allow_new_registration);

  const custom_menu = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.custom_menu)

  const handleMouseClick = useCallback(
    (e) => {
      closeMenuWhenClickedOutside(e, showCurrency, setShowCurrency, [
        "dropdown-toggle",
        "dropdown-item",
        "eci-caret-down",
      ]);
    },
    [showCurrency]
  );

  

  useEffect(() => {
    window.addEventListener("click", handleMouseClick);
    return () => {
      window.removeEventListener("click", handleMouseClick);
    };
  }, [showCurrency]);
  

  
 
  
  
  return (
    <div
      id="ec-mobile-menu"
      className={`ec-side-cart ec-mobile-menu header-mobile-menu ${
        openMobileMenu ? "ec-open" : ""
      }`}
    >
      <ModalDialog
        show={showModal}
        setShow={setShowModal}
        action={logOutUser}
        toastMsg={"Your Successfully logout"}
        setOpenMobileMenu={setOpenMobileMenu}
      />
      <div className="ec-menu-title">
        <span className="menu_title">Categories</span>
        <button className="ec-close" onClick={() => setOpenMobileMenu(false)}>
          ×
        </button>
      </div>
      <div className="ec-menu-inner">
        <div className="ec-menu-content">
          {showBrowseByCategory && !is_myc_web && (
            <div className="browse-by-category-mobile">
              <h3>Browse by Category</h3>
              <BrowseByCategory />
            </div>
          )}
          {is_myc_web ? null : (
            <ul>
              <li>
                <Link to="/home" onClick={() => setOpenMobileMenu(false)}>
                  Home
                </Link>
              </li>
              {Array.isArray(categories) && categories?.map((category) => (
                <MobileSingleMenuList
                  key={category.id}
                  category={category}
                  setOpenMobileMenu={setOpenMobileMenu}
                />
              ))}
              {custom_menu && userID ? <li>
                {custom_menu?.map((menu) => {
                 
                 const isVideoMenu = menu.name.toLowerCase().includes("video")

                  return <a href={menu?.link} target="_blank" key={menu?.name} className={isVideoMenu ? 'video_call_menu' : ''}>
                    {isVideoMenu ? <FaVideo className="video_call_icon"/> : null}
                    <span>{menu?.name}</span>
                  </a>
                }

                )}
              </li> : null}
            </ul>
          )}
        </div>
        <div className="header-res-lan-curr">
          <div className="header-top-lan-curr">
            {/* -- Language Start -- */}

            {!userID && (
              <div className="mobile-menu-login-reg">
                {is_myc_web && !myc_allow_new_registration ? null : (
                  <Link
                    to="/registration"
                    onClick={() => setOpenMobileMenu(false)}
                  >
                    Register
                  </Link>
                )}
                <Link to="/login" onClick={() => setOpenMobileMenu(false)}>
                  Login
                </Link>
              </div>
            )}

            {/* -- Language End -- */}
            {/* -- Currency Start -- */}
            {userID && (
              <div className="header-top-curr dropdown">
                <button
                  className={`dropdown-toggle text-upper ${
                    showCurrency ? "show" : ""
                  }`}
                  data-bs-toggle="dropdown"
                  onClick={() => setShowCurrency((pre) => !pre)}
                >
                  Welcome, {userName}
                  <i className="ecicon eci-caret-down" aria-hidden="true"></i>
                </button>
                <ul className={`dropdown-menu ${showCurrency ? "show" : ""}`}>
                  <li
                    className="active"
                    onClick={() => {
                      setShowCurrency(false);
                      setShowModal(true);
                    }}
                  >
                    <span className="dropdown-item">Logout</span>
                  </li>
                  {/* <li><a className="dropdown-item" href="#" onClick={()=>setShowCurrency(false)}>EUR €</a></li> */}
                </ul>
              </div>
            )}
            {/* -- Currency End -- */}
          </div>
          {/* -- Social Start -- */}
          <div className="header-res-social">
            <div className="header-top-social">
              <ul className="mb-0">
                <li className="list-inline-item">
                  <a
                    className="hdr-facebook"
                    href={social_fb_link}
                    target="_blank"
                  >
                    <i className="ecicon eci-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="hdr-twitter"
                    href={social_twitter_link}
                    target="_blank"
                  >
                    <i className="ecicon eci-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="hdr-instagram"
                    href={social_insta_link}
                    target="_blank"
                  >
                    <i className="ecicon eci-instagram"></i>
                  </a>
                </li>
                {/* <li className="list-inline-item"><a className="hdr-linkedin" href="#"><i
                                            className="ecicon eci-linkedin"></i></a></li> */}
              </ul>
            </div>
          </div>
          {/* -- Social End -- */}
        </div>
      </div>
    </div>
  );
}

export default React.memo(HeaderMobileMenu);
