import Modal from 'react-bootstrap/Modal';
import { IoSearchOutline } from "react-icons/io5";
import "./SearchModalCSS.css"
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState, useTransition } from 'react';
// import { debounce } from '../../utils';
import { fetch_data_from_api, fetch_data_from_api_from_query } from "../../Api/APICalls"
import { ApiList } from '../../Api/ApiList';
import NoResultFound from './NoResultFound';
import { createSearchURLParams, get_informationFromSessionID, get_property_settings } from '../Helper';
import debounce from 'debounce';
import { useQuery } from "@tanstack/react-query";
import Loader from '../Loader';
import WaitSearch from './WaitSearch';

function SearchModal({ showSearch, setShowSearch }) {
  const [search, setSearch] = useState("")
  const [isPending, starTransition] = useTransition()
  const [suggestionList, setSuggestionList] = useState([])
  const [filterSuggestionList, setFilterSuggestionList] = useState([])
  const search_input_ref = useRef(null)
  const userID = get_informationFromSessionID("userID")
  const product_search_based_on = get_property_settings("product_search_based_on");
  const search_type_param_key = product_search_based_on === "item_code" ? "search_item_code" : product_search_based_on
 

  const search_keyword_query = useQuery({
    queryKey: ["search-keyword"],
    queryFn: () => fetch_data_from_api_from_query(ApiList.Search_keywords, "search-keyword", {
      session_id: "",
      design_status: 1,
    }).then(response => response.keyword.filter(value => value !== "")),
    enabled: !!userID
  });

  function filter_search_result() {
    
    if (suggestionList?.length === 0) return
    
    if (search === "") {

      setFilterSuggestionList([])

    } else {
      const shallowCopy = [...suggestionList]
      
        setFilterSuggestionList(() => {
          return shallowCopy.filter(result => result.toLowerCase().indexOf(search.toLowerCase()) > -1)
        })
      
    }
  }

  useEffect(() => {
    setSuggestionList(search_keyword_query?.data)
  }, [search_keyword_query?.isSuccess])
  

  useEffect(()=>{
    if(showSearch){

      search_input_ref.current?.focus({ focusVisible: true });
      setTimeout(() => {
        
        search_input_ref.current?.focus({ focusVisible: true });
      }, 300)
    }else{
      setSearch("")
    }
  }, [showSearch])

  useEffect(() => {
    starTransition(() => filter_search_result())
  }, [search])

  function getHighlightedText(result, search) {
    // Split text on higlight term, include term itself into parts, ignore case
    const ignore_invaild_regex = /[\[\\]/g;
    
    const parts = result.split(new RegExp(`(${search.replace(ignore_invaild_regex, "")})`, "gi"));
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part.toLowerCase() === search.toLowerCase() ? (
          <b className='highlighted-text'>{part}</b>
        ) : (
          part
        )}
      </React.Fragment>
    ));
  }

  
  function handleLinkClick(){
    setSearch("")
    setShowSearch(false)
  }
  
  function handleSearch(e) {
    setSearch(e.target.value)
  }
  
  if(search_keyword_query?.isLoading){
    return null;
  }
  
  return (

    <Modal show={showSearch} onHide={setShowSearch} className='Search-Container'>
      <Modal.Header closeButton>
        <Modal.Title className='search-header'>
          <IoSearchOutline className='search-icon' />
          <input type='text' className='search-input' placeholder="What are you looking for?"
            // value={search}
            onChange={debounce(handleSearch, 400)}
            ref={search_input_ref}
            autoFocus={true}
            
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`search-list ${search !== "" ? 'search-active' : ''}`}>
        
        {
          !search ? null : isPending ? <WaitSearch keyWord={search} totalCount={suggestionList.length}/> : filterSuggestionList.length > 0 ? filterSuggestionList.map(filterList => <Link 
          onClick={handleLinkClick}
          className='search-result' 
          to={createSearchURLParams('matrix-page', {
            mode: "product_search_online",
            page: 0,
            sort: 0,
            [search_type_param_key]: filterList
          })} 
          key={filterList}>
            {getHighlightedText(filterList, search)}

          </Link>) : null
        }

        
        {filterSuggestionList.length === 0 && search !== "" && !isPending ? <NoResultFound search={search} setSearch={setSearch} /> : null}

      </Modal.Body>

    </Modal>

  );
}

export default React.memo(SearchModal)
