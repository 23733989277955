import ReactSlider from "../../ReactSlider/ReactSlider";
import StoreCard from "./components/StoreCard";
import { useQuery } from "@tanstack/react-query";
import "./StoreSection.css";
import { ApiList } from "../../../Api/ApiList";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { get_bulk_settings } from "../../Helper";
import NoImg from "../../../assets/images/no_image_available.png"

function StoreSection() {

  const {cdn, location_banner_BG} = get_bulk_settings(["cdn", "location_banner_BG"]) || {}
  const location_banner = location_banner_BG ? `${cdn}uploads/location_banner_image/${location_banner_BG}` : NoImg;
 

  const { data, isLoading } = useQuery({
    queryKey: ["location"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.Location, "location", {
        device: "web",
      }),
    staleTime: Infinity,
  });

  // const dummy = [...data?.location, ...data?.location]

  return (
    <>
    {!isLoading && data?.location?.length ? <div className="store-section-container">
      <img
        src={location_banner}
        alt="store-bg-image"
        className="store-bg-image"
      />
      <div className="store-card-container overflow-scroll example">
        {
          data?.location?.map(locationData => <StoreCard key={locationData?.title} {...locationData}/>)
        }
      </div>

    </div> : null}
    </>
  );
}

export default StoreSection;
