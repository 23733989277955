
import { get_informationFromSessionID } from "./Component/Helper";
import { Navigate } from "react-router-dom"


const AuthRoutes = ({ children}) => {

  const userID = get_informationFromSessionID("userID")
 

  // if(location.pathname === "/registration" && is_myc_secured && !is_myc_allow_new_registration){
  //   return <Navigate to="/login"/>
  // }

  if(!userID) {
    return (
      <div className="auth-comp">{ children }</div>
    );
  }

  
  
 return <Navigate to="/"/>
};

export default AuthRoutes;
