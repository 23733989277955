import React from "react";
import { get_bulk_settings, formatPriceWithPercentage, formatPriceWithRupee } from "../../../../../Component/Helper";

function LabourDetails({data, calc_making_charge, calc_wasteage}) {
    const {fsv_labour_details_title, labour_amount_column_show_rupee_symbol, labour_amount_column_title} = get_bulk_settings(["fsv_labour_details_title","labour_amount_column_show_rupee_symbol", "labour_amount_column_title"]) || {};
  return (
    <div className="details-block">
      {data?.show_labour_details === "1" && data?.labour_details.length > 0 && (
        <>
          <div className="heading mb-3">
            <h5 className="ec-single-title">{fsv_labour_details_title}</h5>
          </div>
          <div>
            <table className="w-100">
              <thead>
                <tr className="row product-other-details-bottom-group">
                  <td className="label col text-left product-details-label">
                    TYPE
                  </td>
                  <td className="label col text-center product-details-label">
                    RATE
                  </td>
                  <td className="label col text-right product-details-label">
                    {labour_amount_column_title}
                  </td>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data?.labour_details.length > 0 &&
                  data?.labour_details.map((d, _) => {
                    return (
                      <React.Fragment key={_}>
                        {d?.visible_on_frontend === "1" && (
                          <tr className="row product-other-details-bottom-group">
                            <td className="col text-left product-details-value">
                              {d.labour_name}
                            </td>
                            {d.type !== "1" &&
                            labour_amount_column_show_rupee_symbol === "Yes" ? (
                              <td className="col text-center product-details-value">
                                {formatPriceWithRupee(d.rate)}
                              </td>
                            ) : (
                              <td className="col text-center product-details-value">
                                {formatPriceWithPercentage(d.rate)}

                              </td>
                            )}
                            {labour_amount_column_show_rupee_symbol ===
                            "Yes" ? (
                              <td className="col text-right product-details-value">
                                {formatPriceWithRupee(
                                  d.labour_name === "Making Charges"
                                    ? calc_making_charge || d.amount
                                    : d.labour_name === "Wastage"
                                    ? calc_wasteage || d.amount
                                    : d.amount
                                )}
                              </td>
                            ) : (
                              <td className="col text-right product-details-value">
                                {d.labour_name === "Making Charges"
                                  ? calc_making_charge || d.amount
                                  : d.labour_name === "Wastage"
                                  ? calc_wasteage || d.amount
                                  : d.amount}
                              </td>
                            )}
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}

                {/* {data &&
                    data.material_position.length >
                    0 &&
                    data.material_position.map(
                    (title, i) => {
                        
                        return (
                        <tr key={i}>
                            <td>
                            {/* <ul>
                                                            {data[title.short_code] &&
                                                            data[title.short_code].length > 0 &&
                                                            data[title.short_code].map((item) => {
                                                                return (
                                                                    <li key={item.id}>
                                                                        {item.mat_name} - Cts: {item.cts}, Weight: {item.weight}
                                                                    </li>
                                                                )
                                                            })} 
                                                    </ul> 
                            </td>
                        </tr>
                        );
                    }
                    )} */}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(LabourDetails);
