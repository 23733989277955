import React from 'react'
import Textfield from './Textfield/Textfield'
import Telephone from './Telephone/Telephone'
import Selectfield from './Selectfield/Selectfield'
import { fetch_data_from_api_from_query } from '../../../Api/APICalls';
import { ApiList } from '../../../Api/ApiList';
import { createSelectArrayForReactSelect } from '../../../Component/Helper';
import { useQuery } from "@tanstack/react-query";
import { useWatch } from "react-hook-form"


function Formfields({header, fields, control, register, errors,}) {
  const is_billing_fields = header.toLowerCase().includes("billing")
  
  const selected_country = useWatch({
    control,
    name: is_billing_fields ? "billing_country_id" : "shipping_country_id",
  })
  
  const selected_state = useWatch({
    control,
    name: is_billing_fields ? "billing_state_id" : "shipping_state_id",
  })

 const country_query = useQuery({
    queryKey: ["country"],
    queryFn: () => fetch_data_from_api_from_query(`${ApiList.UserRegistration}/${ApiList.get_loc_data}`, "country", {
      id: "",
      table: "countries",
    }).then(res => {
      return createSelectArrayForReactSelect(res, "name", "id")
    }),
 });

 const state_query = useQuery({
  queryKey: ["state", selected_country],
  queryFn: () => fetch_data_from_api_from_query(`${ApiList.UserRegistration}/${ApiList.get_loc_data}`, "state", {
    id: selected_country?.value,
    table: "states",
  }).then(res => {
    return createSelectArrayForReactSelect(res, "name", "id")
  }),

  enabled: Boolean(selected_country?.value),
});


const city_query = useQuery({
  queryKey: ["city", selected_state],
  queryFn: () => fetch_data_from_api_from_query(`${ApiList.UserRegistration}/${ApiList.get_loc_data}`, "city", {
    id: selected_state?.value,
    table: "cities",
  }).then(res => {
    return createSelectArrayForReactSelect(res, "name", "id")
  }),

  enabled: Boolean(selected_state?.value),
});






function getOptions (field_name) {
  switch (field_name) {
    case "Country": return country_query?.data || [];
    case "State": return state_query?.data || [];
    case "City": return city_query?.data;
    default : return []
    
  }
}



  
  

  return (
    <div className='form-wrapper'>
        <header>{header}</header>
        <section className='form-body'>
          {
            fields.map(field => {
              const {control_type, short_code, field_name} = field
              if(control_type === "Telephone"){
                return <Telephone key={short_code} field={field} control={control} errors={errors}/>
              }else if(control_type === "Dropdown"){
                return <Selectfield key={short_code} field={field} control={control} options = {getOptions(field_name)} errors={errors}/>
              }else{
                 return <Textfield key={short_code} field={field} register={register} errors={errors}/>
              }
            })
          }
        </section>
    </div>
  )
}

export default Formfields
