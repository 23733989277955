import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Controller, useWatch } from "react-hook-form";
import { calculate_prod, FSV_ACTIONS, get_short_code_name } from "../../../../Helper";
import {
  createSelectArrayForReactSelect,
  get_property_settings,
} from "../../../../../../Component/Helper";
import { useDispatch, useSelector } from "react-redux";
import { get_fsv_products_calc, get_fsv_quantity, set_FSV_Handler } from "../../../../../../redux/Reducer/reducer";

function DropdownControl({ selectedProduct, item, control }) {
  const primary_BG_color = get_property_settings("btn_primary_color");
  const dispatch = useDispatch();
  const short_code = get_short_code_name(item.short_code);
  const options = useMemo(() => {
    return createSelectArrayForReactSelect(selectedProduct[item.short_code]);
  }, [item.short_code]);
  const products_calc = useSelector(get_fsv_products_calc)
  const fsv_count = useSelector(get_fsv_quantity)

  const selectedOption = useWatch({
    control,
    name: short_code,
  });

  useEffect(() => {
    if(short_code === 'cc_hook' || short_code === 'tone'){
      dispatch(
        set_FSV_Handler({
          type: FSV_ACTIONS.SET_PRODUCT_CALC,
          payload: {
            product_name: selectedProduct?.category_name,
            data: { [short_code]: selectedOption },
          },
        })
      );
    }else{
      calculate_prod(selectedProduct, products_calc, short_code, selectedOption, fsv_count, dispatch)
    }
  }, [selectedOption]);

  return (
    <Controller
      control={control}
      name={short_code}
      render={({ field }) => (
        <Select
          options={options}
          isDisabled={selectedProduct[item.short_code].length === 1}
          {...field}
          className="react-select-container r-container"
          classNamePrefix="react-select r-select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25:
                (primary_BG_color &&
                  primary_BG_color
                    .replace("rgb", "rgba")
                    .replace(")", ",0.2)")) ||
                "inherit",
              primary: primary_BG_color,
            },
          })}
        />
      )}
    />
  );
}

export default DropdownControl;
