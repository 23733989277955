import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import {
  checkUserAlreadyLogin,
  get_informationFromSessionID,
} from "../../Component/Helper";

import LoginContainer from "./Components/LoginContainer";


function LoginNew() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showOptContainer, setShowOptContainer] = useState(false);
  
 

  useEffect(() => {
    const userID = get_informationFromSessionID("userID");
    if (!userID) {
      checkUserAlreadyLogin();
    } else {
      navigate("/");
    }
  },[]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <section id="LoginContainer">
        <div className=" container-fluid">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title m-0 d-flex justify-content-center">
                <h2 className="my-4 ec-title title">
                  {showOptContainer ? (
                    <span className="title-login-with-otp">LOGIN WITH OTP</span>
                  ) : (
                    <span>Login</span>
                  )}
                </h2>
              </div>
            </div>
            <div className="ec-login-wrapper login-wrapper">
             <LoginContainer showOptContainer={showOptContainer} setShowOptContainer={setShowOptContainer}/>
              
            </div>
          </div>
        </div>
        <div className=" text-center position-absolute bottom-0 end-0">
          <label>
            {/* <p className="text-dark pe-2 version">Version 0.0.69</p> */}
          </label>
        </div>
      </section>
    </>
  );
}

export default LoginNew;
