import React from 'react'
import styles from "../../CSS/HomeCSS/CardSection.module.css";
import "./categoryCardLayout.css";
import { Link } from 'react-router-dom';
import { createSearchURLParams, get_property_settings } from '../Helper';
import { is_plural_base_on_count } from '../../utils';
import {useNavigate} from "react-router-dom"

function SingleCardNew({img, cat_id, cat_name, product_count, hasSubcategory, setShowCategoryModal, layoutStyle = 'minibgimg_nllefttitle_nlleftcount'}) {
    const show_hide_category_counts_is_show = get_property_settings("show_hide_category_counts") === "show"
    const parent_category_from_is_static = get_property_settings("parent_category_from") === "static"
    // const parent_category_from_is_static = true
   
    
    const navigate = useNavigate()
    return (
        
            <Link 
            className={`${styles.ec_card} ${layoutStyle}`} 
            to={parent_category_from_is_static ? '/' : createSearchURLParams('matrix-page', {cat_id, mode:'filter', page: 0})}
            onClick={(e)=>{
                e.preventDefault();
                if(hasSubcategory){
                    navigate(createSearchURLParams('home', {cat_id}))
                    setShowCategoryModal(true)
                }else{
                    navigate(createSearchURLParams('matrix-page', {cat_id, mode:'filter', page: 0}))
                    setShowCategoryModal(false)
                }
            }}
            style={{boxShadow: '0px 1px 4px 2px rgba(0, 0, 0, 0.10)'}}
            >
                
                <div className="ec-card-image category-home-card" style={{ backgroundImage: `url(${img})` }}>
                    <img src={img} alt="img" />
                    
                </div>
                <div className="ec-card-details">
                    <h2 className='cat_name'>{cat_name}</h2>
                    {show_hide_category_counts_is_show && <p>{is_plural_base_on_count(product_count) ? 'Designs' : 'Design'} {product_count} {is_plural_base_on_count(product_count) ? 'Pcs' : 'Pc'}</p>}
                </div>
            </Link>
    )
}

export default React.memo(SingleCardNew)
