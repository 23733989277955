import Modal from "react-bootstrap/Modal";
import "./RequestAccessModal.css";
import { BsFillShieldLockFill } from "react-icons/bs";
import { get_bulk_settings, get_userData_from_activeUser } from "../Helper";
import RequestAccessTelephone from "./RequestAccessTelephone";
import {  useState } from "react";
import { useSelector } from "react-redux";
import { get_myc_catalogue_title } from "../../redux/Reducer/reducer";

function RequestAccessModal({ show }) {
  const {
    company_name,
    cdn,
    company_logo,
    whatsapp_number,
    country_name_shortcode,
  } =
    get_bulk_settings([
      "company_name",
      "cdn",
      "company_logo",
      "whatsapp_number",
      "country_name_shortcode",
    ]) || {};
  const company_logo_img = `${cdn}uploads/company_logo/${company_logo}`;
  
  const catalogue_name = useSelector(get_myc_catalogue_title);
  const userName = get_userData_from_activeUser()?.full_name;
  const userMobileNo = get_userData_from_activeUser()?.mobile_no;
  const userCountryCode = get_userData_from_activeUser()?.country_code;
  const userCompanyName = get_userData_from_activeUser()?.company_name;
  const userCity = get_userData_from_activeUser()?.city;

  const handleRequestAccess = () => {
    const whatsAppMsg = encodeURIComponent(
      `Hello ${company_name},\nI want to view entire product range of "${catalogue_name}". Kindly give access to my account.\nMy details are as below:\nFull Name: ${
        userName ? userName + "\n" : "\n"
      }Company Name: ${userCompanyName ? userCompanyName + "\n" : "\n"}City: ${
        userCity ? userCity + "\n" : "\n"
      }Mobile No: ${
        userMobileNo ? userCountryCode + "-" + userMobileNo + "\n" : ""
      }`
    );

    const country_code =
      country_name_shortcode.toLowerCase() === "in" ? "91" : "91";
    const url = `https://api.whatsapp.com/send?phone=${country_code}${whatsapp_number}&text=${whatsAppMsg}`;
    window.open(url, "_blank");
  };

  return (
    <Modal show={show} onHide={() => {}} className={"request-access-modal"}>
      <Modal.Body>
        <header>
          <img
            src={company_logo_img}
            alt="company-logo"
            className={"company-logo"}
          />
          <p className={"company-name"}>{company_name}</p>
        </header>

        <section>
          <h2>Request access to view</h2>
          <p>{catalogue_name}</p>
        </section>

        <footer>
          <div className="mobile-no-section">
            <RequestAccessTelephone
              userMobileNo={userMobileNo}
              userCountryCode={userCountryCode}
            />
          </div>

          <button
            className="request-access-btn"
            onClick={handleRequestAccess}
            // target="_blank"
            // href={`https://api.whatsapp.com/send?phone=${whatsAppNo}&text=${encodedMessage}`}
          >
            <BsFillShieldLockFill className="shield-icon" />
            <span>Request Access</span>
          </button>
        </footer>
      </Modal.Body>
    </Modal>
  );
}

export default RequestAccessModal;
