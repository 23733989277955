import CardSectionHeader from '../HomeComponents/CardHeader/CardHeader'
import styles from "../../CSS/HomeCSS/CardSection.module.css"
import NoImg from "../../assets/images/no_image_available.png"
import { get_bulk_settings, get_informationFromSessionID, get_property_settings } from '../Helper'

import SingleCardNew from './SingleCardNew'
import React from 'react'


function CategoriesSectionNew(props) {
  
  //const companyCode = get_informationFromSessionID("companyCode")
  const homescreen_category_display_style = get_property_settings("homescreen_category_display_style")

  
  if(typeof props.categoriesData !== 'object') return null
  const {cdn, default_image} = get_bulk_settings(["cdn", "default_image"]) || {}
  const defaultImg = default_image ? `${cdn}uploads/default_image/${default_image}` : NoImg;
  // const defaultImg = companyCode === "VEdD" ? default_cat_img_tgc : companyCode === "S0FMQVNIQU9OTElORQ" ? default_cat_img_kalashonline : NoImg;

  

  return (
    
    <section className='categorySection'>
        <div className={`container-fluid ${styles["cards-container"]} homeCardSection`}>
            <CardSectionHeader {...props}/>
            <div className={`row ${styles["cards-section"]} category-card`}>
                
                  {
                    props.categoriesData.map(category => {
                      
                      const img = category.category_img ? `${cdn}uploads/branding_image/category/${category.category_img}` : defaultImg;
                    return <SingleCardNew 
                    img={img} 
                    key={category.id} 
                    cat_id={category.id} 
                    cat_name={category.cat_name}
                    product_count = {category.product_count}
                    hasSubcategory={Array.isArray(category.subcategory) && category.subcategory.length > 0}
                    setShowCategoryModal={props.setShowCategoryModal}
                    layoutStyle={homescreen_category_display_style}
                    />
                  })
                  }
                    
                    
                    
                  
                
            </div>
        </div>
    </section>
    
  )
}

export default React.memo(CategoriesSectionNew)
