import React from 'react'
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
  } from "react-bootstrap";
import { ToastSuccess, set_informationToSessionID } from './Helper';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import {setConstants, setIsLoggedIn, set_inCartWishList, set_is_allow_access_myc_to_user } from "../redux/Reducer/reducer"
import { useQueryClient } from '@tanstack/react-query'


function ModalDialog({show, setShow, action, toastMsg, setOpenMobileMenu}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    
  return (
    <Modal
            show={show}
            centered
            // onExit={() => setShow(false)}
            close='true'
            onHide={() => setShow(false)}
          >
            <ModalHeader closeButton>
              <ModalTitle>Alert</ModalTitle>
            </ModalHeader>
            <ModalBody>Are you sure you want to Logout?</ModalBody>
            <ModalFooter>
              <button
                className="btn btn-primary button_background_color modal-btn"
                onClick={()=> {
                    setShow(false);
                    setOpenMobileMenu && setOpenMobileMenu(false)

                    setTimeout(()=>{
                      queryClient.invalidateQueries({ queryKey: ['category'] })
                      action(toastMsg); 
                      set_informationToSessionID({isUserLoggedOut: true});
                      dispatch(
                        setConstants({})
                      );
                      dispatch(setIsLoggedIn({status: false, userID: ""}))
                      dispatch(set_inCartWishList(null))
                      dispatch(set_is_allow_access_myc_to_user(false))
                      navigate('/');
                    }, 300)

                   
                    
                } }
              >
                {" "}
                Yes
              </button>
              <button
                className="btn btn-primary button_background_color modal-btn"
                onClick={() => {
                  setShow(false);
                  setOpenMobileMenu && setOpenMobileMenu(false)
                }}
              >
                No
              </button>
            </ModalFooter>
          </Modal>
  )
}

export default React.memo(ModalDialog)
