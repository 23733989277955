import React from 'react'

function FormError({errors, paramsData}) {
    return (
        <span className="required-field">
            {errors[paramsData?.short_code]?.message
                ? errors[paramsData?.short_code]?.message.replace(/_/g, " ").toUpperCase()
                : null}
        </span>
    )
}

export default FormError
