import React, { useCallback, useEffect, useState } from "react";

import styles from "../../../CSS/HomeCSS/CardSection.module.css";
import ReactSlider from "../../ReactSlider/ReactSlider";

import {
  createSearchURLParams,
  get_informationFromSessionID,
  get_property_settings,
  set_informationToSessionID,
} from "../../Helper";
import { Link, useNavigate } from "react-router-dom";
import Product_Branding_Display_Card from "../../Product/Product_Branding_Display_Card";
import ProductNew from "../../Product/ProductNew";

function ProductBasedSection({
  title,
  subtitle,
  btnName,
  clsName,
  data,
  cat_id,
  branding_img,
  web_container_bg_color,
  web_container_fg_color,
  product_count,
}) {
  const CDN = get_property_settings("cdn");
  const navigate = useNavigate();
 const [window_width, set_window_width] = useState(window.innerWidth);
 const Dummy_Data = Array.isArray(data) && data.length > 0 ? [data[0]] : [];

  const responsive = [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: branding_img ? 3 : 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: branding_img ? 3 : 4,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 1260,
      settings: {
        slidesToShow: branding_img ? 3 : 4,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 1024,
      settings: {
        slidesToShow: branding_img ? 2 : 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: branding_img ? 1 : 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: branding_img ? 2 : 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ];

  const saveStateToLocalStorage = useCallback(() => {
    const filter = get_informationFromSessionID("filter");

    set_informationToSessionID({
      filter,
    });
  }, []);

  // function handleResize(){
  //     set_window_width(window.innerWidth)
  // }

  const handleResize = useCallback(() => {
    set_window_width(window.innerWidth);
  }, [window_width]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const brading_img_path = `${CDN}uploads/branding_image/featured/${branding_img}`;

  return (
    <section
      className="productBased-main-section"
      style={{ backgroundColor: web_container_bg_color }}
    >
      <div
        className={`container-fluid ${styles["cards-container"]} homeCardSection`}
      >
        {/* <CardSectionHeader title={title} subtitle={subtitle} btnName={btnName} /> */}

        <div className="row">
          <div
            className={`col-md-12 ${styles["section-header"]} section-header`}
          >
            <div className={styles["section-title"]}>
              <h2
                className={`${styles["ec-title"]} ec-title`}
                style={{ color: web_container_fg_color }}
              >
                {title}
              </h2>
              {subtitle && <p className={styles.subTitle}>{subtitle}</p>}
            </div>
            {btnName && (
              <div className={styles["section-btn"]}>
                <Link
                  to={createSearchURLParams("matrix-page", {
                    featured_id: cat_id,
                    mode: "featuredBased",
                    page: 0,
                  })}
                  // onClick={() => setTimeout(() => window.location.reload(), 100)}
                  className={`${styles["btn-primary"]} btn-primary`}
                  style={{
                    color: web_container_fg_color,
                    borderColor: web_container_fg_color,
                  }}
                  onClick={() => {
                    product_count !== undefined &&
                      set_informationToSessionID({
                        totalProductCount: Number(product_count),
                      });
                  }}
                >
                  {btnName}
                </Link>
              </div>
            )}
          </div>
        </div>

        <section className={`productBased-strip-container`}>
          {branding_img && (
            <div
              className={"cat-displayImg"}
              style={{ backgroundColor: web_container_bg_color }}
            >
              <img src={brading_img_path} alt={branding_img} />
            </div>
          )}
          <div
            className={`row ${styles["cards-section"]} cards-section ${
              branding_img ? "" : "no-display"
            }`}
          >
            {window_width > 600 ? (
              <ReactSlider
                additionalSetting={{
                  infinite: false,
                  slidesToShow: branding_img ? 3 : 4,
                }}
                responsive={responsive}
                className={clsName}
              >
                {data.map((item, index) => (
                  <ProductNew
                    key={index}
                    index={index}
                    item={item}
                    sort={"0"}
                    saveStateToLocalStorage={saveStateToLocalStorage}
                    display_card_for_product_base_section = {true}
                  />
                ))}
              </ReactSlider>
            ) : (
              <ReactSlider
                additionalSetting={{
                  infinite: false,
                  slidesToShow: branding_img ? 3 : 4,
                }}
                responsive={responsive}
                className={clsName}
              >
                {/* {branding_img && <div className={'cat-displayImg-inside'} style={{backgroundColor: web_container_bg_color}} ref={branding_inside_img_ref}><img src={brading_img_path} alt={branding_img}/></div>} */}

                {branding_img &&
                  Dummy_Data.map((item, index) => (
                    <Product_Branding_Display_Card
                    key={index}
                    index={index}
                    item={item}
                    sort={"0"}
                    saveStateToLocalStorage={saveStateToLocalStorage}
                    display_card_for_product_base_section = {true}
                    brading_img_path={brading_img_path}
                    />
                  ))}

                {data.map((item, index) => (
                  <ProductNew
                  key={index}
                  index={index}
                  item={item}
                  sort={"0"}
                  saveStateToLocalStorage={saveStateToLocalStorage}
                  display_card_for_product_base_section = {true}
                />
                ))}
              </ReactSlider>
            )}
            
          </div>
        </section>
      </div>
    </section>
  );
}

export default React.memo(ProductBasedSection);
