import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  get_myc_is_secured,
  set_trigger_cart_btn,
  set_trigger_shortlist_btn,
  setConstants,
  get_inCartWishList,
  set_inCartWishList
} from "../../redux/Reducer/reducer";
import { NumericFormat } from "react-number-format";
import { ApiList } from "../../Api/ApiList";
import {
  ToastError,
  ToastSuccess,
  createSearchURLParams,
  formatPriceWithRupee,
  get_bulk_settings,
  get_design_inventory_and_realated_cart_property,
  get_informationFromSessionID,
  get_product_status_details,
  set_productID_inCartWishList,
  
} from "../Helper";
import CartIcon from "../../assets/images/ic_matrix_cart@3x.png";
import Tag from "../Tag";
import { fetch_data_from_api } from "../../Api/APICalls";
import noImg from "../../assets/images/no_image_available.png";
import secure_image from "../../assets/images/secure-image.jpg";
import { displayed_product_in_matrix_myc_for_secure } from "../../utils";
import { getCustomValue } from "../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../Custom/CompanyCustomization";

const ProductNew = ({
  index,
  sort,
  item,
  saveStateToLocalStorage,
  display_card_for_product_base_section,
}) => {
  const location = useLocation();

  //General Settings
  const {
    show_hide_design_master_new_arrival,
    new_arrival_badge_bg_color,
    new_arrival_badge_fg_color,
    matrix_stepper_and_buttons,
    matrix_add_to_cart_button,
    allow_lessthan_moq,
    enable_shortlist,
    matrix_shortlist_button_position,
    matrix_cart_button_position,
    show_center_label_matrix,
    center_label_matrix_value,
    cdn,
    show_price_label_matrix,
    is_pinned_badge_fg_color: pinned_badge_fg_color,
    is_pinned_badge_bg_color: pinned_badge_bg_color,
    is_pinned_matrix_string: pinned_matrix_string,
    default_image
  } = useMemo(
    () =>
      get_bulk_settings([
        "show_hide_design_master_new_arrival",
        "new_arrival_badge_bg_color",
        "new_arrival_badge_fg_color",
        "matrix_stepper_and_buttons",
        "matrix_add_to_cart_button",
        "allow_lessthan_moq",
        "enable_shortlist",
        "matrix_shortlist_button_position",
        "matrix_cart_button_position",
        "show_center_label_matrix",
        "center_label_matrix_value",
        "cdn",
        "show_price_label_matrix",
        "is_pinned_badge_fg_color",
    "is_pinned_badge_bg_color",
    "is_pinned_matrix_string",
    "default_image"
      ]) || {},
    []
  );

  const showStepper = display_card_for_product_base_section
    ? "No"
    : matrix_stepper_and_buttons;
  
  const showAddToCart = display_card_for_product_base_section
    ? "No"
    : matrix_add_to_cart_button;
  const show_price_label =
    location.pathname === "/matrix-page" && show_price_label_matrix === "Yes";
  //Pinned Product
  const show_pinned = item?.is_pinned === "1";
  

  //Session Values
  const user_id = get_informationFromSessionID("userID");

  //Product Object
  
  const defaultImg = default_image ? `${cdn}uploads/default_image/${default_image}` : noImg;
  
  const design_master_new_arrival = item?.design_master_new_arrival === "1";
  const centerLabel =
    show_center_label_matrix === "Yes" ? item[center_label_matrix_value] : "";
  let image = `${
    item.design_files
      ? `${cdn}${item.image_path.replace("thumb_image", "large_image")}${
          item.design_files
        }`
      : defaultImg
  }`;
  const label = item?.label;
  const values = item?.values;
  const minQty = item?.min_order_quantity;
  const maxQty = item?.max_order_quantity;
  const design_inventory = item?.design_inventory;
  const inCartWishList = useSelector(get_inCartWishList);
  //MYC secure locked image
  const is_myc_is_secured = useSelector(get_myc_is_secured);
  const get_display_product_count_for_secure = useMemo(() => {
    if (!is_myc_is_secured) return;
    return displayed_product_in_matrix_myc_for_secure();
  }, [is_myc_is_secured]);

  if (
    !user_id &&
    is_myc_is_secured &&
    get_display_product_count_for_secure !== null
  ) {
    if (index + 1 > get_display_product_count_for_secure) {
      image = secure_image;
    }
  }

  const [count, setCount] = useState(Number(item?.in_cart_qty) || parseInt(minQty));
  const [itemIsInCart, setItemIsInCart] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(() => {
    if(!inCartWishList) return "ADD TO CART";
    const { typeOfID, property } = get_design_inventory_and_realated_cart_property(item?.design_inventory);
    
    if(inCartWishList[property].includes(item[typeOfID]) && showStepper === "Yes"){
      return "UPDATE" 
    }else if(inCartWishList[property].includes(item[typeOfID]) && showStepper === "No"){
      return "REMOVE" 
    }else{
      return "ADD TO CART"
    }
  });

  const ATC_BUTTON_CLASS = buttonLabel === "UPDATE" || buttonLabel === "REMOVE" ? 'update-button' : 'add-to-cart-button'
 

  const product_status_details = get_product_status_details(
    item?.status_details
  );

  const dispatch = useDispatch();
  
  
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));

  const navigate = useNavigate();
  const cat_id = searchParams?.cat_id || 0;

  const FSV_redirection_link = location.pathname.includes("home")
    ? createSearchURLParams(`product-page/${index}`, {
        sort,
        cat_id,
        mode: "homescreen",
        product_id: item.design_master_id || item.inventory_master_id,
      })
    : // `/product-page/${index}?mode=homescreen&product_id=${item.design_master_id}&sort=${sort}&cat_id=${cat_id}`
    searchParams?.mode === "product_search_online"
    ? createSearchURLParams("product-page/0", searchParams)
    : createSearchURLParams(`product-page/${index}`, { sort, cat_id });
  // `/product-page/${index}?sort=${sort}&cat_id=${cat_id}`

  const Increment = () => {
    let add = parseInt(count) + 1;
    if (parseInt(maxQty) === 0) {
      setCount(add);
    } else if (add > parseInt(maxQty)) {
      ToastError(`Max ${maxQty} Qty Reached.`);
    } else {
      setCount(add);
    }
  };

  const Decrement = () => {
    const is_allow_lessthen_moq = allow_lessthan_moq === "Yes";

    if (is_allow_lessthen_moq && parseInt(count) <= parseInt(minQty)) return;

    if (!itemIsInCart && parseInt(count) === 1) return;

    if (parseInt(count) > 0) {
      setCount(parseInt(count) - 1);
    }
  };

  
 
  const set_ATC_button_label = () => {
    const { typeOfID, property } =
      get_design_inventory_and_realated_cart_property(item.design_inventory);

      //inCartWishList[property].includes(item[typeOfID])
      if(inCartWishList[property].includes(item[typeOfID]) && showStepper === "Yes" && count === 0){
        setButtonLabel("ADD TO CART")
      }else if(showStepper === "Yes"){
        setButtonLabel("UPDATE")
      }else if (!inCartWishList[property].includes(item[typeOfID]) && showStepper === "No"){
        setButtonLabel("REMOVE")
      }else{
        setButtonLabel("ADD TO CART")
      }
    
  };

  async function handle_add_to_cart_button(){
    if (!user_id) return navigate("/login");
    const productID = Get_Product_ID_Property_Name();
    
    const action =
      buttonLabel === "UPDATE" || buttonLabel === "ADD TO CART"
        ? "add"
        : "remove";
    await handle_cart_and_wishlist(
      "cart",
      item[productID],
      action
    );
    setItemIsInCart(true);
    set_ATC_button_label();
  }

  const handle_cart_and_wishlist = async (typeOfCart, product_id, action) => {
    
    if(!user_id && !inCartWishList) return navigate("/login")

    // if((typeOfCart !== "cart" || typeOfCart !== "wishlist") || (action !== "add" || action !== "remove")){
    //   throw new Error("typeOfCart And Action must be required")
    // }

    const table = typeOfCart === "wishlist" ? "temp_wishlist" : "temp_cart";
    const defaultParams = {
      user_id,
      session_id: 0,
      table,
      design_inventory,
      product_id,
    };

    const params =
      action === "add"
        ? {
            ...defaultParams,
            quantity: count,
            mode: "with_subproduct",
            type_of_user: "normal_user",
          }
        : {
            ...defaultParams,
          };

    const API_Endpoint =
      action === "add"
        ? `${ApiList.cart}/${ApiList.addToCartMatrix}`
        : `${ApiList.cart}/${ApiList.Delete_From_Cart_Wishlist_Matrix}`;
    const Proxy_Endpoint =
      action === "add" ? "add-to-cart" : "remove-from-cart";
    const response = await fetch_data_from_api(
      API_Endpoint,
      Proxy_Endpoint,
      params
    );

    const is_Response_is_an_object = response?.constructor === Object;

    //if there is an error
    if (is_Response_is_an_object && response.ack === "0") {
      return ToastError(response?.msg);
    } else {
      if (is_Response_is_an_object) {
        if (window.innerWidth <= 575) {
          typeOfCart === "cart" && dispatch(set_trigger_cart_btn(true));
          typeOfCart === "wishlist" &&
            dispatch(set_trigger_shortlist_btn(true));
        } else {
          ToastSuccess(response?.msg);
        }
        dispatch(setConstants({ count: response?.cart_total }));

        const typeOfID =
          item.design_inventory === "design_master"
            ? "design_master_id"
            : item.design_inventory === "inventory_master"
            ? "inventory_master_id"
            : "";

        let updated_cart_wish_list = {};
        if (response?.action === 1 || response?.action === 2) {
          updated_cart_wish_list = set_productID_inCartWishList(
            typeOfCart,
            item.design_inventory,
            item[typeOfID],
            "add",
            inCartWishList
          );
        } else if (!response?.action || response?.action === 3) {
          updated_cart_wish_list = set_productID_inCartWishList(
            typeOfCart,
            item.design_inventory,
            item[typeOfID],
            "remove",
            inCartWishList
          );
          if (typeOfCart === "cart") {
            setItemIsInCart(false);
            setCount(1);
            //set_ATC_button_label();
          }
        }

        dispatch(set_inCartWishList(updated_cart_wish_list));
      }
    }
  };

  //Return ethier "design_master_id" || "inventory_master_id"
  const Get_Product_ID_Property_Name = () => {
    return item?.design_inventory === "design_master"
      ? "design_master_id"
      : item?.design_inventory === "inventory_master"
      ? "inventory_master_id"
      : "";
  };

  const checkQty = () => {
    if (count === "" || count === undefined) {
      setCount(parseInt(minQty));
    }
  };
  useEffect(() => {
    //set_ATC_button_label();
    const productID = Get_Product_ID_Property_Name();
    
    if (!inCartWishList || Object.keys(inCartWishList).length === 0) {
      setItemIsInCart(false);
    } else {
      const inCartWishList_property_name =
        productID === "inventory_master_id" ? "in_cart_id_im" : "in_cart_id_dm";
      if (
        inCartWishList[inCartWishList_property_name].includes(item[productID])
      ) {
        setItemIsInCart(true);
      } else {
        setItemIsInCart(false);
      }
    }
  }, []);



  return (
    <div
      className="ec-product-sbtn mt-auto mb-auto d-flex aling-items-center w-100 flex-column h-100 single-matrix-product"
      key={index}
    >
      <span className="ec-pro-actions px-3 w-100 d-flex justify-content-center">
        <div
          className={`cart-top-container d-flex w-100 align-items-center actions-ico 
          ${
            matrix_cart_button_position === "topright"
              ? matrix_shortlist_button_position === "topright"
                ? "justify-content-between"
                : "justify-content-between"
              : matrix_shortlist_button_position === "topleft"
              ? "justify-content-start"
              : "flex-row-reverse justify-content-between "
          } `}
        >
          {item.design_inventory === "design_master" ? (
            <>
              {show_pinned ? <div
                  className="card-top-label"
                  style={{
                    backgroundColor: pinned_badge_bg_color,
                    color: pinned_badge_fg_color,
                  }}
                > 
                  {pinned_matrix_string}
                </div>

                :
              
              show_hide_design_master_new_arrival === "show" &&
              design_master_new_arrival ? (
                <div
                  className="card-top-label"
                  style={{
                    backgroundColor: new_arrival_badge_bg_color,
                    color: new_arrival_badge_fg_color,
                  }}
                >
                  {getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.new_arrivals) ||
                    "New Arrivals"}
                </div>
              ) : null}

              {enable_shortlist && (
                <>
                  {inCartWishList?.in_wishlist_id_dm?.length > 0 &&
                  inCartWishList?.in_wishlist_id_dm.includes(
                    item.design_master_id
                  ) ? (
                    <div>
                      <div
                        className=" whishlist-selected whishlist-circle bg-transparent"
                        onClick={(e) => {
                          const productID = Get_Product_ID_Property_Name();
                          handle_cart_and_wishlist(
                            "wishlist",
                            item[productID],
                            "remove"
                          );
                        }}
                      >
                        <i className="fa-solid fa-heart fa-lg px-2 py-3"></i>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/* <img
                        className="mx-1 whishlist matrix-icons "
                        src={ColoredShortListIcon}
                        onClick={(e) => {
                          handleWhishlist(e, item, "add");
                        }}
                      /> */}

                      <div
                        className="  whishlist-circle bg-transparent"
                        onClick={(e) => {
                          const productID = Get_Product_ID_Property_Name();
                          handle_cart_and_wishlist(
                            "wishlist",
                            item[productID],
                            "add"
                          );
                        }}
                      >
                        <i className="fa-solid fa-heart fa-lg px-2 py-3 "></i>
                      </div>
                    </div>
                  )}
                </>
              )}

              {inCartWishList?.in_cart_id_dm?.length > 0 &&
                inCartWishList?.in_cart_id_dm?.includes(
                  item.design_master_id
                ) && (
                  <div className="cartIcon">
                    <img className="mx-1 matrix-icons" src={CartIcon} alt="" />
                  </div>
                )}
            </>
          ) : (
            ""
          )}
          {item.design_inventory === "inventory_master" ? (
            <>
              {enable_shortlist && (
                <>
                  {inCartWishList?.in_wishlist_id_im?.length > 0 &&
                  inCartWishList?.in_wishlist_id_im.includes(
                    item.inventory_master_id
                  ) ? (
                    <div
                      className=" whishlist-selected whishlist-circle bg-transparent"
                      onClick={(e) => {
                        const productID = Get_Product_ID_Property_Name();
                        handle_cart_and_wishlist(
                          "wishlist",
                          item[productID],
                          "remove"
                        );
                      }}
                    >
                      <i className="fa-solid fa-heart fa-lg px-2 py-3"></i>
                    </div>
                  ) : (
                    <div
                      className="  whishlist-circle bg-transparent"
                      onClick={(e) => {
                        const productID = Get_Product_ID_Property_Name();
                        handle_cart_and_wishlist(
                          "wishlist",
                          item[productID],
                          "add"
                        );
                      }}
                    >
                      <i className="fa-solid fa-heart fa-lg px-2 py-3 "></i>
                    </div>
                  )}
                </>
              )}
              {inCartWishList?.in_cart_id_im?.length > 0 &&
                inCartWishList?.in_cart_id_im?.includes(
                  item.inventory_master_id
                ) && (
                  <div className="cartIcon">
                    <img className="mx-1 matrix-icons" src={CartIcon} alt="" />
                  </div>
                )}
            </>
          ) : (
            ""
          )}
        </div>
      </span>
      <div
        className="ec-product-image"
        style={{ backgroundImage: `url(${image})` }}
      >
        {product_status_details && <Tag {...product_status_details} />}
        {/* <a href="#" className="ec-image"> */}
        <Link
          to={FSV_redirection_link}
          state={location}
          onClick={() => {
            saveStateToLocalStorage(index);
          }}
        >
          {}
          <img className="pic-1" src={image} />
        </Link>
      </div>
      {label.length > 0 ? (
        <div className="ec-product-body mt-auto">
          {centerLabel ? (
            <b className="product-name">
              <span>{centerLabel}</span>
            </b>
          ) : null}
          {}
          {label &&
            label.length >= 0 &&
            label.map((item, i) => {
              if (typeof item !== "string") return null;

              return (
                <div key={i}>
                  {item?.toLowerCase() === "price" ? (
                    show_price_label && (
                      <b>
                        <span>{item}</span> :
                      </b>
                    )
                  ) : (
                    <b>
                      <span>{item}</span> :
                    </b>
                  )}
                  <span
                    className={
                      item?.toLowerCase() === "price" && !show_price_label
                        ? "custom-price-style"
                        : ""
                    }
                  >
                    {" "}
                    {item?.toLowerCase() === "price"
                      ? formatPriceWithRupee(values[i])
                      : values[i]}
                  </span>
                </div>
              );
            })}
          <div className="stepper">
            {showStepper !== "No" && (
              <div className="qty-plus-minus">
                <div className="dec ec_qtybtn" onClick={Decrement}>
                  -
                </div>

                <NumericFormat
                  className="qty-input"
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    if (maxQty === 0) {
                      setCount(floatValue);
                    } else if (
                      !isNaN(floatValue) &&
                      floatValue >= minQty &&
                      floatValue <= maxQty
                    ) {
                      setCount(floatValue);
                    } else {
                      if (floatValue > maxQty) {
                        setCount(maxQty);
                        ToastError(`Max ${maxQty} Qty Reached.`);
                      }
                    }
                  }}
                  value={count}
                  onBlur={checkQty}
                  isAllowed={(inputObj) => {
                    const { value } = inputObj;
                    if (value === "") {
                      return true;
                    }
                    const numericValue = parseFloat(value);
                    if (numericValue === 0 && !itemIsInCart) {
                      return false;
                    }
                    return maxQty === 0
                      ? true
                      : numericValue > maxQty
                      ? false
                      : true;
                  }}
                  allowNegative={false}
                  prefix=""
                  placeholder="qty"
                />

                <div className="inc ec_qtybtn" onClick={Increment}>
                  +
                </div>
              </div>
            )}
          </div>

          {showAddToCart !== "No" && (
            <div className="ec-product-button-group">
              {/* <a className="ec-product-like-icon" href="#"><i className="fi-rr-heart"></i></a> */}
              <a
                className={`ec-add-to-cart mt-2 ${ATC_BUTTON_CLASS}`}
                id="add-to-cart"
                onClick={handle_add_to_cart_button}
              >
                {buttonLabel}
              </a>
              {/* <a className="ec-product-compare-icon" href="#"><i className="fi fi-rr-arrows-repeat"></i></a> */}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(ProductNew);
