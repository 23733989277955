import React, { useEffect, useRef, useState } from 'react'
import { decryptData, encryptData, fetchSecureData, get_informationFromSessionID } from '../../Component/Helper';
import { ApiList, BASE_URL, PROXY_BASE_URL } from '../../Api/ApiList';
import { useLocation } from "react-router-dom"
import "./StaticPage.css";
import { fetch_data_from_api } from '../../Api/APICalls';
import Loader from "../../Component/Loader"

function StaticPage() {
    const location = useLocation()
    const searchParams = Object.fromEntries(
        new URLSearchParams(location.search)
    )


    const iframeRef = useRef(null)

    const companyCode = get_informationFromSessionID("companyCode");
    const [data, setData] = useState(null)
    const [dummyData, setDummyData] = useState(null)
    const [height, setHeight] = useState(null)
    const [loading, setLoading] = useState(false)
    const fetchHTMLData = async () => {
        setLoading(true)
        const staticPageResponse = await fetch_data_from_api(ApiList.staticpage, 'StaticPage', { company_code: companyCode, page: searchParams.id })
        setData(staticPageResponse)
        let d1 = staticPageResponse.replace(/body.+{/g, '.body{').replace(/.body{/g, '.body{')
        d1 = d1.replace(/<body/g, `<body class="body"`)

        
        setDummyData(d1)
        setLoading(false)

    }


    useEffect(() => {
        fetchHTMLData()
    }, [searchParams.id])

    return (
        <section className='static-page'>
            {
                loading ? <Loader isLoading={loading} /> : (
                    <article >
                        <div className="dummyContainer" dangerouslySetInnerHTML={{ __html: dummyData }}></div>
                        <iframe srcDoc={data} frameBorder="0" width='100%' height="100%" style={{ overflow: 'hidden' }} ref={iframeRef}></iframe>
                    </article>
                )
            }

        </section>
    )
}

export default StaticPage
