import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RiCheckboxCircleFill } from "react-icons/ri";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select"
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import {
  ApiList,
  
} from "../../../Api/ApiList";

import { useDispatch, useSelector } from "react-redux";
import { get_inCartWishList, set_inCartWishList, set_trigger_cart_btn, set_trigger_shortlist_btn, setConstants } from "../../../redux/Reducer/reducer";
import {
  ToastError,
  ToastSuccess,
  createSelectArrayForReactSelect,
  createSelectOptionForReactSelect,
  get_informationFromSessionID,
  get_settings,
  hasPropertyInObj,
} from "../../../Component/Helper";
import { fetch_data_from_api } from "../../../Api/APICalls";
import noImage from "../../../assets/images/no_image_available.png"
import { debounce } from "../../../utils";

const CartProduct = ({
  id,
  group_in_wishlist,
  group_in_cart,
  fetchData,
  productIndex,
  isLastProduct,
  general_settings,
  cartData,
  product_detail,
  type_of_cart,
  
}) => {
  
  // const maxQty = 5;
  // const minQty = 3;
  // const lessThanMoq = 4;
  
  const productCode = general_settings.cart_grouped_code_based_on === "mfg_code" ? product_detail?.mfg_code : product_detail?.search_item_code;
  const image = product_detail?.design_files ? `${general_settings.cdn}${product_detail?.image_path}${product_detail?.design_files}` : noImage
  const productname = product_detail?.product_name
  const params = Object.values(product_detail?.params[0])
  const minQty = product_detail?.min_order_quantity
  const maxQty = product_detail?.max_order_quantity
  //const inch = product_detail?.size_param_catalogue
  const which_master = product_detail?.which_master
  const quantity= product_detail?.quantity

  const {
    show_quantity_stepper_in_cart_wishlist,
    show_qty_stepper_in_inventory,
    show_remarks_in_cart_wishlist,
    show_remarks_in_inventory,
    show_product_name_in_cart_wishlist,
    allow_lessthan_moq
  } = general_settings;

  const company_code = get_informationFromSessionID("companyCode")
  const user_id = get_informationFromSessionID("userID")

  const [count, setCount] = useState(quantity ? quantity : minQty);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isToastActive, setIsToastActive] = useState(false)
 
  
  
  const [selectedKaratOption, setSelectedKaratOption] = useState({})
  const [karatOptions, setKaratOptions] = useState(createSelectArrayForReactSelect(product_detail.karat_param_catalogue))
  const [selectedInchOption, setSelectedInchOption] = useState({})
  const [sizeOptions, setSizeOptions] = useState(createSelectArrayForReactSelect(product_detail.size_param_catalogue))
  const [selectedHookOption, setSelectedHookOption] = useState({})
  const [hookOptions, setHookOptions] = useState(createSelectArrayForReactSelect(product_detail.cc_hook_preference_param_catalogue))
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const settings = get_settings()
  const primary_BG_color = settings?.btn_primary_color;
  
  let inCartWishList = useSelector(get_inCartWishList)
  
  

  const checkQty = () => {
    
    if (count === 0 || count === undefined) {
      setCount(parseInt(minQty));
    }
  };
 

  const deleteItem = async () => {
      
      setLoading(true)
      const deleteItem_Response = await fetch_data_from_api(`${ApiList.cart}/${ApiList.DeleteFromCart}`, 'delete-from-cart', {
          id,
          type: type_of_cart === "temp_wishlist" ? group_in_wishlist : group_in_cart,
          table: type_of_cart
      })

      if(deleteItem_Response.constructor === Object){
        dispatch(setConstants({ count: deleteItem_Response?.cart_total }));

        
      if (type_of_cart === "temp_cart") {
        
        
        if (product_detail.which_master === "design_master") {
          dispatch(set_inCartWishList({
            ...inCartWishList,
            in_cart_id_dm: [
              ...inCartWishList.in_cart_id_dm.filter((i, k) => {
                return i !== product_detail.design_master_id;
              }),
            ],
          }))
        }

        

        if (product_detail.which_master === "inventory_master") {
          dispatch(set_inCartWishList({
            ...inCartWishList,
            in_cart_id_im: [
              ...inCartWishList.in_cart_id_im.filter((i, k) => {
                return i !== product_detail.inventory_master_id;
              }),
            ],
          }))
        }
      } else {
       

        if (product_detail.which_master === "design_master") {
          dispatch(set_inCartWishList({
            ...inCartWishList,
            in_wishlist_id_dm: [
              ...inCartWishList.in_wishlist_id_dm.filter((i, k) => {
                return i !== product_detail.design_master_id;
              }),
            ],
          }))
        }

        
        if (product_detail.which_master === "inventory_master") {
          dispatch(set_inCartWishList({
            ...inCartWishList,
            in_wishlist_id_im: [
              ...inCartWishList.in_wishlist_id_im.filter((i, k) => {
                return i !== product_detail.inventory_master_id;
              }),
            ],
          }))
        }
      }
      if(window.innerWidth <= 575){

        type_of_cart === "temp_cart" && dispatch(set_trigger_cart_btn(true))
        type_of_cart === "temp_wishlist" && dispatch(set_trigger_shortlist_btn(true))
      }else{

        ToastSuccess(deleteItem_Response?.msg)
      }

      }
      setShow(false);
      setLoading(false)
      fetchData()
  };


  const Increment = () => {
    let add = parseInt(count) + 1;
    // maxQty && parseInt(maxQty)===0 ? setCount(add) :
    //     add > parseInt(maxQty) ? ToastError(`Max ${maxQty} Qty Reached.`) : setCount(add)
    // if (maxQty) {
    if (parseInt(maxQty) === 0) {
      setCount(add);
      handleChange("quantity", add);
    } else {
      if (add > parseInt(maxQty)) {
        ToastError(`Max ${maxQty} Qty Reached.`);
      } else {
        handleChange("quantity", add);
        setCount(add);
      }
    }
    // }
  };
  const Decrement = () => {
    if (count > 1 && allow_lessthan_moq) {
      setCount(parseInt(count) - 1);
      handleChange("quantity", parseInt(count) - 1);
    } else {
      if (parseInt(count) > minQty) {
        handleChange("quantity", parseInt(count) - 1);

        setCount(parseInt(count) - 1);
      }
    }
  };

  const handleChange = async (short_code, value) => {
    setLoading(true);
    setIsToastActive(true)
    const input_change_response = await fetch_data_from_api(`${ApiList.cart}/${ApiList.UpdateCartParams}`, 'update-cart-params', {
      row_id: id,
      table: type_of_cart,
      user_id,
      update_value: value,
      type: type_of_cart === "temp_wishlist" ? group_in_wishlist : group_in_cart,
      update_param: short_code,
    })

    if(input_change_response.constructor === Object){
      dispatch(setConstants({ count: input_change_response?.cart_total }));
      
      !isToastActive && ToastSuccess(input_change_response?.msg)
      setTimeout(() => {
        setIsToastActive(false)
      }, 2000)
      
        setLoading(false);
    }

   
    fetchData("stopLoader")
  };

  
  const transferProduct = async () => {

    const transferProduct_response = await fetch_data_from_api(`${ApiList.cart}/${ApiList.TransferProduct}`, 'transfer-product', {
      id,
      table_from: type_of_cart,
      table_to: type_of_cart === "temp_wishlist" ? "temp_cart" : "temp_wishlist",
      type: type_of_cart === "temp_wishlist" ? group_in_wishlist : group_in_cart,
      user_id,
    })

    
    if(transferProduct_response.constructor === Object){
      if(transferProduct_response?.error) return ToastError(transferProduct_response?.error?.msg)
      dispatch(setConstants({ count: transferProduct_response?.cart_total }));
      ToastSuccess(transferProduct_response?.msg);
      buttonRef?.current?.blur();
    }

   






    
    
    if (type_of_cart === "temp_wishlist") {
      // cart +
      // shortlist -

      // design_master
      if (product_detail.which_master === "design_master") {
        if (product_detail.design_master_id) {
         

          dispatch(set_inCartWishList({
            ...inCartWishList,
            // adding in to cart
            in_cart_id_dm: [
              ...inCartWishList.in_cart_id_dm,
              !inCartWishList.in_cart_id_dm.includes(
                product_detail.design_master_id
              ) && product_detail.design_master_id,
            ],
            // remove from short list
            in_wishlist_id_dm: [
              ...inCartWishList.in_wishlist_id_dm.filter((i, k) => {
                return i !== product_detail.design_master_id;
              }),
            ],
          }))
        }
      }

      // inventory_master
      if (product_detail.which_master === "inventory_master") {
        if (product_detail.inventory_master_id) {
          

          dispatch(set_inCartWishList({
            ...inCartWishList,
            // adding in to cart
            in_cart_id_im: [
              ...inCartWishList.in_cart_id_im,
              !inCartWishList.in_cart_id_im.includes(
                product_detail.inventory_master_id
              ) && product_detail.inventory_master_id,
            ],
            // remove from short list
            in_wishlist_id_im: [
              ...inCartWishList.in_wishlist_id_im.filter((i, k) => {
                return i !== product_detail.inventory_master_id;
              }),
            ],
          }))
        }
      }
    } else {
      // cart -
      // shortlist +

      // design_master
      if (product_detail.which_master === "design_master") {
        if (product_detail.design_master_id) {
         
          dispatch(set_inCartWishList({
            ...inCartWishList,
            // adding in to cart
            in_wishlist_id_dm: [
              ...inCartWishList.in_wishlist_id_dm,
              !inCartWishList.in_wishlist_id_dm.includes(
                product_detail.design_master_id
              ) && product_detail.design_master_id,
            ],
            // remove from short list
            in_cart_id_dm: [
              ...inCartWishList.in_cart_id_dm.filter((i, k) => {
                return i !== product_detail.design_master_id;
              }),
            ],
          }))
        }
      }

      // inventory_master
      if (product_detail.which_master === "inventory_master") {
        if (product_detail.inventory_master_id) {
         
          dispatch(set_inCartWishList({
            ...inCartWishList,
            // adding in to cart
            in_wishlist_id_im: [
              ...inCartWishList.in_wishlist_id_im,
              !inCartWishList.in_wishlist_id_im.includes(
                product_detail.inventory_master_id
              ) && product_detail.inventory_master_id,
            ],
            // remove from short list
            in_cart_id_im: [
              ...inCartWishList.in_cart_id_im.filter((i, k) => {
                return i !== product_detail.inventory_master_id;
              }),
            ],
          }))
        }
      }
    }

    setLoading(false);
    fetchData()

  };
  const updateWindowResolution = () => {
    // Update view and thumb position based on window width

    
  };
  useEffect(() => {
    window.addEventListener("resize", updateWindowResolution);
    return () => {
      window.removeEventListener("resize", updateWindowResolution);
    };
  });

  useEffect(()=>{
    setKaratOptions(createSelectArrayForReactSelect(product_detail.karat_param_catalogue))
    setSizeOptions(createSelectArrayForReactSelect(product_detail.size_param_catalogue))
    setHookOptions(createSelectArrayForReactSelect(product_detail.cc_hook_preference_param_catalogue))
  }, [product_detail])
  
  
  return (
    <div
      className={
        // windowResolution.width > 1024 ? "container-fluid px-2" : "container"
        "container-fluid px-2 product-section"
      }
    >
      <div className="row productName d-flex justify-content-between productName align-items-center my-2 mt-3 px-2">
        {/* <div className="col-12  d-flex justify-content-end del-btn" onClick={() => setShow(true)} >
          
          <span>
            <FontAwesomeIcon icon={faTrash}/>
          </span>
        </div> */}
      </div>
      <div
        className={`row h-100 ${productIndex === cartData.product_details.length - 1 ? "" : ""
          } justify-content-center product`}
      >
        <div className="col-xxl-5 col-xl-5 col-md-5 col-sm-5 col imgDiv">
          <img
            // src={require("../assets/WebImage.png")}
            src={image ? image : ""}
            // height="100%"
            width="100%"
            style={{ objectFit: "contain", maxWidth: "275px", minWidth: "190px", maxHeight: "300px" }}
            className="p-1 mx-auto mx-sm-0 mx-xl-auto"
            alt=""
          />
        </div>
        <div className="col productDetail my-2 position-relative ">
        <div className="col-12  d-flex justify-content-end del-btn" onClick={() => setShow(true)} >
          
          <span>
            <FontAwesomeIcon icon={faTrash}/>
          </span>
        </div>
          <div className=" col-12  text-start">
            <p>
              {show_product_name_in_cart_wishlist === "Yes" && productname && (
                <>{productname} &nbsp;</>
              )}
            </p>
          </div>
          <div className=" col-12  text-start mb-2">
            <p>{productCode}</p>
          </div>
          
          <div className="row cart-product-details">
            <div className="col-12 cart-product-details">
              
              <div className="row other-cart-details">
                {params &&
                  params.length > 0 &&
                  params.map((cartData, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          `${cartData.display_width === "b" ? "full-width" : "half-width"}`
                        }
                      >
                        <div
                          className={
                            cartData.display_width === "b" ? "label" : "label"
                          }
                        > 
                          {cartData?.label}{cartData?.label?":":""}
                        </div>
                        {product_detail[cartData.short_code + "_catalogue"] &&
                          Object.values(product_detail[cartData.short_code + "_catalogue"]).length >
                          1 ? (
                          <>
                            
                            <div className="data karat_dropdown">
                              
                            <Select
                              menuPlacement= {isLastProduct ? "top" : "bottom"}
                              value={cartData.label === "Karat" && hasPropertyInObj(selectedKaratOption)
                                    ? selectedKaratOption 
                                    : cartData.label === "Inch" && hasPropertyInObj(selectedInchOption)
                                    ? selectedInchOption 
                                    : cartData.label === "Hook" && hasPropertyInObj(selectedHookOption)
                                    ? selectedHookOption 
                                    : createSelectOptionForReactSelect(cartData?.selected_value)
                                    }
                              options={(cartData.label === "KT" || cartData.label === "Karat" || cartData.label === "Kt") ? karatOptions 
                              : (cartData.label === "Size" || cartData.label === "Inch") ? sizeOptions 
                              : cartData.label === "Hook" ? hookOptions 
                              : []}
                              
                              isSearchable={false}
                              isClearable={false}
                              onChange={(option) => {
                                
                                //cartData.label === "Karat" ? setSelectedKaratOption(option) : cartData.label === "Inch" ? setSelectedInchOption(option) : cartData.label === "Hook" ? setSelectedHookOption(option) : null;
                               
                                if( cartData.label === "KT" || cartData.label === "Karat"){
                                 
                                  setSelectedKaratOption(option)
                                }else if(cartData.label === "Size" || cartData.label === "Inch"){
                                  
                                  setSelectedInchOption(option)
                                }else if(cartData.label === "Hook"){
                                  setSelectedHookOption(option)
                                }

                                handleChange(cartData?.short_code, option.value);
                              }}
                              className="react-select-container r-container"
                              classNamePrefix="react-select r-select"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                border: "none",
                                colors: {
                                  ...theme.colors,
                                  primary25: primary_BG_color && primary_BG_color.replace("rgb", "rgba").replace(")", ",0.2)") || "inherit",
                                  primary: primary_BG_color,
                                },
                              })}

                            />
                            </div>
                          </>
                        ) : (
                          <div className={`data ${!cartData?.label ? "selectedValue" : ""}`}>
                            {!cartData?.label && <RiCheckboxCircleFill style={{color: "var(--highlight_color)"}}/>}
                            <span>{cartData?.selected_value}</span>
                          </div>
                        )}
                        
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <Modal
            show={show}
            centered
            // onExit={() => setShow(false)}
            close
            onHide={() => setShow(false)}
          >
            <ModalHeader closeButton>
              <ModalTitle>Alert</ModalTitle>
            </ModalHeader>
            <ModalBody>{`Are you sure you want to remove ${productCode} From ${type_of_cart === "temp_wishlist" ? "Shortlist" : "Cart"}?`}</ModalBody>
            <ModalFooter>
              <button
                className="btn btn-primary button_background_color modal-btn"
                onClick={() => {
                  deleteItem();
                  window.scrollTo(0,0)
                }}
              >
                {" "}
                Yes
              </button>
              <button
                className="btn btn-primary button_background_color modal-btn"
                onClick={() => {
                  setShow(false);
                }}
              >
                No
              </button>
            </ModalFooter>
          </Modal>
          
          {show_remarks_in_cart_wishlist === "Yes" &&
            which_master === "inventory_master" &&
            show_remarks_in_inventory === "Yes" && (
              <div className="row remarks ">
                <div className="col col-md-3 remarks  label">Remark:</div>
                <div className="col-12 col-md-9 data">
                  <textarea
                    
                    className="  form-control form-control-sm"
                    placeholder="Add Remark"
                    rows={4}
                  />
                </div>
                {/* <div className="col-3 d-flex justify-content-end">
              <FontAwesomeIcon icon={faPencil} />
            </div> */}
              </div>
            )}
          {show_quantity_stepper_in_cart_wishlist === "Yes" &&
            which_master === "inventory_master" ? (
            show_qty_stepper_in_inventory === "Yes" ? (
              <div className="row qty">
                <div className="col-1 qty label">Qty:</div>
                <div className="col-8 data d-flex">
                  <div className="qty-plus-minus d-flex border">
                    <NumericFormat
                      className="qty-input"
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        if (maxQty === 0) {
                          setCount(floatValue);
                        } else if (
                          !isNaN(floatValue) &&
                          floatValue >= minQty &&
                          floatValue <= maxQty
                        ) {
                          setCount(floatValue);
                        } else {
                          if (floatValue > maxQty) {
                            setCount(maxQty);
                            ToastError(`Max ${maxQty} Qty Reached.`);
                          }
                        }
                      }}
                      value={count}
                      onBlur={checkQty}
                      isAllowed={(inputObj) => {
                        const { value } = inputObj;
                        if (value === "") {
                          return true;
                        }
                        const numericValue = parseFloat(value);
                        if (numericValue === 0) {
                          return false;
                        }
                        return maxQty === 0
                          ? true
                          : numericValue > maxQty
                            ? false
                            : true;
                      }}
                      allowNegative={false}
                      prefix=""
                      placeholder="qty"
                    />
                    <div
                      className="dec ec_qtybtn ms-3 w-auto"
                      onClick={Decrement}
                    >
                      -
                    </div>
                    <div className="inc ec_qtybtn me-3" onClick={Increment}>
                      +
                    </div>
                  </div>
                </div>
                {/* <div className="col-3 d-flex justify-content-end">
                  <button className="btn btn-primary button_background_color">
                    +{type_of_cart === "temp_wishlist" ? "Cart" : "shortlist"}
                  </button>
                </div> */}

                {/* {
                <div>
                  <div className="row"></div>
                </div>
              } */}
              </div>
            ) : (
              <div className="row qty">
                <div className="col-6 half-width qty">
                  <div className="label mr-2">Qty:</div>
                  <div className="data ">{quantity ? quantity : count}</div>
                </div>
                

                {/* {
                <div>
                  <div className="row"></div>
                </div>
              } */}
              </div>
            )
          ) : (
            show_quantity_stepper_in_cart_wishlist === "Yes" ? <div className="row qty">
              <div className="col-3 qty label">Qty:</div>
              <div
                className={
                  // "col-8 data d-flex align-items-center justify-content-sm-start justify-content-end"
                  "col-9 data d-flex align-items-center justify-content-md-start justify-content-end"
                }
              >
                <div className="qty-plus-minus d-flex border">
                  <NumericFormat
                    className="qty-input"
                    onValueChange={(values) => {
                      const { floatValue } = values;
                     
                      if (maxQty === 0) {
                        setCount(floatValue);
                      } else if (
                        !isNaN(floatValue) &&
                        floatValue >= minQty &&
                        floatValue <= maxQty
                      ) {
                        setCount(floatValue);
                      } else {
                        if (floatValue > maxQty) {
                          setCount(maxQty);
                          ToastError(`Max ${maxQty} Qty Reached.`);
                        }
                      }
                    }}
                    value={count}
                    onBlur={checkQty}
                    isAllowed={(inputObj) => {
                      const { value } = inputObj;
                      if (value === "") {
                        return true;
                      }
                      const numericValue = parseFloat(value);
                      if (numericValue === 0) {
                        return false;
                      }
                      return maxQty === 0
                        ? true
                        : numericValue > maxQty
                          ? false
                          : true;
                    }}
                    allowNegative={false}
                    prefix=""
                    placeholder="qty"
                  />
                  <div className="dec ec_qtybtn  w-auto" onClick={Decrement}>
                    -
                  </div>
                  <div className="inc ec_qtybtn" onClick={Increment}>
                    +
                  </div>
                </div>
                {/* <button
                  className="btn btn-primary button_background_color"
                  onClick={() => {
                    count && handleChange("quantity", count);
                  }}
                >
                  update count
                </button> */}
              </div>
             </div> : null
          )}
          <div className="row single-prod-transfer-btn">
            <div className="col-12 d-flex justify-content-end mt-2 mb-3 cart-btn">
                  <button
                    className="btn btn-primary button_background_color"
                    onClick={() => {
            
                      transferProduct();
                      window.scrollTo(0,0)
                    }}
                  >
                    +{type_of_cart === "temp_wishlist" ? "Cart" : "shortlist"}
                  </button>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartProduct;
