
import ReactSlider from "../../ReactSlider/ReactSlider"
import Banner from "./Banner"
import { get_property_settings } from "../../Helper"
import React from "react"




function HomeBanner({brandingData}) {
  const cdn = get_property_settings("cdn")
 

  return (
    <div className="sticky-header-next-sec ec-main-slider section section-space-pb">
        <div className="ec-slider swiper-container main-slider-nav main-slider-dot">
            {/* -- Main slider -- */}
            <div className="swiper-wrapper">
            <ReactSlider additionalSetting={{dots: false, autoplay: true, autoplaySpeed: 3000, cssEase: "linear", pauseOnHover: false}} className={"banner-slick"}>
                {
                  brandingData.map(branding => {
                    let img = ""
                    if(branding?.branding_img && branding?.branding_img_mobile){
                      img = [`${cdn}uploads/branding_image/web/${branding?.branding_img}`, `${cdn}uploads/branding_image/web/${branding?.branding_img_mobile}`]
                    }else{
                      img = `${cdn}uploads/branding_image/web/${branding?.branding_img}`
                    }

                    return <Banner img={img} key={branding?.position}/>
                  })
                }
            </ReactSlider>
            </div>
            <div className="swiper-pagination swiper-pagination-white"></div>
            <div className="swiper-buttons">
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </div>
        </div>
    </div>
  )
}

export default React.memo(HomeBanner)
