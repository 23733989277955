import React from "react";
import { FaWhatsapp } from "react-icons/fa";

function WhatsappForSmallDevice({whatsAppNo, encodedMessage, showInMobile}) {
  if(!showInMobile){
    return null
  }
  
  return (
    <div className="ec-nav-panel-icons d-flex d-sm-none">
      <a
        className="toggle-cart ec-header-btn ec-side-toggle header-buttons"
        target="_blank"
        href={`https://api.whatsapp.com/send?phone=${whatsAppNo}&text=${encodedMessage}`}
      >
        <FaWhatsapp className="footer-icons" />
      </a>

      <span className="footer-icons-text">WhatsApp</span>
    </div>
  );
}

export default WhatsappForSmallDevice;
