import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import { ToastError, ToastSuccess, get_userData_from_activeUser } from '../../../../../../Component/Helper';
import { fetch_data_from_api } from '../../../../../../Api/APICalls';
import { ApiList } from '../../../../../../Api/ApiList';
import { convertOBJ_to_html_react_options } from '../DropdownSelect';
import {useSelector} from "react-redux";
import { get_add_retailer_check_sendOTP } from '../../../../../../redux/Reducer/reducer';
//{ userFormData, setValueForRetailer, otpResData, setShowFormModal}
function OTPModal({resOtp, add_retailer}) { 
    const [otp, setOtp] = useState('');
    const [optError, setOtpError] = useState('');
    

    function handelOTP() {
        if(otp.length < 6){
            setOtpError('Enter vaild OTP')
        }else{
         
          
          if(resOtp.toString() === otp){
            add_retailer()
          }else{
            setOtpError("invaild opt")
          }
        }
    }

  return (
    <Modal.Body className="otp-form-modal-body">
      
      <OtpInput
      value={otp}
      shouldAutoFocus={true}
      onChange={setOtp}
      inputType="number"
      numInputs={6}
      // renderSeparator={<span>-</span>}
      renderInput={(props) => <input type="number" {...props} />}
      inputStyle="Otp-inputs"
      containerStyle="Otp-container"
    />
      {optError ? <div className="required-field errorText text-uppercase">{optError}</div> : null}
      <div className='verify-opt-btn' onClick={handelOTP}>Verify OTP</div>           
      </Modal.Body>
  )
}

export default OTPModal
