import React from 'react'


function CartGroupBySection({groupByData, windowResolution, setGroup_in_wishlist, setGroup_in_cart, type_of_cart}) {
  const headerHeight = sessionStorage.getItem("headerHeight")
  
 return (
    <section
              className={`group-by w-100  cart-shortlist-section ${
                windowResolution.width > 1024 ? "px-4" : ""
              }   `}
              style={{ zIndex: "5", top: `${headerHeight}px` }}
            >
              <div className="container-fluid  mx-auto">
                <div className="single-pro-content mx-auto ">
                  <div className="ec-pro-variation m-0 ">
                    <div className="ec-pro-variation-inner group-by-inner">
                      <div className="ec-pro-variation-content">
                        <div className="   ">
                          <div className="row w-100 align-items-center ">
                            <div
                              className={`col-12 pe-0 d-flex align-items-center  justify-content-start ${
                                
                                "container-fluid px-4"
                              }`
                            }
                              style={{ zIndex: "9", gap: '0.5em' }}
                            >
                              <div className="group-by-label me-2 text-nowrap py-2">
                                GROUP BY:{" "}
                              </div>
                              <ul className="d-flex align-items-center overflow-scroll example group-by-btns-container">
                                {groupByData &&
                                  groupByData.length > 0 &&
                                  groupByData.map((groupBy, _) => {
                                    return (
                                      
                                        <li
                                          key={_}
                                          className={`group-by-buttons ${
                                            groupBy?.is_selected === "1"
                                              ? "group-by-buttons-selected"
                                              : "group-by-buttons-unselected"
                                          } text-nowrap my-1`}
                                          onClick={() => 
                                            {
                                              

                                            if(type_of_cart === "temp_wishlist"){
                                              setGroup_in_wishlist(groupBy.short_code)
                                            }else{
                                              setGroup_in_cart(groupBy.short_code)
                                            }
                                          }
                                          }
                                        >
                                          {" "}
                                          {groupBy?.caption}
                                        </li>
                                      
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
  )
}

export default CartGroupBySection
