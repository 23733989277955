import React, { useState } from "react";
import ModalDialog from "../../ModalDialog";
import {
  
  get_informationFromSessionID,
  get_property_settings,
  logOutUser,
} from "../../Helper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Cart from "../../../assets/images/cart_icon_20@3x.png";
import Shortlist from "../../../assets/images/shortlist@3x.png";

function HeaderUserMenu() {
  //const [openUserMenu, setOpenUserMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  //const userID = get_informationFromSessionID("userID");
  const cartcounts = useSelector((state) => state?.reducer?.constants?.count);
  const enable_shortlist = get_property_settings("enable_shortlist");
  const company_code = get_informationFromSessionID("companyCode");

  // useEffect(()=>{

  //     window.addEventListener('click', (e) => closeMenuWhenClickedOutside(e, openUserMenu, setOpenUserMenu, ['fa-user', 'dropdown-toggle', 'dropdown-item']))
  //     return () => {
  //         window.removeEventListener('click', (e) => closeMenuWhenClickedOutside(e, openUserMenu, setOpenUserMenu, ['fa-user', 'dropdown-toggle', 'dropdown-item']))
  //     }
  // }, [openUserMenu])

  return (
    <>
      <ModalDialog
        show={showModal}
        setShow={setShowModal}
        action={logOutUser}
        toastMsg={"Your Successfully logout"}
      />

      {/* -- Header wishlist Start -- */}
      {enable_shortlist && enable_shortlist === "Yes" ? (
        <Link
          to={`/short-list?company_code=${company_code}`}
          className="ec-header-btn ec-header-wishlist header-buttons d-sm-block d-none"
          title="short-list"
        >
          <div className="header-icon">
            {/* <i className="fi-rr-heart"></i> */}
            <img src={Shortlist} alt="wishlist" />
          </div>
          <span className="ec-header-count">
            {cartcounts && cartcounts?.shortlist_count
              ? cartcounts?.shortlist_count
              : 0}
          </span>
        </Link>
      ) : null}

      {/* -- Header wishlist End -- */}
      {/* -- Header Cart Start -- */}
      <Link
        to={`/cart?company_code=${company_code}`}
        className="ec-header-btn ec-side-toggle header-buttons d-sm-block d-none"
        title="cart"
      >
        <div className="header-icon">
          {/* <i className="fi-rr-shopping-bag"></i> */}
          <img src={Cart} alt="cart" />
        </div>
        <span className="ec-header-count cart-count-lable">
          {cartcounts && cartcounts?.count ? cartcounts?.count : 0}
        </span>
      </Link>
      {/* -- Header Cart End -- */}
    </>
  );
}

export default React.memo(HeaderUserMenu);
