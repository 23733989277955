import React, { useEffect, useRef, useState } from "react";
import MenuDropdownList from "./MenuDropdownList";
import { Link, useLocation } from "react-router-dom";
import RecursiveCategoryModal from "../../RecursiveCategory/RecursiveCategoryModal";
import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";
import { FaVideo } from "react-icons/fa";
import { get_informationFromSessionID } from "../../Helper";

function HeaderMenu({ categories }) {
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const HeaderMenuRef = useRef(null)
  const location = useLocation();
  const userID = get_informationFromSessionID("userID")

  useEffect(() => {
    sessionStorage.setItem("HeaderMenu",HeaderMenuRef?.current?.offsetHeight)
    
  }, [categories])

  if (!categories)
    return <p className="text-center opacity-50 mt-3 d-lg-block d-none">Loading....</p>;

  const position =
    location.pathname === "/" || location.pathname === "/home" || location.pathname === "/matrix-page";
  const custom_menu = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.custom_menu);

  
  
  return (
    <div
      id="ec-main-menu-desk"
      className={`d-none d-lg-block sticky-nav home-menu ${
        position ? "sticky-menu" : ""
      }`}
      ref={HeaderMenuRef}>
      {showCategoryModal && <RecursiveCategoryModal
        showCategoryModal={showCategoryModal}
        setShowCategoryModal={setShowCategoryModal}
      />}
      <div className="container-fluid position-relative">
        <div className="row">
          <div className="col-md-12 align-self-center">
            <div className="ec-main-menu">
              <ul>
                {categories?.length > 0 ? (
                  <li
                    className={`${
                      location.pathname === "/" || location.pathname === "/home"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link to="/home">Home</Link>
                  </li>
                ) : null}
                {Array.isArray(categories) && categories?.map((category) => (
                  <MenuDropdownList
                    {...category}
                    key={category.id}
                    setShowCategoryModal={setShowCategoryModal}
                  />
                ))}
                {custom_menu && userID
                  ? custom_menu?.map((menu) => {
                        const isVideoMenu = menu.name.toLowerCase().includes("video")
                      return <li
                        key={menu.name}
                        className={
                            isVideoMenu
                            ? "video_call_menu"
                            : ""
                        }
                      >
                        <a href={menu?.link} target="_blank">
                          {isVideoMenu? <FaVideo className="video_call_icon"/> : null}  
                          {menu.name}
                        </a>
                      </li>
                  }
                    
                    )
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderMenu;
