import { createSearchURLParams, createSelectOptionForReactSelect, get_bulk_settings, get_informationFromSessionID, get_material_datails_from_FSV, get_plain_value_from_inputs, get_property_settings, get_values_base_on_short_code_from_FSV, get_which_master_from_FSV, set_informationToSessionID } from "../../Component/Helper"
import { Pcs_stepper_calc, gross_weight_converstion, gst_and_all_total_calc, karat_amount_calc, karat_to_weight_converstion, labour_calc, material_all_total, size_to_weight_converstion } from "../../calculation"
import { set_FSV_Handler } from "../../redux/Reducer/reducer"

export const FSV_ACTIONS = {
    SET_PRODUCTS : "SET_PRODUCTS",
    SET_SELECTED_PRODUCT : "SET_SELECTED_PRODUCT",
    SET_PRODUCT_CALC : "SET_PRODUCT_CALC",
    SET_REMARKS: "SET_REMARKS",
    SET_QUANTITY: "SET_QUANTITY",
    SET_SELECTED_SUBPRODUCTS: "SET_SELECTED_SUBPRODUCTS"
}

const {size_diameter_based_wt_change, size_change_wt_on} = get_bulk_settings([
  "size_diameter_based_wt_change",
  "size_change_wt_on"
])

export function get_short_code_name (short_code){
  
  if(short_code === "selection_karat_catalogue") {
    return "karat"
  } else if(short_code === "selection_multiple_grosswt"){
    return "selected_gross_wt"
  } else if(short_code === "selection_tone_catalogue"){
    return "tone"
  }else if(short_code === "selection_piece_catalogue"){
    return "piece"
  }else if(short_code === "selection_size_catalogue"){
    return "size"
  }else{
    return short_code
  }
}

export function get_default_selected(product) {
  let defaultValues = {}
    
    if(!product) return defaultValues
    const selectType = get_property_settings("catalogue_selection_based_on")
    // const selectType = "dropdown"
    
    const selection_position = product?.selection_position;
    
    if (Array.isArray(selection_position) && selection_position.length > 0) {
      const selection_template = [
        { short_code: "selection_multiple_grosswt", defaults_key: "gross_wt"},
        { short_code: "selection_karat_catalogue", defaults_key: "karat"},
        { short_code: "selection_size_catalogue", defaults_key: "size" },
        { short_code: "selection_tone_catalogue", defaults_key: "tone" },
        { short_code: "cc_hook", defaults_key: "cc_hook" },
        //isObject: if this selection value is not string it's object (e.g {product_id: "459", variant_name: "75GM - 2.5IN"}) / defaults: "variant": "75GM - 2.5IN"
        { short_code: "selection_variant_catalogue", defaults_key: "variant", isObject: true, label_value: "variant_name", id_value: "product_id" }, 
        
      ];
      console.log(selection_position)
      selection_template.forEach((template) => {
        const selection = selection_position.find(
          (selection) => selection.short_code === template.short_code
        );
        
        if (selection) {
          const key = template.defaults_key;
          let value = ""
          const default_key_value = product?.defaults[key]
          if(template.isObject){ //if selection value is object
              product[template.short_code].find(selection_value_obj => {
                   if(selection_value_obj[template.label_value] === default_key_value) {
                      value = selection_value_obj[template.id_value]
                   }
              })
          }else{ //if selection value is string
             value = product?.defaults[key]
          }
          const short_code = get_short_code_name(template?.short_code)
          
         
          defaultValues = {...defaultValues, [short_code]: selectType === "dropdown" ? createSelectOptionForReactSelect(value) : value}
          
          // defaultValues[short_code] = selectType === "dropdown" ? createSelectOptionForReactSelect(product?.defaults[key]) : product?.defaults[key]
        } else {
          //code for if selection_position.short_code value and selection_template.short_code value not match (null)
        }
      });
    }
      
    
    
     
    
    
    return defaultValues
}

export function setValues(defaultValue, required_keys_array, setValue){
  
  
  required_keys_array?.forEach(name => {
    
    setValue(name, defaultValue[name])
  })

}

export function update_default_selected_to_all_subproduct(allProduct, category_name, updatedProducts){
    let Obj = {};
    const updatedProducts_keys = Object.keys(updatedProducts)
    category_name.forEach((category) => {
      if(!updatedProducts_keys.includes(category)){
        const getProduct = allProduct.find(prod => prod.category_name === category)
        Obj[category] = {...get_default_selected(getProduct), quantity: Number(getProduct?.min_order_quantity) || 1}
      }
    })
   
    return {...updatedProducts, ...Obj}
}

export function set_cart_params(selectedProduct, products_calc, typeOfCart, quantity, remarks){

  const userId = get_informationFromSessionID("userID")
  const selectType = get_property_settings("catalogue_selection_based_on");
  
  

  const selected_gross_wt= selectType === "dropdown" ? products_calc?.selected_gross_wt?.value : products_calc?.selected_gross_wt;
  const size = selectType === "dropdown" ? products_calc?.size?.value : products_calc?.size;
  const piece= selectType === "dropdown" ? products_calc?.piece?.value : products_calc?.piece;
  const karat= selectType === "dropdown" ? products_calc?.karat?.value : products_calc?.karat;
  const tone= selectType === "dropdown" ? products_calc?.tone?.value : products_calc?.tone;

  const { which_master, master_id } = get_which_master_from_FSV(selectedProduct);
   
    const short_codes = get_values_base_on_short_code_from_FSV(
      selectedProduct?.short_code,
      selectedProduct?.values,
      ["gross_wt", "net_wt", "total_cost", "default_karat_id"]
    );
    
    const material_details = get_material_datails_from_FSV(
      selectedProduct?.material,
      [
        "pieces",
        "weight",
        "material_id",
        "cut_id",
        "sieve_size_id",
        "material_table_id",
        "cts",
        "shape_id",
        "other_charges",
        "clarity_id",
        "color_id",
      ]
    );
   
  const params = {
    user_id: userId ? userId : "",
    type_of_user: "normal_user",
    session_id: "",
    quantity: quantity,
    table: typeOfCart === "wishlist" ? "temp_wishlist" : "temp_cart",
    product_id: products_calc?.selection_variant_catalogue || selectedProduct[master_id] || "",
    parent_id: selectedProduct[master_id] || "",
    design_inventory: which_master,
    gross_wt: products_calc?.calc_gross_weight || short_codes?.gross_wt || "",
    net_wt: products_calc?.calc_net_weight || short_codes?.net_wt || "",
    selected_gross_wt: selected_gross_wt || "",
    size: size || "",
    piece: piece || "",
    karat: karat || short_codes?.default_karat_id || "",
    tone: tone || "",
    certification: "",
    hallmarking: "",
    diamond_catalogue_id: "",
    diamond_clarity_id: "",
    diamond_color_id: "",
    price: products_calc?.calc_total_after_tax_amt || short_codes?.total_cost || "",
    remarks,
    random: new Date().toLocaleString(),
    device: "web",
    materials: material_details,
  }

  return params
}

export function calculate_prod (selectedProduct, products_calc, short_code, value, fsv_count, dispatch) {
  const category_name = selectedProduct?.category_name
  
  // const selectType = "dropdown"
  const selectedValue = products_calc[category_name]
  const current_prod_calc = {}
  
  

  let selected_gross_wt = short_code === "selected_gross_wt" ? get_plain_value_from_inputs(value) : get_plain_value_from_inputs(selectedValue?.selected_gross_wt)
  let karat = short_code === "karat" ?  get_plain_value_from_inputs(value) : get_plain_value_from_inputs(selectedValue?.karat)
  let size = short_code === "size" ? get_plain_value_from_inputs(value) : get_plain_value_from_inputs(selectedValue?.size)
  
  if (selectedProduct && selectedProduct?.karat_rates) {
    
  const metal_rate = selectedProduct?.karat_rates?.find(
      (karat_rate) => {
        
        return karat_rate.karat_value?.toString() === karat?.toString()
      }
    )?.rate || "0"

    current_prod_calc["metal_rate"] = metal_rate;
    

    const { grossWeight: grossWeight1, netWeight: netWeight1 } = gross_weight_converstion(selected_gross_wt, selectedProduct);
    
    


    const { grossWeight, netWeight } = karat_to_weight_converstion(
      karat,
      grossWeight1,
      netWeight1,
      selectedProduct
    );
    
    
    

    const last_values = size_to_weight_converstion(
      size,
      grossWeight,
      netWeight,
      size_change_wt_on,
      size_diameter_based_wt_change,
      selectedProduct
    );
    

    
    
    //calc karat Rate
    const { karat_amount } = karat_amount_calc(
      karat,
      last_values?.grossWeight,
      last_values?.netWeight,
      selectedProduct
    );
    
    current_prod_calc["calc_karat_rate"] = karat_amount

    
    // Calc labour
    const { labourAmount, thisLabourVal, wasteage } = labour_calc(
      size,
      karat,
      last_values?.grossWeight,
      last_values?.netWeight,
      selectedProduct
    );
    current_prod_calc["calc_making_charge"] = thisLabourVal
    current_prod_calc["calc_wasteage"] = wasteage

    //Calc Total Material
    const Total_Mat_Amt = material_all_total(selectedProduct.material, "total", false);
    current_prod_calc["calc_total_material_amt"] = Total_Mat_Amt

    //Calc wt based on Stepper PCs
    
    const { newGW, newNW } = Pcs_stepper_calc(
      last_values?.grossWeight,
      last_values?.netWeight,
      fsv_count,
      selectedProduct
    );
    
    
    current_prod_calc["calc_gross_weight"] = newGW
    current_prod_calc["calc_net_weight"] = newNW

    

    //GST and Total Calc
    const { GST, total_after_tax } = gst_and_all_total_calc(
      karat_amount,
      Total_Mat_Amt,
      labourAmount,
      0,
      selectedProduct
    );

    current_prod_calc["calc_GST_amt"] = GST
    current_prod_calc["calc_total_after_tax_amt"] = total_after_tax
    
    if(short_code && value){
      dispatch(set_FSV_Handler({
        type: FSV_ACTIONS.SET_PRODUCT_CALC,
        payload: {
          product_name: category_name,
          data: {[short_code]: value, ...current_prod_calc}
        }
      }))
    }else{
      
      dispatch(set_FSV_Handler({
        type: FSV_ACTIONS.SET_PRODUCT_CALC,
        payload: {
          product_name: category_name,
          data: current_prod_calc
        }
      }))
    }
    
  }
}



export function merge_product_and_subproduct(FSV_Data){
  if(FSV_Data.subproducts.length > 0) {
    return [FSV_Data, ...FSV_Data?.subproducts]

  }else{
    return [FSV_Data]
  }
}

export function get_fsv_prod_details(property, selectedProd){
    let details = {};
    if(Array.isArray(property)){

      property.forEach((prop) => {
        details[prop] = selectedProd[prop]
      })
    }else{
      details[property] = selectedProd[property]
    }

    return details
}

export function handle_fsv_button_value (short_code, value) {
    if(short_code === "selection_variant_catalogue"){
      return {value: value?.product_id, label: value?.variant_name}
    }else{
      return value
    }
}

export function FSV_data_url (params) {
    let {pathname, search} = window.location;
    pathname = pathname.replace("/", "");
    const searchParams = Object.fromEntries(new URLSearchParams(search));
    return createSearchURLParams(pathname, {...searchParams, ...params}) //"my_catalogue"
} 