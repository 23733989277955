export const CUSTOMIZIED_COMPANY_PROPERTY = {
    new_arrivals: "new_arrivals",
    custom_menu: "custom_menu",
    horizontal_menu: "horizontal_menu"
}


export class Company {
  constructor(value) {
    this.new_arrivals = value?.new_arrivals || "New Arrivals";
    this.custom_menu = value?.custom_menu || null;
    this.horizontal_menu = value?.horizontal_menu || {status: false}
    this.hide_elements = value?.hide_elements || [];
  }
}




