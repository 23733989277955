import React from 'react';
import styles from "../../../CSS/HomeCSS/CardSection.module.css";
import { set_informationToSessionID } from '../../Helper';
import {useNavigate} from "react-router-dom";

function CardHeader({title, subtitle, btnName, setShowCategoryModal, categoryModalListFunction, clsName = ""}) {
    const navigate = useNavigate()

    return (
        <div className="row">
            <div className={`col-md-12 ${styles["section-header"]} ${clsName}`}>
                <div className={styles["section-title"]}>
                    <h2 className={styles["ec-title"]}>{title}</h2>
                    {subtitle && <p className={styles.subTitle}>{subtitle}</p>}
                </div>
                {btnName && <div className={styles["section-btn"]}
                onClick={()=> {
                    if(btnName === "View All Categories"){
                        setShowCategoryModal(true)
                        categoryModalListFunction()
                    }else{
                        set_informationToSessionID({isFData: false})
                        navigate('/matrix-page');
                    }
                }}
                >
                    <a className={styles["btn-primary"]}>{btnName}</a>
                </div>}
            </div>
        </div>
    )
}

export default CardHeader
