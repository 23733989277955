import React, { useCallback, useEffect, useRef, useState } from "react";
import leftArrow from "../../assets/images/left-angle.png";
import rightArrow from "../../assets/images/right-angle.png";
import ArrowKeysReact from "arrow-keys-react";
import { calculatedDOM_Height, createSearchURLParams, debounce, get_informationFromSessionID, get_property_settings, set_informationToSessionID } from "../../Component/Helper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import NoProduct from "../../Component/NoProduct/NoProduct";
import ProductPreview from "./components/ProductPreview/ProductPreview";
import FSVFooter from "./components/FSVFooter/FSVFooter";
import FSVDetails from "./components/FSVDetails/FSVDetails";
import FSVInputs from "./components/FSVInputs/FSVInputs";
import { FSV_ACTIONS,  merge_product_and_subproduct } from "./Helper";
import {  fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import { useDispatch, useSelector } from "react-redux";
import { getCatMaster, get_is_myc_web, set_FSV_Handler } from "../../redux/Reducer/reducer";
import Loader from "../../Component/Loader";
import { useQuery } from "@tanstack/react-query";



function FullScreenView() {
  const navigate = useNavigate();
  const location = useLocation();
  const fsvContainerRef = useRef(null);
  const { id } = useParams();
  const [page, setPage] = useState(id);
  const [hideArrow, setHideArrow] = useState(parseInt(id) === 0 ? "left" : "");
  const [RTL, setRTL] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);
  // const [AllProducts, setAllProducts] = useState([]);
  
  const userId = get_informationFromSessionID("userID");
  const is_myc_web = useSelector(get_is_myc_web);
  const master = useSelector(getCatMaster);
  const filterParams = get_informationFromSessionID("filterParams") || {};
  const total_product_count =
    parseInt(get_informationFromSessionID("totalProductCount")) || 0;

  const searchParams = Object.fromEntries(new URLSearchParams(location.search));

  const defaultData = get_property_settings("default_matrix_page_load")

  
 

  const dispatch = useDispatch()

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  


  const {isLoading, data: AllProducts, isError} = useQuery({
    queryKey: [page, searchParams, "full-screen-view"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.fullScreen, "full-screen-view", {
        user_id: userId || "",
        collection_id: 0,
        mode: is_myc_web ? "catalogue_filter" : "filter",
        data: 1,
        table: master,
        page: searchParams?.mode === "homescreen" ? 0 : page,
        design_status: "1,2",
        cat_id: searchParams?.cat_id || 0,
        ...searchParams,
        ...filterParams,
    }).then(products => {
      
      if(products[0]?.subproducts?.length > 0){
        const products_with_subproducts = merge_product_and_subproduct(products[0])
        dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_PRODUCTS, payload: products_with_subproducts}))
        dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_SELECTED_PRODUCT, payload: products_with_subproducts[0]}))
        
        
        return products_with_subproducts
      }else{
        dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_PRODUCTS, payload: products}))
        dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_SELECTED_PRODUCT, payload: products[0]}))
        
        
        return products
       }
    }),
    initialData: [],
    
  });

  

 

  const nextProduct = (page, sort, cat_id) => {
   
    const nextpage = parseInt(page) + 1;
    if (hideArrow !== "right") {
      setPage(nextpage);
    }
    hideArrow !== "right" &&
      navigate(`/product-page/${nextpage}?sort=${sort}&cat_id=${cat_id}`);
  };
  const previousProduct = (page, sort, cat_id) => {
    const prevpage = parseInt(page) - 1;
    if (hideArrow !== "left") {
      setPage(prevpage);
    }

    hideArrow !== "left" &&
      navigate(`/product-page/${prevpage}?sort=${sort}&cat_id=${cat_id}`);
  };

  

  const handleDebouncedKeyPress = useCallback(
    debounce((e) => {
      
      if (!isLoading) {
        if (e.key === "Escape") {
          const filter = get_informationFromSessionID("filter") || {
            sort: 0,
            page: 0,
          };
          const gotopage = Math.ceil((parseInt(id) + 1) / defaultData) - 1 || 0;
          
          const matrixPagePath = createSearchURLParams("matrix-page", {
            ...filter,
            ...filterParams,
            cat_id: searchParams?.cat_id,
            page: gotopage,
          });
          const currProd = get_informationFromSessionID("currProd")
          
          navigate(matrixPagePath, {state: location?.pathname, currProd});
        }
      }
    }, 300), // Adjust the delay (in milliseconds) as needed
    [id]
  );



  useEffect(() => {
    // fetch_FSV_data()
    window.scrollTo(0,0)
    sessionStorage.setItem("FSV_Swipe", JSON.stringify(true))
    const id = Number(page);
    if (id === 0) {
      setHideArrow("left");
    } else if (id === total_product_count - 1) {
      setHideArrow("right");
    } else {
      setHideArrow("");
    }
    
    fsvContainerRef?.current?.focus()

    document.addEventListener("keydown", handleDebouncedKeyPress);
    set_informationToSessionID({currProd: Number(page)})
    dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_PRODUCT_CALC, payload: {action: "reset"}}))
    return () => {
      document.removeEventListener("keydown", handleDebouncedKeyPress);
    };
  }, [page]);

  ArrowKeysReact.config(
    searchParams?.mode === "homescreen"
      ? {}
      : {
          left: () => {
            previousProduct(page, searchParams?.sort, searchParams?.cat_id);
          },
          right: () => {
            nextProduct(page, searchParams?.sort, searchParams?.cat_id);
          },
        }
  );
    
  
  

  return (
    <>
      {!isLoading && AllProducts?.length > 0 ? <div
        {...ArrowKeysReact.events}
        tabIndex="1"
        className="products-page fsv-container"
        id="products-page"
        ref={fsvContainerRef}
        onLoad={() => {
          fsvContainerRef?.current?.focus();
          window.scrollTo(0,0)
        }}
      >
        {searchParams?.mode !== "homescreen" &&
          total_product_count > 1 && (
            <div
              // className="next-prev-buttons-container next-prev-buttons-container-sm d-none d-md-flex "
              className="next-prev-buttons-container next-prev-buttons-container-sm d-flex "
              style={{ height: "670px", width: "100%" }}
            >
              <div id="left-button" className="next-prev-button-container prev">
                {hideArrow !== "left" && (
                  <img
                    alt="previous product"
                    src={leftArrow}
                    height="61"
                    width="40"
                    rel="prev"
                    className="left-angle"
                    onClick={() => {
                      previousProduct(
                        page,
                        searchParams?.sort,
                        searchParams?.cat_id
                      );
                    }}
                  />
                )}
              </div>
              {hideArrow !== "right" && (
                <div
                  id="right-button"
                  className="next-prev-button-container next"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    alt="next product"
                    src={rightArrow}
                    height="61"
                    width="40"
                    rel="next"
                    className="right-angle"
                    onClick={() => {
                      nextProduct(page, searchParams?.sort, searchParams?.cat_id);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        {!isLoading ? (
          <Slider
            infinite={false}
            arrows={false}
            swipe={window.innerWidth < 768}
            // swipeToSlide={true}
            onSwipe={(direction) => {
              
              if (direction === "left") {
                
                nextProduct(page, searchParams?.sort, searchParams?.cat_id);
                setRTL(false);
              } else if (direction === "right") {
                setRTL(true);
                previousProduct(page, searchParams?.sort, searchParams?.cat_id);
              }
            }}
            speed={0}
            edgeFriction={0}
            initialSlide={1}
            rtl={RTL}
          >
            <>
              {isError ? (
                <section className="ec-page-content section-space-p container-xxl container-fluid">
                  <NoProduct errMsg={"No Product Found"} />
                </section>
              ) : (
                <>
                  
                  <section className="ec-page-content section-space-p container-fluid fsv-section">
                    <div className="container-fluid  mx-auto p-0">
                      <div className="row">
                        <div className="ec-pro-rightside ec-common-rightside col-lg-12 col-sm-12">
                          <div className="single-pro-block">
                            <div className="single-pro-inner">
                              <div className="row justify-content-center fsv-col">
                                <ProductPreview nav1={nav1} nav2={nav2} setNav1={setNav1} setNav2={setNav2}/>

                                {/* details */}
                                <div
                                  className="col-xl-4 col-lg-5 col-md-5 col-11 mt-sm-0 mt-5 fsv-prod-details-section"
                                  style={{ marginBottom: "100px" }}
                                >
                                  <FSVInputs nav1={nav1} nav2={nav2} master={master}/>

                                  <FSVDetails master={master}/>
                                </div>
                              </div>
                              {/* {data && showScrollBtn && <div
                                  className="top-Btn d-flex justify-content-center"
                                  id="scroll-to-top-btn"
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/favicon/up-arrow.png")}
                                    height="20"
                                    width="20"
                                  />

                                  <button>Scroll To Top</button>
                                </div>} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  
                </>
              )}
            </>
          </Slider>
        ) : (
          <NoProduct errMsg={"No Data Found"} showLoader={true} />
        )}

        <FSVFooter/>
      </div> : <Loader isLoading={true} height={calculatedDOM_Height(75)}/>}
    </>
  );
}

export default FullScreenView;
