import React, { useEffect } from "react";
import PhoneNumberInput from "../../Component/PhoneNumberInput";
import { useState } from "react";
import {
  ApiList,
  BASE_URL,
  PROXY_BASE_URL,
  company_code,
} from "../../Api/ApiList";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  ToastError,
  ToastSuccess,
  decryptData,
  encryptData,
  fetchSecureData,
  get_informationFromSessionID,
  get_settings,
} from "../../Component/Helper";

function ForgotPassword() {
  const navigate = useNavigate();
  const [formData, setFormDate] = useState({});
  const handleChange = (value, name, obj) => {
    obj
      ? setFormDate({ ...formData, ...obj })
      : setFormDate({ ...formData, [name]: value });
  };

  const [error, setError] = useState();

  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isNumAvailable, setIsNumAvailable] = useState(false); // CHANGE it !!!!!!!!!!!!!!!!111
  const [otp, setOtp] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [resOtp, setResOtp] = useState("");
  const [otpMsg, setOtpMsg] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const company_code = get_informationFromSessionID("companyCode");
  const settings = get_settings()

  const handleSubmit = async (e, formData, company_code) => {
    e.preventDefault();
    const { mobile_no, country_code } = formData;
    if (mobile_no) {
      try {
        //Proxy-Implementation

        const Url = `${PROXY_BASE_URL}/api-call/?forgot-password-code-gen`; // Update the endpoint
        const obj = {
          params: encryptData({
            country_code: country_code ? country_code : "",
            string_random_internal: 1454691677,
            company_code: company_code,
            session_id: 40,
            sb_jewelpos_mode: "Yes",
            mobile_no: mobile_no ? mobile_no : "",
          }),
          api_url: encryptData(
            `${BASE_URL}${ApiList.UserRegistration}/${ApiList.forgot_password_code_gen}`
          ),
        };
        const response = await fetchSecureData(Url, obj);
        const res = decryptData(response);

        // Old Implementation
        // const url =
        //   BASE_URL +
        //   `UserRegistration/forgot_password_code_gen?country_code=${
        //     country_code ? country_code : ""
        //   }&string_random_internal=1454691677&company_code=TElWRURFTU8=&session_id=40&sb_jewelpos_mode=Yes&mobile_no=${
        //     mobile_no ? mobile_no : ""
        //   }`;
        // // `Login?company_code=TElWRURFTU8=&long=0&device_ip=&lat=0&password=${password ? password : ""
        // // }&device_token=&mobile_no=${mobile_no ? mobile_no : ""
        // // }&random_user_id=&string_random_internal=8873021192&login_from=web&device_id=&device_model=`;
        // const res = await axios.get(url);
        if (res.ack === "0") {
          ToastError(res.error);
          setIsNumAvailable(false);
        } else if (res.ack === "1") {
          setResOtp(res.mobile_key);
          // ToastSuccess(res.msg);
          setOtpMsg(res.msg);
          setIsNumAvailable(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const verifyOTP = () => {
    // e.preventDefault();
    if (otp.length === 6 && otp === resOtp) {
      setShowPasswordFields(true);
      ToastSuccess("OTP Verified Successfully");
      setResetPassword(true);
    } else if (otp.length === 6 && otp !== resOtp) {
      ToastError("Wrong OTP...!");
    }
  };

  const verifyPassword = async (e, formData, company_code) => {
    e.preventDefault();
    const { password, confirm_password, mobile_no } = formData;
    if (password && password.length !== 0 && confirm_password.length !== 0) {
      if (password === confirm_password) {
        try {
          //Proxy-Implementation

          const Url = `${PROXY_BASE_URL}/api-call/?reset-password`; // Update the endpoint
          const obj = {
            params: encryptData({
              password: password,
              string_random_internal: 4015423486,
              company_code: company_code,
              session_id: 40,
              sb_jewelpos_mode: "Yes",
              mobile_no: mobile_no,
            }),
            api_url: encryptData(
              `${BASE_URL}${ApiList.UserRegistration}/${ApiList.reset_password}`
            ),
          };
          const response = await fetchSecureData(Url, obj);
          const res = decryptData(response);

          // Old Implementation
          // const url =
          //   BASE_URL +
          //   `UserRegistration/reset_password?password=${password}&string_random_internal=4015423486&company_code=TElWRURFTU8=&session_id=40&sb_jewelpos_mode=Yes&mobile_no=${mobile_no}`;

          // const res = await axios.get(url);
          if (res.ack === "0") {
            ToastError(res.error);
          } else if (res.ack === "1") {
            navigate("/login");
            setTimeout(() => {
              ToastSuccess(res.msg);
            }, 1000);
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        ToastError("Password and Confirm Password does not match.");
      }
    } else {
      ToastError("Please Enter a Password.");
    }
  };
  const verify = (type) => {
    let error = {};
    const key = Object.keys(formData);
    const value = Object.values(formData);

    if (formData["mobile_no"] && formData["country_code"]) {
      if (formData["mobile_no"].length === 10) {
      } else {
        error = { ...error, mobile_no: "Please Enter valid phone No." };
      }
    } else {
      error = { ...error, mobile_no: "Phone No. is Mandatory" };
    }
    // value.map((d, _) => {
    //   if (d) {
    //     if (formData["mobile_no"].length !== 10) {
    //       error = {
    //         ...error,
    //         ["mobile_no"]: `Please Enter valid phone No.`,
    //       };
    //     }
    //   } else {
    //     error = {
    //       ...error,
    //       [key[_]]:
    //         key[_] === "mobile_no"
    //           ? `Phone no is required Field`
    //           : `${key[_]} is required Field`,
    //     };
    //   }
    // });

    setError(error);
    return Object.keys(error).length === 0 ? true : false;
  };

  useEffect(() => {
    if (otp.length >= 6) {
      verifyOTP();
    }
  }, [otp]);

  return (
    <>
      {/* <BreadCrumb
        From="/login"
        PrevpPage="Login"
        title="Forgot-Password"
        activePage="Forgot-Password"
      ></BreadCrumb> */}
      <section id="ForgotContainer">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title m-0 d-flex flex-column justify-content-center">
                <h2 className="my-4 ec-title title">Forgot Password</h2>
                {isOtpVerified ? (
                  <p className="mb-4 normalText">
                    To reset your password, enter the new password and confirm
                    password
                  </p>
                ) : !isNumAvailable ? (
                  <p className="mb-3 normalText">
                    To reset your password, enter the mobile number you use to
                    sign in to {settings && settings?.app_name}
                  </p>
                ) : (
                  <p className="mb-3 normalText">{!resetPassword && otpMsg}</p>
                )}
              </div>
            </div>
            <div className="ec-login-wrapper forgot-wrapper">
              <div className="ec-login-container forgot-container">
                <div className="ec-login-form">
                  <form action="#" method="post">
                    {!isNumAvailable ? (
                      <>
                        <span className="ec-login-wrap">
                          <PhoneNumberInput handleChange={handleChange} />
                          {/* <label>Email Address*</label>
                          <input
                          type="text"
                          name="name"
                          placeholder="Enter your email add..."
                          required=""
                        /> */}
                          <div className="required-field">
                            {error && error["mobile_no"]}
                          </div>
                        </span>
                        <span className="login-button">
                          <button
                            tabIndex={2}
                            className="w-50 btn btn-primary primary_button btn-primary send-otp-btn"
                            type=""
                            onClick={(e) => {
                              e.preventDefault();
                              if (verify()) {
                                handleSubmit(e, formData, company_code);
                              }
                            }}
                          >
                            SEND OTP
                          </button>
                        </span>
                      </>
                    ) : (
                      <>
                        <div className="d-flex flex-column align-items-center justify-content-center m-1">
                          {/* <span className="ec-login-wrap">
                                                            <label>Password*</label>
                                                            <input
                                                                type="password"
                                                                name="password"
                                                                placeholder="Enter your password"
                                                                required=""
                                                                onChange={(e) =>
                                                                    handleChange(e.target.value, e.target.name)
                                                                }
                                                            />
                                                        </span> */}
                          {showPasswordFields ? (
                            <>
                              <div className=" w-100 d-flex align-item-center password-input-wrapper reset-password-inputs">
                                <input
                                  className="mb-2 mt-2 fieldValue"
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Enter Password"
                                  onChange={(e) => {
                                    handleChange(e.target.value, e.target.name);
                                    e.target.value !== formData["password"]
                                      ? setError({
                                          ...error,
                                          password: "password must match",
                                        })
                                      : setError({
                                          ...error,
                                          password: "",
                                        });
                                  }}
                                  required
                                />
                                {/* Add the eye icon and toggle the visibility onClick */}
                                <span
                                  className="password-toggle-icon"
                                  onClick={() => {
                                    setShowPassword((prevState) => !prevState);
                                  }}
                                >
                                  {showPassword ? (
                                    <>
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    </>
                                  ) : (
                                    <>
                                      <FontAwesomeIcon icon={faEye} />
                                    </>
                                  )}
                                </span>
                              </div>
                              <div className="w-100 d-flex align-item-center password-input-wrapper reset-password-inputs">
                                <input
                                  className="mb-2 mt-2 fieldValue"
                                  name="confirm_password"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  placeholder="Enter Confirm Password"
                                  onChange={(e) => {
                                    handleChange(e.target.value, e.target.name);
                                    e.target.value !== formData["password"]
                                      ? setError({
                                          ...error,
                                          password: "password must match",
                                        })
                                      : setError({
                                          ...error,
                                          password: "",
                                        });
                                  }}
                                  required
                                />
                                {/* Add the eye icon and toggle the visibility onClick */}
                                <span
                                  className="password-toggle-icon"
                                  onClick={() => {
                                    setShowConfirmPassword(
                                      (prevState) => !prevState
                                    );
                                  }}
                                >
                                  {showConfirmPassword ? (
                                    <>
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    </>
                                  ) : (
                                    <>
                                      <FontAwesomeIcon icon={faEye} />
                                    </>
                                  )}
                                </span>
                              </div>
                              <div className="m-1 w-100">
                                <button
                                  onClick={(e) => {
                                    verifyPassword(e, formData, company_code);
                                  }}
                                  className="w-50 btn btn-primary primary_button reset-password-btn"
                                >
                                  Reset Password
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="otp-inputs verify-otp" style={{width: '100%'}}>
                              <OTPInput
                                id="input"
                                inputType="number"
                                value={otp}
                                onChange={setOtp}
                                isInputNum={true}
                                numInputs={6} // The number of OTP input boxes
                                // renderSeparator={
                                //   <div className="mx-1 mx-sm-3"></div>
                                // }
                                shouldAutoFocus // Optional: Automatically focus the first input box on mount
                                renderInput={(props) => (
                                  <input
                                    type="number"
                                    {...props}
                                    className="no-selection fieldValue input-opt-box"
                                  />
                                )}
                                // inputStyle={{
                                //   padding: 0,
                                //   width: "40px",
                                //   height: "40px",
                                // }} // Optional styling for each input box
                              />
                              <span className="w-100" style={{display: 'flex', justifyContent: "center"}}>
                                <button
                                  className="w-50  btn btn-primary primary_button btn-primary verify-otp-btn"
                                  type=""
                                  onClick={(e) => verifyOTP(e)}
                                  
                                >
                                  VERIFY OTP
                                </button>
                              </span>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
