import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { ToastError, get_informationFromSessionID, get_property_settings } from "../../../Component/Helper";
import { fetch_data_from_api } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import {useSelector} from "react-redux";
import { get_is_myc_web } from "../../../redux/Reducer/reducer";

function OTPLoginContainer({setShowOptContainer, OTPRes, mobile_no, redirect_after_successful_login}) {
    const login_based_on = get_property_settings("login_based_on");
    const is_myc_web = useSelector(get_is_myc_web)
    const mycat_v2_login_based_on = get_property_settings("mycat_v2_login_based_on")
    const [inputOtp, setInputOtp] = useState("");

    const verify_opt = async () => {
      if(inputOtp.length !== 6) return ToastError("Please Enter 6 digit OTP Number")
      if(inputOtp !== OTPRes) return ToastError("OTP does not match please try again")
      const contry_code = get_informationFromSessionID("country_code") || "91"
        const verify_opt_response = await fetch_data_from_api(ApiList.login, 'verifyotp-login', {
          login_from: "web",
          mobile_no: mobile_no.replace(contry_code, ""),
          long: 0,
          lat: 0,
          device_token: "",
          device_model: "",
          device_ip: "",
          device_id: "",
          // sb_jewelpos_mode: "No",
          otp: inputOtp,
        })


        
        if(verify_opt_response?.error){
          ToastError(verify_opt_response?.error)
        }else if(verify_opt_response?.ack === "1"){
          redirect_after_successful_login(verify_opt_response)
          
        }
        setShowOptContainer(false)
    }

    useEffect(() => {
      if(inputOtp.length < 6) return
      verify_opt()
    }, [inputOtp])


  return (
    
        <form noValidate>
          <span className="ec-login-wrap">
            <div className="otp-inputs">
              <OTPInput
                id="input"
                inputType="number"
                value={inputOtp}
                onChange={(e) => {
                  setInputOtp(e);
                  
                }}
                isInputNum={true}
                numInputs={6}
                shouldAutoFocus // Optional: Automatically focus the first input box on mount
                renderInput={(props) => (
                  <input
                    type="number"
                    {...props}
                    className="no-selection fieldValue input-opt-box"
                  />
                )}
              />
            </div>
          </span>

          <span className="login-button">
            <button
              className={`btn btn-outline-dark text text-nowrap w-100 btn-primary primary_button btn-primary`}
              
              tabIndex="0"
              onClick={(e)=>{
                e.preventDefault(); 
                verify_opt()
              }}
            >
              VERIFY CODE
            </button>
          </span>

          {((!is_myc_web && login_based_on !== "otp") || (is_myc_web && mycat_v2_login_based_on !== "otp")) && <span className="login-button">
            <button
              className={`btn btn-outline-dark text text-nowrap w-100 ${
                login_based_on !== "otp"
                  ? "secondary_button border btn-secondary"
                  : "btn-primary primary_button btn-primary"
              }`}
              
              tabIndex="0"
              onClick={(e)=>{
                e.preventDefault(); 
                setShowOptContainer(false)}}
            >
              LOGIN WITH PASSWORD
            </button>
          </span>}
        </form>
    
  );
}

export default OTPLoginContainer;
