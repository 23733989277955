import React, { useEffect, useState } from 'react'
import shipping_billing_params from "./components/shipping_billing_params.json"
import "./style/ShippingBilling_style.css"
import Formfields from './components/Formfields'
import { useForm } from "react-hook-form"
import { createOrder, get_payment_gateway_details, setFinalData, setFormDataValue, vaildate_mobileNo } from './ShippingBillingHelper'
import { useNavigate, useLocation } from 'react-router-dom'
import { ToastError } from '../../Component/Helper'


function ShippingBilling() {
  const [hide_shipping_details, set_hide_shipping_details] = useState(true)
  
  const location = useLocation()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm()
  
  
  
  

  
  const onSubmit = async (formData) => {
    const AMOUNT = location?.state?.amount;
    const is_vaildate_mobileNo = vaildate_mobileNo(formData, setError)
    
    if(is_vaildate_mobileNo.status){
      is_vaildate_mobileNo.errors.forEach((err) => {
        setError(...err)
      })
      return 
    }
    const modify_form_data = {...setFormDataValue(formData), order_remarks: "", amount: AMOUNT}

   // return console.log(modify_form_data)
    const order = await createOrder(AMOUNT)
    
    if(order?.error){
      return ToastError(order?.error?.description || "Somthing went wrong")
    }else{

      get_payment_gateway_details(order, modify_form_data)
    }
    



  }
  
  return (
    <section className="ShippingBilling_Section" onLoad={()=> window.scrollTo(0,0)}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Formfields header="Billing Details" fields={shipping_billing_params?.billing} control={control} register={register} errors={errors}/>

        <div className='copy_to_shipping'>
          <input type="checkbox" className='copy_to_shipping_input' id="copy_to_shipping"
          checked={hide_shipping_details}
          onChange={() => set_hide_shipping_details(pre => !pre)}
          />
          <label htmlFor="copy_to_shipping">Copy same to shipping details</label>
        </div>

        {!hide_shipping_details && <Formfields header="Shipping Details" fields={shipping_billing_params?.shipping} control={control} register={register} errors={errors}/>}
        
        <button className='submit-btn' type="submit">Proceed to Payment</button>
      </form>
    </section>
  )
}

export default ShippingBilling
