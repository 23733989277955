import React, {
  useEffect,
  useState,
  
} from "react";
import ReactSelect from "./ReactSelect";
import {
  createSelectArrayForReactSelect,
  get_informationFromSessionID,
  get_userData_from_activeUser,
} from "../../../../../Component/Helper";
import { fetch_data_from_api } from "../../../../../Api/APICalls";
import { ApiList } from "../../../../../Api/ApiList";
import { useDispatch, useSelector } from "react-redux";
import { useLocation} from "react-router-dom";
import {
  getDistributor,
  setDistributor,
} from "../../../../../redux/Reducer/reducer";

export const convertOBJ_to_html_react_options = (retailer_list) => {
  return retailer_list.map((list) => {
    return {
      value: list?.id,
      // label: (
      //   <>
      //     <div>
      //       <h3>{list?.name}</h3>

      //       {list?.city ? <div>city: {list?.city}</div> : null}
      //       {list?.contact_person_name ? (
      //         <div>name: {list?.contact_person_name}</div>
      //       ) : null}
      //       {list?.contact_person_number ? (
      //         <div>contact: {list?.contact_person_number}</div>
      //       ) : null}
      //     </div>
      //   </>
      // ),
      // label: list?.name+"\n"+"("+list?.city+")"+"\n"+list?.contact_person_name+"\n"+list?.contact_person_number,
      label: `${list?.name} ${list?.city ? `(${list?.city})` : ''} ${list?.contact_person_name} ${list?.contact_person_number}`,
      // label: list?.name,
      text: list?.name,
    };
  });
};

function DropdownSelect({
  paramsData,
  paramsDataIndex,
  Controller,
  control,
  setValue,
  watch,
}) {
  const dispatch = useDispatch();
  const distributor = watch("distributor")
  
  const [distributorOptions, setDistributorOptions] = useState([]);
  const [retailerOptions, setRetailerOptions] = useState([]);
  

  const distributor_list = useSelector(getDistributor);

  const location = useLocation()

let searchParams = Object.fromEntries(
  new URLSearchParams(location.search)
)

  const fetch_distributor_list = async () => {
    const user_id = get_informationFromSessionID("userID");
    if (!user_id) return;
    const client_type =
      (user_id && get_userData_from_activeUser().client_type) || "1";
    const distributor_list_response = await fetch_data_from_api(
      `${ApiList.Finalize}/${ApiList.get_distributor}`,
      "get_distributor",
      {
        client_type,
        session_id: "",
      }
    );
    const distributor_list = createSelectArrayForReactSelect(
      distributor_list_response?.final_result,
      "name",
      "id"
    );
    dispatch(setDistributor(distributor_list_response?.final_result || []));
    setDistributorOptions(distributor_list);
    
  };
  
  const fetch_retailer_list = async (distributor_client_master_id) => {
    
    const user_id = get_informationFromSessionID("userID");
    if (!user_id) return;
    const client_type =
      (user_id && get_userData_from_activeUser().client_type) || "1";
    const retailer_list_response = await fetch_data_from_api(
      `${ApiList.Finalize}/${ApiList.get_retailer}`,
      "get_retailer",
      {
        client_type,
        session_id: "",
        distributor_client_master_id,
      }
    );

    const htmlOption = convertOBJ_to_html_react_options(
      retailer_list_response?.final_result || []
    );

    

    setRetailerOptions(htmlOption);
 
   
    
  };

  useEffect(() => {
    if (paramsData?.short_code === "distributor") {
      fetch_distributor_list();
    }
  }, [searchParams?.distributor_id]);

  // useEffect(() => {
  //   if (paramsData?.short_code === "retailer" && distributor_list.length > 0) {
  //     const distributor_client_master_id = distributor_list[0]?.id;
  //     fetch_retailer_list(distributor_client_master_id);
  //   }
  // }, [distributor_list]);

  
  useEffect(()=> {
    
    fetch_retailer_list(distributor?.value)
  }, [distributor?.value, searchParams?.distributor_id])

  return (
    <>
      <ReactSelect
              
              options={
                paramsData?.short_code === "distributor"
                  ? distributorOptions
                  : retailerOptions
              }
              setOptions={
                paramsData?.short_code === "distributor"
                  ? setDistributorOptions
                  : setRetailerOptions
              }
              
              paramsDataIndex={paramsDataIndex}
              paramsData={paramsData}
              Controller={Controller}
              control={control}
              setValue={setValue}
              searchParams={searchParams}
            />
        
      
    </>
  );
}

export default DropdownSelect;
