import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import WhatsappForLargeDevice from "./components/WhatsappForLargeDevice";
import WhatsappForSmallDevice from "./components/WhatsappForSmallDevice";
import { fetch_data_from_api, fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import {useSelector} from "react-redux"
import { useQuery } from "@tanstack/react-query";

const WhatsappIconNew = ({ showInMobile }) => {
  const [whatsAppNo, setWhatsAppNo] = useState("");
  const whatsAppMsg = useSelector(state => state?.reducer?.whatsAppMsg)
  
  const whatsApp_query = useQuery({
    queryKey: ["whatsApp"],
    queryFn: () => fetch_data_from_api_from_query(ApiList.Whatsapp, "whatsApp"),
  });

  

  

  useEffect(() => {
    if(Array.isArray(whatsApp_query?.data)){
      const data = whatsApp_query?.data[0]
      const whatsapp_mobile_number = `${data?.whatsapp_mobile_number_country_code}${data?.whatsapp_mobile_number}`
      setWhatsAppNo(whatsapp_mobile_number)
    }
  }, [whatsApp_query?.isSuccess]);

  

  return (
    <>
    <WhatsappForLargeDevice whatsAppNo={whatsAppNo} encodedMessage={whatsAppMsg}/>
    

    <WhatsappForSmallDevice whatsAppNo={whatsAppNo} encodedMessage={whatsAppMsg} showInMobile={showInMobile}/>
    
    </>
    
  );
};

export default WhatsappIconNew;
