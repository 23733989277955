import React, { useEffect } from "react";
import Select from "react-select";
import { get_property_settings } from "../../../../../Component/Helper";



function ReactSelect({ options, paramsDataIndex, Controller, control,paramsData, setValue, searchParams }) {
  const primary_BG_color = get_property_settings("btn_primary_color")
 
  
  useEffect(()=>{
    let selectedOption 
      
        if(paramsData?.short_code === "distributor" && searchParams?.distributor_id){
          selectedOption = options.find(option => option.value === searchParams?.distributor_id)
        }else if(paramsData?.short_code === "retailer"  && searchParams?.retailer_id){
          selectedOption = options.find(option => option.value === searchParams?.retailer_id)
        }else{
          selectedOption = options[0]
        }
      
       setValue(paramsData?.short_code, selectedOption)
      
  }, [options, searchParams?.distributor_id, searchParams?.retailer_id])
  
  return (
    <Controller
    control={control}
    name={paramsData?.short_code}
    rules={{
      required: {
        value: paramsData?.required === "1",
        message: "This field is required",
      },
    }}
    render={({ field }) => {
      return <Select
      tabIndex={paramsDataIndex + 1}
      options={options}
      defaultValue={options[0]}
      className="react-select-container r-container"
      classNamePrefix="react-select r-select"
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25:
            (primary_BG_color &&
              primary_BG_color.replace("rgb", "rgba").replace(")", ",0.2)")) ||
            "inherit",
          primary: primary_BG_color,
        },
      })}
     
 

     isSearchable={true}
     {...field}
     
     
    />
    }}
    />
    
  );
}

export default ReactSelect;
