import React, { useState } from 'react'
import { MdDelete } from "react-icons/md";

function CartRemoveAll({setShow}) {
  return (
    <section className='removeAllProductSection'>
        <div className='inner-section' >
            <button className='group-by-buttons group-by-buttons-selected remove-all-prod-btn'
                onClick={()=> setShow(true)}
            >
              <MdDelete className="del-icon"/>
                <span>Remove All</span>
            </button>
        </div>
    </section>
  
  )
}

export default CartRemoveAll
