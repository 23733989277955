import SelectSubproducts from "./components/SelectSubproducts";
import ProductNavTab from "./components/ProductNavTab";
import Qty_Remark from "./components/Qty_Remark/Qty_Remark";
import ButtonsControl from "./components/ButtonsControl/ButtonsControl";
import DropdownControl from "./components/DropdownControl/DropdownControl";
import { get_bulk_settings } from "../../../../Component/Helper";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { get_fsv_products, get_fsv_selected_product, set_FSV_Handler } from "../../../../redux/Reducer/reducer";
import { FSV_ACTIONS, get_default_selected, setValues} from "../../Helper"

function FSVInputs({nav1, nav2, master}) {
  let {
    fsv_checkbox_position,
    fsv_checkbox_visible,
    qty_remarks_position_in_fsv,
    catalogue_selection_based_on,
    show_quantity_stepper_in_fsv_dm,
    show_qty_remarks_inventory,
    show_subproduct_tab_fsv
  } =
    get_bulk_settings([
      "fsv_checkbox_position",
      "fsv_checkbox_visible",
      "qty_remarks_position_in_fsv",
      "catalogue_selection_based_on",
      "show_quantity_stepper_in_fsv_dm",
      "show_qty_remarks_inventory",
      "show_subproduct_tab_fsv"
    ]) || {};

  fsv_checkbox_visible = fsv_checkbox_visible === "Yes";
  show_quantity_stepper_in_fsv_dm = show_quantity_stepper_in_fsv_dm === "Yes";
  show_qty_remarks_inventory = show_qty_remarks_inventory === "Yes";

  const selectedProduct = useSelector(get_fsv_selected_product)
  const products_and_subproducts = useSelector(get_fsv_products)
 const dispatch = useDispatch()


  const {
    register,
    control,
    setValue,
    watch,
  } = useForm({
    
    mode:"onChange"
  })

  //console.log(watch())
  
  //Set Default Values
  useEffect(() => {
    if(products_and_subproducts?.length === 0) return
    
    products_and_subproducts.forEach(product => {
       let default_section = get_default_selected(product)
      
      dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_PRODUCT_CALC, payload: {data: default_section, product_name: selectedProduct?.category_name}}))
      if( Object?.keys(default_section).length > 0 && selectedProduct?.category_name === product?.category_name){
  
        const required_keys_array = Object?.keys(default_section)
        
       setValues(default_section, required_keys_array, setValue)
      }
    })
}, [products_and_subproducts])


  

  return (
    <div
      className={"single-pro-desc single-pro-desc-no-sidebar w-100"}
      style={{ width: "100% !important" }}
    >
      {fsv_checkbox_visible &&
      fsv_checkbox_position === "first" &&
      products_and_subproducts.length > 1 ? (
        <SelectSubproducts
          products={products_and_subproducts}
          
        />
      ) : null}

      {qty_remarks_position_in_fsv === "above_subproduct" && 
      ((master === "design_master" && show_quantity_stepper_in_fsv_dm) ||
      (master === "inventory_master" && show_qty_remarks_inventory))
      ? (
        <Qty_Remark clsName={"d-block"}/>
      ) : null}

      {fsv_checkbox_visible &&
      fsv_checkbox_position === "last" &&
      products_and_subproducts.length > 1 ? (
        <SelectSubproducts
          products={products_and_subproducts}
          
        />
      ) : null}

      {show_subproduct_tab_fsv === "Yes" && <ProductNavTab
        products={products_and_subproducts}
        changeProduct={()=>{}}
        nav1={nav1}
        nav2={nav2}
      />}

      <div className="single-pro-content">
        <div className="ec-pro-variation ">
          {selectedProduct &&
            selectedProduct?.selection_position?.length > 0 &&
            selectedProduct?.selection_position?.map((item, index) => {
              return (
                <div key={index}>
                  {selectedProduct[item.short_code] && (
                    <div
                      className="ec-pro-variation-inner ec-pro-variation-size "
                      key={index}
                    >
                      <div className="container">
                        <div className="row justify-content-start product-details-inner-group-sm">
                          {selectedProduct[item.short_code].length > 0 && (
                            <div className="col-lg-5 col-md-3 col-sm-5 align-self-center label d-flex product-details-label">
                              {item.custom_label}
                            </div>
                          )}

                          {catalogue_selection_based_on !== "dropdown" &&
                          
                            selectedProduct[item.short_code].length > 0 && (
                              <ButtonsControl
                                selectedProduct={selectedProduct}
                                item={item}
                                selected={[]}
                                register={register}
                                selectType={catalogue_selection_based_on}
                              />
                            )}

                          

                          {catalogue_selection_based_on === "dropdown" &&
                            selectedProduct[item.short_code].length > 0 && (
                              <DropdownControl
                                selectedProduct={selectedProduct}
                                item={item}
                                control={control}
                               
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          {qty_remarks_position_in_fsv === "inside_subproduct" &&
          ((master === "design_master" && show_quantity_stepper_in_fsv_dm) ||
          (master === "inventory_master" && show_qty_remarks_inventory))
          ? (
            <Qty_Remark/>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default FSVInputs;
