import { useEffect } from "react"
import { useReducer } from "react"
import { fetch_data_from_api } from "./APICalls"

export const ACTIONS = {
    API_REQUEST: 'api-request',
    FETCH_DATA: 'fetch-data',
    ERROR: 'error',
}

const initialState = {
    data: [],
    loading: false,
    error: null
}

function reducer (state, {type, payload}) {
    switch (type) {
        case ACTIONS.API_REQUEST : 
            return {...state, data: [], loading: true}
        case ACTIONS.FETCH_DATA : 
            return {...state, data: payload, loading: false}
        case ACTIONS.ERROR : 
            return {...state, loading: false, error: payload}
        default: return state
    }
}

function useFetch(api_endpoint, proxy_url_endpoint, params) {

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(()=>{
    fetch_data_from_api(api_endpoint, proxy_url_endpoint, params, dispatch)
  }, [api_endpoint])
  
  return state;
}

export default useFetch


