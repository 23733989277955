import { ApiList } from "../../../Api/ApiList";
import {
  createSearchURLParams,
  delete_informationFromSessionID,
  get_designStatus,
  get_informationFromSessionID,
  set_informationToSessionID,
} from "../../../Component/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  fetch_data_from_api,
  fetch_data_from_api_from_query,
} from "../../../Api/APICalls";
import RangeSliderSection from "./RangeSliderSection";
import ButtonsSection from "./ButtonsSection";
import { useSelector } from "react-redux";
import {
  getCatMaster,
  get_is_myc_web,
  get_myc_is_secured,
} from "../../../redux/Reducer/reducer";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import {
  applied_filtered_params,
  apply_filter_form_Params,
  get_default_filter_params_value_from_url,
  is_url_has_form_input_keys,
} from "./FilterHelper";
import Loader from "../../../Component/Loader";
import ShowOptionBtn from "./ShowOptionBtn";

function FilterSideBarOptions({
  showSideBar,
  setShowSideBar,
  clsName = "",
  isMatrixSilder = false,
  setHasFilterParams,
}) {
  let location = useLocation();
  let searchParams = Object.fromEntries(new URLSearchParams(location.search));

  const navigate = useNavigate();
  const userId = get_informationFromSessionID("userID");
  const company_code = get_informationFromSessionID("companyCode");
  const is_myc_web = useSelector(get_is_myc_web);
  const is_myc_secured = useSelector(get_myc_is_secured);
  const master = useSelector(getCatMaster);
  const design_status = get_designStatus(master);
  const filterParams = get_informationFromSessionID("filterParams");
  const DEFAULT_SHOW_OPTIONS = 4;
  const [defaultShowOption, setDefaultShowOption] = useState([
    0,
    DEFAULT_SHOW_OPTIONS,
  ]); //0: show all options

  const { data, isSuccess } = useQuery({
    queryKey: [searchParams?.cat_id, "fetchparams"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.fetchParams, "fetchparams", {
        user_id: userId || "",
        cat_id: searchParams.cat_id || "0",
        featured_id: searchParams?.featured_id || "",
        company_code,
        design_status,
        table: master,
        mode: is_myc_web ? "my_catalogue" : "",
      }).then((res) => {
        setHasFilterParams(res?.length > 0);
        return res;
      }),
  });

  const clearFilter = () => {
    reset();
    !isMatrixSilder && setShowSideBar(false);
    set_informationToSessionID({ table: master });
    delete_informationFromSessionID("filterParams");

    const clearSearchParams = {
      mode: is_myc_web
        ? "catalogue_filter"
        : searchParams?.mode === "featuredBased"
        ? "featuredBased_filter"
        : searchParams?.mode || "filter",
      cat_id: searchParams?.cat_id || "0",
      featured_id: searchParams?.featured_id || "",
    };

    navigate(
      createSearchURLParams("matrix-page", {
        ...clearSearchParams,
        sort: searchParams?.sort || "0",
        page: searchParams?.page || "0",
      })
    );
  };

  const applyFilter = (data) => {
    
    //Handle Click event error is 'data'
    if (data?.type && data?.type === "click") {
      data = getValues(); //set form values Here if Handle Click event
    }

    !isMatrixSilder && setShowSideBar(false);

    const mode_for_myc = is_myc_web && "catalogue_filter";
    const page = "0";
    const defaultParams = {
      cat_id: searchParams?.cat_id || "0",
      featured_id: searchParams?.featured_id || "",
      mode: is_myc_web
        ? "catalogue_filter"
        : searchParams?.mode === "featuredBased"
        ? "featuredBased_filter"
        : searchParams?.mode || "filter",
      sort: searchParams?.sort || "0",
    };

    const filter_form_Params = apply_filter_form_Params(data);

    set_informationToSessionID({
      filterParams: { ...defaultParams, ...filter_form_Params },
    });

    navigate(
      createSearchURLParams("matrix-page", {
        ...defaultParams,
        ...filter_form_Params,
        page,
      })
    );
  };

  useEffect(() => {
    //When FilterParam Appy and redirect from FSV to matrix

    if (
      (filterParams && location?.state?.includes("product-page")) ||
      is_url_has_form_input_keys(searchParams)
    ) {
      const filtered_params_value = applied_filtered_params(searchParams);
      
      
      if (filtered_params_value) {
        for (const key in filtered_params_value) {
          setValue(key, filtered_params_value[key]);
        }
      }
    } else {
      reset();
      delete_informationFromSessionID("filterParams");
    }
  }, [searchParams?.cat_id, showSideBar]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
    reset,
  } = useForm();

  if ((is_myc_web && is_myc_secured && !userId) || data?.length === 0) {
    return null;
  }

  const HeaderMenuHeight = Number(window.sessionStorage.getItem("HeaderMenu"));

  return (
    <form
      className={`${
        showSideBar
          ? "ec-shop-leftside filter-sidebar toggle-sidebar-swipe filter-sidebar-section"
          : "ec-shop-leftside filter-sidebar filter-sidebar-section"
      } ${clsName}`}
      onSubmit={handleSubmit(applyFilter)}
      noValidate
      style={{
        top: HeaderMenuHeight > 0 ? `${HeaderMenuHeight + 8}px` : "0px",
      }}
    >
      <div className="ec-sidebar-heading">
        <h1>Filter Products By</h1>
        {!isMatrixSilder && (
          <button
            className="filter-cls-btn"
            onClick={() => setShowSideBar(false)}
          >
            ×
          </button>
        )}
      </div>
      <div className="ec-sidebar-wrap">
        {isSuccess && typeof data === "object" ? (
          data[0]?.sort?.map((sortData, index) => {
            if (data[0]?.data[sortData?.field]?.length === 0) return null;
            return (
              <div
                key={index}
                className={
                  (data?.sort?.length !== index + 1) === true
                    ? "ec-sidebar-block "
                    : "ec-sidebar-block mb-10"
                }
              >
                <div className="ec-sb-title">
                  <h3 className="ec-sidebar-title">
                    {sortData?.custom_label ? sortData?.custom_label : ""}
                  </h3>
                </div>
                <div className="ec-sb-block-content ">
                  <ul className="d-flex justify-content-start flex-wrap">
                    {data[0]?.data[sortData?.field]
                      .slice(...defaultShowOption)
                      .map((filterData, i) => {
                        return (
                          <div
                            key={i}
                            className={
                              filterData.type === "range"
                                ? "range-wrapper"
                                : "buttons-wrapper"
                            }
                          >
                            {filterData.type === "range" ? (
                              <RangeSliderSection
                                dataField={sortData?.field}
                                data={filterData}
                                control={control}
                                register={register}
                                setValue={setValue}
                              />
                            ) : (
                              <ButtonsSection
                                data={filterData}
                                dataField={sortData?.field}
                                selected={{}}
                                register={register}
                              />
                            )}
                          </div>
                        );
                      })}

                    <ShowOptionBtn
                      setDefaultShowOption={setDefaultShowOption}
                      DEFAULT_SHOW_OPTIONS={DEFAULT_SHOW_OPTIONS}
                      OptionLength={data[0]?.data[sortData?.field]?.length}
                      defaultShowOption={defaultShowOption}
                    />
                  </ul>
                </div>
              </div>
            );
          })
        ) : (
          <Loader isLoading={true} loaderType="BeatLoader" />
        )}
      </div>
      {isSuccess && typeof data === "object" && (
        <div
          className="ec-sidebar-block fsv_bottom_toolbar"
          id="apply-btn-contaiiner"
        >
          <button
            className="btn btn-primary btn_secondary_foreground_color btn_secondary_border_color  "
            id="btn-filter-apply"
            onClick={(e) => {
              e.preventDefault();
              clearFilter();
            }}
            type="button"
          >
            clear
          </button>
          <button
            className="btn btn-primary btn_primary_color btn_primary_foreground_color nav_secondary_bar_button_border "
            id="btn-filter-apply"
            onClick={applyFilter}
            type="submit"
          >
            apply
          </button>
        </div>
      )}
    </form>
  );
}

export default React.memo(FilterSideBarOptions);
