// developemnt
const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === "development"
export const BASE_URL = IS_DEVELOPMENT_MODE ? "https://demo.jewelflow.pro/online_api/v3/" : "https://prodone.jewelflow.pro/online_api/v3/";
// export const PROXY_BASE_URL = "http://192.168.1.11:5000";

//production
// export const BASE_URL = "http://prodone.jewelflow.pro/";
// export const BASE_URL = "https://onlineapi.jewelflowdev.com/v3/";
export const PROXY_BASE_URL = IS_DEVELOPMENT_MODE ? "https://v2.jewelflow.pro" : "https://node.jewelflow.link";

// export const PROXY_BASE_URL =
//   "https://triologic-back-5mjtvjpuh-pavan-triologic.vercel.app";

// export const company_code = "TElWRURFTU8=";
// export const constants =
//   "?catalogue_id=31&user_id=1182&company_code=TElWRURFTU8=";

export const ApiList = {
  setting: "Setting",
  master_setting: "master_setting",
  homeScreen: "Homescreen",
  fetchCat: "FetchCat",
  fetchStaticpages: "FetchStaticpages",
  staticpage: "StaticPage",
  matrix: "Matrix",
  sortParams: "SortParams",
  productMatrixCount: "Product_Matrix_Count",
  fetchParams: "FetchParams",
  login: "Login",
  generateOtp: "generate_login_otp",
  cart: "Cart",
  cartCount: "CartCount",
  addToCartMatrix: "AddToCartMatrix",
  fullScreen: "Fullscreen",
  addToCart: "AddtoCart",
  fetchCartListGrouped: "FetchCartListGrouped",
  clearCart: "ClearCart",
  transferAllProducts: "TransferAllProducts",
  DeleteFromCart: "DeleteFromCart",
  TransferProduct: "TransferProduct",
  UpdateCartParams: "UpdateCartParams",
  Finalize: "Finalize",
  payment_failed: "Finalize/payment_failed",
  get_parameters: "get_parameters",
  UserRegistration: "UserRegistration",
  get_registration_form_params: "get_registration_form_params",
  get_loc_data: "get_loc_data",
  generate_verification_keys: "generate_verification_keys",
  forgot_password_code_gen: "forgot_password_code_gen",
  reset_password: "reset_password",
  Delete_From_Cart_Wishlist_Matrix: "Delete_From_Cart_Wishlist_Matrix",
  Currency: "Currency",
  Whatsapp: "Whatsapp",
  Search_keywords: "Search_keywords",
  get_distributor: "get_distributor",
  get_retailer: "get_retailer",
  add_retailer_get_parameters: "add_retailer_get_parameters",
  add_retailer_check_sendOTP: "add_retailer_check_sendOTP",
  add_retailer: "add_retailer",
  MycWeb: "MycWeb/get_link_master_details",
  Location:"Location",
};
