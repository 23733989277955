import React from 'react'

function SocialLink({social_link}) {
    const { short_code, url } = social_link || {};
  return (
    <li className="list-inline-item"><a href={url} target="_blank">
        {short_code === "social_instagram" ? (
          <i className="ecicon eci-instagram"></i>
        ) : short_code === "social_facebook" ? (
          <i className="ecicon eci-facebook"></i>
        ) :  short_code === "social_linkedin" ? (
          <i className="ecicon eci-linkedin"></i>
        ) : short_code === "social_twitter" ? (
          <i className="ecicon eci-twitter"></i>
        ) : short_code === "social_youtube" ? (
          <i className="ecicon eci-youtube"></i>
        ): short_code === "social_pinterest" ? (
          <i className="ecicon eci-pinterest"></i>
        ): short_code === "social_locate_us" ? (
          <i className="ecicon eci-map"></i>
        ): null}
         {/* <i className="ecicon eci-twitter"></i> */}
    </a>
    </li>
  )
}

export default SocialLink
