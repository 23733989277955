import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MdOutlineZoomIn, MdOutlineZoomOut } from "react-icons/md";
import { AiOutlineReload } from "react-icons/ai";
import React, { useState } from "react";
import Loader from "./Loader";
import { calculatedDOM_Height } from "./Helper";


const ZoomImageNew = ({ zoomImageUrl, largeImageUrl, noZoom, DRAWER_OPEN_DIRECTION, toggleZoomDrawer }) => {
  const [imgLoad, setImgLoad] = useState(true);

  const imgHeight = calculatedDOM_Height(75, 50);

  const style = {
    height: window.innerWidth > 768 ? imgHeight + "px" : "auto",
  };

  const headerHeight = Number(sessionStorage.getItem("headerHeight")) || 0;
  const FSVFooterHeight = Number(sessionStorage.getItem("FSVFooterHeight")) || 0;
  const MobileNavBarHeight = Number(sessionStorage.getItem("MobileNavBarHeight")) || 0;
  const EXTRA_OFFSET = 45
  const totalOffsetHeight = headerHeight + FSVFooterHeight + MobileNavBarHeight + EXTRA_OFFSET
  
  const no_zoom_img_style = window.innerWidth < 768 ? {
    width: '100%',
    height: `calc(100vh - ${totalOffsetHeight}px)`,
    objectFit: "contain"
  } : {};

 

  return (
    <section
      style={{ backgroundImage: `url(${zoomImageUrl})` }}
      className="zoom-img-outer-container"
    >
      {noZoom ? (
        <div className="static-img-container"
        onClick={() => {
          toggleZoomDrawer(DRAWER_OPEN_DIRECTION, true)
          sessionStorage.setItem("FSV_Swipe", JSON.stringify(false))
        }}
        >
        <img
          src={largeImageUrl}
          alt="test"
          className="main-zoom-img static"
          onLoad={() => {
            setImgLoad(false);
          }}
          style={no_zoom_img_style}
        />
        <button className="static-zoom-icon"
        
        >
          <MdOutlineZoomIn/>
        </button>
        </div>
      ) : (
        <TransformWrapper initialScale={1}>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <div className="tools d-none d-lg-flex">
                <button
                  onClick={() => zoomIn()}
                  className="zoom-in-btn"
                  title="Zoom in"
                >
                  <MdOutlineZoomIn />
                </button>
                <button
                  onClick={() => zoomOut()}
                  className="zoom-out-btn"
                  title="Zoom out"
                >
                  <MdOutlineZoomOut />
                </button>
                <button
                  onClick={() => resetTransform()}
                  className="zoom-reset-btn"
                  title="Reset zoom"
                >
                  <AiOutlineReload />
                </button>
              </div>
              <TransformComponent wrapperClass="react-zoom-pan-pinch">
                <Loader setImgLoad={imgLoad} />
                <img
                  src={zoomImageUrl}
                  alt="test"
                  className="main-zoom-img"
                  onLoad={() => {
                    setImgLoad(false);
                  }}
                  style={style}
                />
                {/* <div>Example text</div> */}
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      )}
    </section>
  );
};

export default React.memo(ZoomImageNew);
