import React, { useImperativeHandle, useState } from "react";
import Stepper from "./components/Stepper";
import Remarks from "./components/Remarks"
import { getCustomValue } from "../../../../../../Custom/CompanyList";

function Qty_Remark({clsName = ""}) {
  
  const hide_elements = getCustomValue("hide_elements")?.includes("Qty_Remark")
  
  if(hide_elements){
    return null
  }
  

   return (
    <div className={`ec-pro-variation-inner ec-pro-variation-size ${clsName}`}>
      {true && (
        <Stepper/>
      )}
      <Remarks/>
    </div>
  );
}

export default Qty_Remark;
