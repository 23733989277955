import Slider from "rc-slider";
import React, { useState, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom"
import Loader from "../../../Component/Loader";


function RangeSliderSection({
  dataField,
  data,
  control,
  register,
  setValue
}) {


 
  let location = useLocation()
  let searchParams = Object.fromEntries(
    new URLSearchParams(location.search)
  )

  

  //Change datafield carat (base on mat_calc_type) if there is Diamond else set DataField as is
  const DataField = dataField === "Diamond" ? data?.mat_calc_type.toLowerCase() : dataField

  
   
  

  const rangeValue = useWatch({ control, name: DataField })
  


  let step = 0;
  let labelText = ""

  if (dataField === "gross_wt" || dataField === "net_wt") {
    step = 0.01
    labelText = "gm"
  } else if (dataField === "Diamond" && data?.mat_calc_type === "Carat") {
    step = 0.01
    labelText = "cts"
  } else if (dataField.includes("_price")) {
    step = 1
    labelText = "₹"
  } else {
    step = 1;
    labelText = ""
  }

  

  if (!data) {
    return <Loader isLoading={true}/>
  }

  
  return (
    <div className="ec-sb-block-content es-price-slider">
      <div className="ec-price-filter">
        <Slider
          range
          min={parseFloat(data[`min_${DataField}`])}
          max={parseFloat(data[`max_${DataField}`])}
          step={parseFloat(step)}
          defaultValue={[
            parseFloat(data[`min_${DataField}`]),
            parseFloat(data[`max_${DataField}`]),
          ]}
          value={[
            rangeValue ? rangeValue.minmax[0] || parseFloat(data[`min_${DataField}`]) : parseFloat(data[`min_${DataField}`]),
            rangeValue ? rangeValue.minmax[1] || parseFloat(data[`max_${DataField}`]) :  parseFloat(data[`max_${DataField}`]),
          ]}
          onChange={(values) => {
            setValue(`${DataField}.minmax.0`, values[0])
            setValue(`${DataField}.minmax.1`, values[1])
          }}
        />
        <div className="ec-price-input">
          <label className="filter__label">
          {labelText === "₹" && <span>{labelText}</span>}
            <input
              type="number"
              className="filter__input"
              defaultValue={data[`min_${DataField}`]}
              {...register(`${DataField}.minmax.0`, {valueAsNumber: true, min: data[`min_${DataField}`], max: data[`max_${DataField}`]})}
              
              />
              {labelText !== "₹" && <span>{labelText}</span>}
          </label>
          <span className="ec-price-divider"></span>
          <label className="filter__label">
            {labelText === "₹" && <span>{labelText}</span>}
            <input
              type="number"
              className="filter__input"
              defaultValue={data[`max_${DataField}`]}
              {...register(`${DataField}.minmax.1`, {valueAsNumber: true, min: data[`min_${DataField}`], max: data[`max_${DataField}`]})}
              />
              {labelText !== "₹" && <span>{labelText}</span>}
          </label>
          
        </div>
      </div>
    </div>
  );
}

export default RangeSliderSection;
