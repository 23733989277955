import React, {  useMemo, memo } from "react";
import Modal from "react-bootstrap/Modal";
import CategoryListGroup from "./CategoryListGroup";
import { useSelector } from "react-redux";
import { getCatMaster,  get_is_myc_web } from "../../redux/Reducer/reducer";
import { useLocation, useNavigate } from "react-router-dom";
import "./RecursiveCategoryModal.css";
import {
  createSearchURLParams,
  get_informationFromSessionID,
  get_property_settings,
} from "../Helper";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import ButtonImageGroup from "./ButtonImage/ButtonImageGroup";
import Loader from "../Loader";

function RecursiveCategoryModal({ showCategoryModal, setShowCategoryModal }) {
  
  const show_hide_category_counts =
    get_property_settings("show_hide_category_counts") === "show";
  // const [subCategories, setSubCategory] = useState(null);
  
  
  const location = useLocation();
  const navigate = useNavigate();
  const master = useSelector(getCatMaster);
  const userID = get_informationFromSessionID("userID");
  const is_myc_web = useSelector(get_is_myc_web);

  const category_query = useQuery({
    queryKey: ["category", master, userID],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.fetchCat, "FetchCat", {
        which_master: master,
      }),
    initialData: [],
    enabled: !is_myc_web
  });

  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const cat_id = searchParams.cat_id;

  const findID = (categoryData, cat_id, nestingKey) => {
    if(!Array.isArray(categoryData)) return null;
    return categoryData?.reduce((result, currentItem) => {
      if (result) return result;
      if (currentItem.id === cat_id) return currentItem;
      if (currentItem[nestingKey])
        return findID(currentItem[nestingKey], cat_id, nestingKey);
    }, null);
  }
    

  
  
  // useEffect(() => {
  //   if (!cat_id) return;
  //     const get_recurssive_data = findID(category_query?.data, cat_id, "subcategory")
  //     setSubCategory(get_recurssive_data);
      
  //   }, [cat_id]);

  const subCategories = useMemo(() => findID(category_query?.data, cat_id, "subcategory"), [cat_id])

  
  return (
    <Modal
      show={showCategoryModal}
      onHide={() => { 
        navigate("/home")
        setShowCategoryModal(false);
      }
    }
      className="categoryModal"
    >
      {!subCategories || category_query?.isLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg d-flex align-items-center">
              {subCategories?.cat_parent_id !== "0" && (
                <IoMdArrowRoundBack
                  className="backbtn"
                  onClick={() => {
                    navigate(
                      createSearchURLParams("home", {
                        cat_id: subCategories?.cat_parent_id,
                      })
                    );
                  }}
                />
              )}
              <span className="modal-title">{subCategories?.cat_name}</span>

              {show_hide_category_counts &&
                subCategories?.product_count !== null && (
                  <span className="modal-count modal-title">
                    {subCategories?.product_count}
                  </span>
                )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {subCategories?.subcategory_view_style === "tableview" ? (
              <CategoryListGroup
                subCategories={subCategories.subcategory}
                setShowCategoryModal={setShowCategoryModal}
              />
            ) : (
              
              <ButtonImageGroup
                subCategories={subCategories.subcategory}
                setShowCategoryModal={setShowCategoryModal}
                layoutStyle={subCategories?.display_style_for_imagebutton}
              />
            )}
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}

export default memo(RecursiveCategoryModal);
