import React from 'react'

const style = {
  width: '100%',
}


function Banner({ img }) {
  if (Array.isArray(img)) {
    
   return (<div>
      {
        <picture>
        <source media="(min-width:576px)" srcSet={img[0]} />
        <img src={img[1]} alt="" style={style} />
      </picture>
      }
    </div>)
  } else {
    return (
      <div>
        <img src={img} alt="" style={style} />
      </div>
    )
  }

}

export default Banner
