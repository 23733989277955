import React from "react";
import { get_bulk_settings,  formatPriceWithRupee } from "../../../../../Component/Helper";

function MaterialDetails({data}) {

  const {fsv_material_details_title, labour_amount_column_show_rupee_symbol} = get_bulk_settings(["fsv_material_details_title","labour_amount_column_show_rupee_symbol"]) || {};

  return (
    <div className="details-block">
      {data && data?.total?.length > 0 && (
        <>
          <div className="heading">
            <h5 className="ec-single-title">
              {fsv_material_details_title}
            </h5>
          </div>
          <table className="w-100">
            <thead>
              <tr className="row product-other-details-bottom-group">
                {data &&
                  data.material_position.length > 0 &&
                  data.material_position.map((title, i) => {
                    return (
                      data.total_material_visible_setting[title.short_code] ===
                        "1" && (
                        <td
                          key={title.short_code}
                          className={`col label product-details-label ${
                            i === 0 ? "text-left" : "product-details-label"
                          }  ${
                            i === data.material_position.length - 1
                              ? "text-right"
                              : ""
                          }`}
                        >
                          {title.custom_label}
                        </td>
                      )
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {data.total.map((itemm, i) => {
                return (
                  <tr
                    className="row product-details-value product-other-details-bottom-group"
                    key={i}
                  >
                    {data.material_position.map((item, ci) => {
                      return (
                        <React.Fragment key={ci}>
                          {item.short_code === "material" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <td
                                className={`col product-details-value ${
                                  ci === 0 ? "text-left" : ""
                                }  ${
                                  ci === data.material_position.length - 1
                                    ? "text-right"
                                    : ""
                                }`}
                              >
                                {itemm?.name ? itemm?.name : "-"}{" "}
                              </td>
                            )}
                          {item.short_code === "cts" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <td
                                className={`col ${
                                  ci === 0 ? "text-left" : ""
                                }  ${
                                  ci === data.material_position.length - 1
                                    ? "text-right"
                                    : ""
                                }`}
                              >
                                {itemm?.cts ? itemm?.cts : "-"}{" "}
                              </td>
                            )}
                          {item.short_code === "weight" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <td
                                className={`col ${
                                  ci === 0 ? "text-left" : ""
                                }  ${
                                  ci === data.material_position.length - 1
                                    ? "text-right"
                                    : ""
                                }`}
                              >
                                {itemm?.weight ? itemm?.weight : "-"}{" "}
                              </td>
                            )}
                          {item.short_code === "pieces" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <td
                                className={`col ${
                                  ci === 0 ? "text-left" : ""
                                }  ${
                                  ci === data.material_position.length - 1
                                    ? "text-right"
                                    : ""
                                }`}
                              >
                                {itemm?.pieces ? itemm?.pieces : "-"}{" "}
                              </td>
                            )}
                          {item.short_code === "other_charges" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <>
                                {" "}
                                {labour_amount_column_show_rupee_symbol ===
                                "Yes" ? (
                                  <td
                                    className={`col ${
                                      ci === 0 ? "text-left" : ""
                                    }  ${
                                      ci === data.material_position.length - 1
                                        ? "text-right"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    {formatPriceWithRupee(itemm?.other_charges)}
                                  </td>
                                ) : (
                                  <td
                                    className={`col ${
                                      ci === 0 ? "text-left" : ""
                                    }  ${
                                      ci === data.material_position.length - 1
                                        ? "text-right"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    {itemm?.other_charges}
                                  </td>
                                )}
                              </>
                            )}
                          {item.short_code === "total" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <>
                                {" "}
                                {labour_amount_column_show_rupee_symbol ===
                                "Yes" ? (
                                  <td
                                    className={`col ${
                                      ci === 0 ? "text-left" : ""
                                    }  ${
                                      ci === data.material_position.length - 1
                                        ? "text-right"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    {formatPriceWithRupee(itemm?.total)}
                                  </td>
                                ) : (
                                  <td
                                    className={`col ${
                                      ci === 0 ? "text-left" : ""
                                    }  ${
                                      ci === data.material_position.length - 1
                                        ? "text-right"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    {itemm?.total}
                                  </td>
                                )}
                              </>
                            )}
                        </React.Fragment>
                      );
                    })}
                  </tr>
                );
              })}

              {data &&
                data.material_position.length > 0 &&
                data.material_position.map((title, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {/* <ul>
                          {data[title.short_code] &&
                            data[title.short_code].length > 0 &&
                            data[title.short_code].map((item) => {
                              return (
                                <li key={item.id}>
                                  {item.mat_name} - Cts: {item.cts}, Weight:{" "}
                                  {item.weight}
                                </li>
                              );
                            })}
                        </ul> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default React.memo(MaterialDetails);
