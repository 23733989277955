import React from "react";
import { formatPriceWithRupee, get_property_settings  } from "../../../../../Component/Helper";

function TotalCost({item, calc_total_after_tax_amt, data, index}) {
  const labour_amount_column_show_rupee_symbol = get_property_settings("labour_amount_column_show_rupee_symbol")

  return (
    <div className="total product-details-bottom-group">
      <div className="d-flex justify-content-between total-cost">
        <div>
          <span>{item}</span>{" "}
        </div>
        <div className="ml-auto total-amt">
          {labour_amount_column_show_rupee_symbol === "Yes" ? (
            <span>
              {" "}
              {formatPriceWithRupee(
                calc_total_after_tax_amt || data?.values[index]
              )}
            </span>
          ) : (
            <span> {calc_total_after_tax_amt || data?.values[index]}</span>
          )}
        </div>
        <br />
      </div>
    </div>
  );
}

export default React.memo(TotalCost);
