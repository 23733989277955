import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get_martix_view_layout, set_matrixViewLayout } from '../../../../../redux/Reducer/reducer'

function ViewLayout({type, image}) {
  
    const selectedLayout = useSelector(get_martix_view_layout)
    const selectView = selectedLayout === type ? "selected" : ""
    const dispatch = useDispatch()
  return (
    <div className={`view-layout-image-container ${selectView}`}
    
    >
        <img src={image} alt="singleLayout" onClick={() => {dispatch(set_matrixViewLayout(type))}}/>
    </div>
  )
}

export default ViewLayout
