import React, { useCallback, useEffect, useRef, useState } from 'react'
import HeaderUserMenu from './HeaderUserMenu'
import {Link} from "react-router-dom"
import { closeMenuWhenClickedOutside, get_informationFromSessionID, get_property_settings, get_userData_from_activeUser, logOutUser } from '../../Helper'
import ModalDialog from '../../ModalDialog'
import BrowseByCategory from './BrowseByCategory'
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux"
import { get_is_myc_web } from '../../../redux/Reducer/reducer'
import SocialLink from './components/SocialLink'

function HeaderTop({setOpenMobileMenu, clsName, logoImg}) {
    const [showCurrency, setShowCurrency] = useState(false)
    const social_link = get_property_settings("social_link") || []
  const is_myc_web = useSelector(get_is_myc_web)
  

  const [showModal, setShowModal] = useState(false)
  const location = useLocation()
    const userID = get_informationFromSessionID("userID")
   const userName = userID && get_userData_from_activeUser().full_name;
   
   const showBrowseByCategory = userID &&  get_userData_from_activeUser().show_design === "Yes" && get_userData_from_activeUser().show_inventory === "Yes"
   
   const show_social_link = get_property_settings("show_social_link") === "Yes"
   
   const handleMouseClick = useCallback((e)=>{
        closeMenuWhenClickedOutside(e, showCurrency, setShowCurrency, ['dropdown-toggle', 'dropdown-item', 'eci-caret-down'])
   }, [showCurrency])
    
    useEffect(()=>{
       
        window.addEventListener('click', handleMouseClick)
        return () => {
            window.removeEventListener('click',  handleMouseClick)
        }
    }, [showCurrency])

    


    return (
        <>
        <ModalDialog show={showModal} setShow={setShowModal} action={logOutUser} toastMsg={"Your Successfully logout"} />
        <div className={`header-top ${clsName} d-none d-lg-block`}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    {/* -- Header Top social Start -- */}
                    {show_social_link && <div className="col-4 text-left header-top-left d-none d-lg-block">
                        
                        <div className="header-top-social">
                            <span className="social-text text-upper">Follow us on:</span>
                            <ul className="mb-0">
                                {
                                    social_link.map(link => <SocialLink social_link={link} id={link?.short_code}/>)
                                }
                                
                            </ul>
                        </div>
                    </div>}
                    {/* -- Header Top social End -- */}
                    

                    {/* -- Header Top Language Currency -- */}
                    <div className="col-7 header-top-right d-none d-lg-flex justify-content-end ml-auto">
                        <div className="header-top-lan-curr d-flex justify-content-end align-items-center">
                            {showBrowseByCategory && !is_myc_web && <BrowseByCategory/>}
                            {!userID && <div className="header-top-login-reg">
                                <Link to="/registration">Register</Link>
                                <Link to="/login">Login</Link>
                            </div>}

                            {/* -- Currency Start -- */}
                            {userID && <div className="header-top-curr dropdown">
                                <button className={`dropdown-toggle text-upper ${showCurrency ? "show" : ""}`} data-bs-toggle="dropdown"
                                onClick={()=>setShowCurrency(pre => !pre)}
                                >Welcome, {userName}
                                <i className="ecicon eci-caret-down" aria-hidden="true"></i>
                                </button>
                                <ul className={`dropdown-menu ${showCurrency ? "show" : ""}`}>
                                    <li className="active"
                                    onClick={
                                        ()=>{setShowCurrency(false)
                                            setShowModal(true)
                                        }
                                     }

                                    ><a className="dropdown-item" href="#">Logout</a></li>
                                    {/* <li><a className="dropdown-item" href="#" onClick={()=>setShowCurrency(false)}>EUR €</a></li> */}
                                </ul>
                            </div>}
                            {/* -- Currency End -- */}

                            {/* -- Language Start -- */}
                            {/* <div className="header-top-lan dropdown">
                                <button className="dropdown-toggle text-upper" data-bs-toggle="dropdown">Language <i
                                        className="ecicon eci-caret-down" aria-hidden="true"></i></button>
                                <ul className="dropdown-menu">
                                    <li className="active"><a className="dropdown-item" href="#">English</a></li>
                                    <li><a className="dropdown-item" href="#">Italiano</a></li>
                                </ul>
                            </div> */}
                            {/* -- Language End -- */}

                        </div>
                    </div>
                    {/* -- Header Top Language Currency -- */}


                    {/* -- Header Top responsive Action -- */}
                    <div className="col d-lg-none ">
                        <div className="ec-header-bottons">

                            {/* -- Header User Start -- */}
                            <HeaderUserMenu/>
                            {/* -- Header User End -- */}

                            {/* -- Header menu Start -- */}
                            <a href="" className="ec-header-btn ec-side-toggle d-lg-none"
                            onClick={
                                (e)=>{
                                    e.preventDefault();
                                    setOpenMobileMenu(true)
                                }
                            }
                            >
                                <i className="fi fi-rr-menu-burger"></i>
                            </a>
                            {/* -- Header menu End -- */}
                        </div>
                    </div>
                    {/* -- Header Top responsive Action -- */}
                </div>
            </div>
        </div>
        </>
    )
}

export default React.memo(HeaderTop)
