import React, { useEffect, useState } from "react";
import { get_short_code_name } from "../../../../Helper"
import { useWatch } from "react-hook-form";
import Button from "./components/Button";
import ButtonFlow from "./components/ButtonFlow";
function ButtonsControl({
  selectedProduct,
  item,
  register,
  selectType,
}) {
  let short_code = get_short_code_name(item.short_code);

  

  return (
    <>
      {selectType === "button" ? ( //button
      
        <Button
          short_code={short_code}
          selectedProduct={selectedProduct}
          item={item}
          register={register}
          
        />
      ) : (
        <ButtonFlow
          short_code={short_code}
          selectedProduct={selectedProduct}
          item={item}
          register={register}
          
        />
      )}
    </>
  );
}

export default ButtonsControl;
