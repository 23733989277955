import React, { useEffect, useRef } from 'react'

function WhatsappForLargeDevice({whatsAppNo, encodedMessage}) {
    const whatsAppFloatRef = useRef(null)
    
    const rightOffset = whatsAppFloatRef?.current?.offsetWidth ? `-${whatsAppFloatRef?.current?.offsetWidth - 36}px` : '-75px'
    
  return (
    <div className="ec-style ec-right-bottom d-none d-sm-block" ref={whatsAppFloatRef} style={{right: rightOffset}}>
      <div className="ec-right-bottom">
        <div className="ec-box d-flex align-items-center btn_primary_color btn_primary_foreground_color button_border">
          <div className="ec-button ">
            <i
              className="fa-brands fa-whatsapp filter-color "
              style={{ fontSize: "25px" }}
            ></i>
            
          </div>
          <a
            className="hovered"
            target="_blank"
            href={`https://api.whatsapp.com/send?phone=${whatsAppNo}&text=${
              encodedMessage
            }`}
          >
            <span>WHATSAPP</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default WhatsappForLargeDevice
