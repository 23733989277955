import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import HomeBanner from "../../Component/HomeComponents/HomeBanner/HomeBanner";
import USPSection from "../../Component/HomeComponents/USPSection/USPSection";
import {
  ToastSuccess,
  delete_informationFromSessionID,
  get_informationFromSessionID,
  get_property_settings,
} from "../../Component/Helper";
import { ApiList } from "../../Api/ApiList";
import Testmonial from "../../Component/HomeComponents/Testmonial/Testmonial";

import ProductBasedSection from "../../Component/HomeComponents/ProductBasedSection/ProductBasedSection";
import BrandingBasedSection from "../../Component/HomeComponents/BrandingBasedSection/BrandingBasedSection";

import { useSelector } from "react-redux";
import { get_is_myc_web, getCatMaster } from "../../redux/Reducer/reducer";
import RecursiveCategoryModal from "../../Component/RecursiveCategory/RecursiveCategoryModal";
import CategoriesSectionNew from "../../Component/RecursiveCategory/CategoriesSectionNew";
import Loader from "../../Component/Loader";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import { useQuery } from "@tanstack/react-query";
import StoreSection from "../../Component/HomeComponents/StoreSection/StoreSection";
import {useLocation} from "react-router-dom";

function Home() {
  const master = useSelector(getCatMaster);
  const userID = get_informationFromSessionID("userID");
  const location = useLocation()
  let searchParams = Object.fromEntries(new URLSearchParams(location.search));

  const is_myc_web = useSelector(get_is_myc_web);
  
  const category_query = useQuery({
    queryKey: ["category", master, userID],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.fetchCat, "FetchCat", {
        which_master: master,
      }),
      placeholderData: (previousData, previousQuery) => previousData,
    initialData: [],
    enabled: !is_myc_web
  });

  const homescreen_query = useQuery({
    queryKey: ["homescreen"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.homeScreen, "homescreen", {
        device: "web",
      }),
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: Infinity,
  });

  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const parent_category_from = get_property_settings("parent_category_from");
  // const parent_category_from = "static"
  const parent_category_text = get_property_settings("parent_category_text");
  const web_show_store_locator_homescreen = get_property_settings("web_show_store_locator_homescreen");
  
  const handleShowCategoryModal = useCallback((bool) => {
    setShowCategoryModal(bool);
  }, []);

  

  useEffect(() => {
    const isUserLoggedOut = get_informationFromSessionID("isUserLoggedOut");

    if (isUserLoggedOut) {
      ToastSuccess("Your logout successfully");
      delete_informationFromSessionID("isUserLoggedOut");
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(showCategoryModal || !searchParams?.cat_id) return 
    if(category_query?.data?.length > 0) {

      setShowCategoryModal(true)
    }

  }, [searchParams?.cat_id, category_query?.data])

  if (category_query?.isLoading || homescreen_query?.isLoading) {
    return <Loader isLoading={true} />;
  }

  

  return (
    <section>
      {showCategoryModal && (
        <RecursiveCategoryModal
          showCategoryModal={showCategoryModal}
          setShowCategoryModal={setShowCategoryModal}
        />
      )}
      
      {homescreen_query?.data?.branding ? (
        <HomeBanner brandingData={homescreen_query?.data?.branding} />
      ) : null}

      {/* parent_category_from === "static") */}
      {Array.isArray(category_query?.data) &&
        category_query?.data?.length > 0 &&
        parent_category_from === "static" ? (
          <CategoriesSectionNew
            title={parent_category_text}
            subtitle={""}
            btnName={""}
            clsName={"categories-slick"}
            categoriesData={category_query?.data}
            setShowCategoryModal={handleShowCategoryModal}
          />
        ) :  parent_category_from === "static" ? <Loader isLoading={category_query?.data?.length !== 0}/> : null}

      {/* parent_category_from === "fetchcat") */}

      {Array.isArray(category_query?.data) &&
        category_query?.data?.length > 0 &&
        parent_category_from === "fetchcat" ?
        category_query?.data?.map((category) => (
          <CategoriesSectionNew
            key={category?.id}
            title={category?.cat_name}
            subtitle={""}
            btnName={""}
            clsName={"categories-slick"}
            categoriesData={category.subcategory}
            setShowCategoryModal={handleShowCategoryModal}
          />
        )) :  parent_category_from === "fetchcat" ? <Loader isLoading={category_query?.data?.length !== 0}/> : null}

      {homescreen_query?.data?.featured_list?.length
        ? homescreen_query?.data?.featured_list?.map((data) => {
            if (data?.featured_display_type === "product_based") {
              return (
                <ProductBasedSection
                  title={data?.name}
                  subtitle={null}
                  btnName={data.show_view_all === "Yes" ? "View All" : null}
                  clsName={"trending-slick"}
                  branding_img={data.branding_img}
                  key={data?.id}
                  data={data?.dataArray}
                  product_count={data?.product_count}
                  cat_id={data?.id}
                  web_container_bg_color={
                    data.web_container_bg_color || "transparent"
                  }
                  web_container_fg_color={
                    data.web_container_fg_color || "var(--highlight_color)"
                  }
                />
              );
            } else if (data.featured_display_type === "branding_based") {
              return (
                <BrandingBasedSection
                  title={data?.name}
                  btnName={data?.show_view_all === "Yes" ? "View All" : null}
                  imgName={data?.branding_img ? data.branding_img : null}
                  key={data?.id}
                />
              );
            }
          })
        : null}

      {homescreen_query?.data?.usp && homescreen_query?.data?.usp?.length > 0 ? (
        <USPSection uspData={homescreen_query?.data?.usp} />
      ) : null}

      {web_show_store_locator_homescreen === "Yes" && <StoreSection />}

      {homescreen_query?.data?.testimonial?.length ? (
        <Testmonial testmonialData={homescreen_query?.data?.testimonial} />
      ) : null}
    </section>
  );
}

export default React.memo(Home);
