import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {get_inCartWishList, get_is_myc_web, get_myc_is_secured, setConstants, set_inCartWishList } from "./redux/Reducer/reducer";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "./Component/Loader";
import { ApiList} from "./Api/ApiList";
import { delete_informationFromSessionID,  get_informationFromSessionID, get_property_settings, get_userData_from_activeUser } from "./Component/Helper";
import { fetch_data_from_api, fetch_data_from_api_from_query} from "./Api/APICalls";
import { useQuery } from "@tanstack/react-query";



const ProtectedRoutes = ({ children }) => {
 
  
  const location = useLocation()
  const is_myc_web = useSelector(get_is_myc_web);
  const is_myc_secure = useSelector(get_myc_is_secured);
  const inCartWishList = useSelector(get_inCartWishList)
  const userID = get_informationFromSessionID("userID") || "";
  const company_code = get_informationFromSessionID("companyCode");
  const user_details = userID && get_userData_from_activeUser() || {}
  let login_on_startup_online = get_property_settings("login_on_startup_online")
  login_on_startup_online = login_on_startup_online === "Yes" && !userID
  
  const dispatch = useDispatch();

  async function fetch_cart_count(){
    
    const cart_count_Response = await fetch_data_from_api(`${ApiList.cart}/${ApiList.cartCount}`, 'cart-count', {company_code, user_id: userID, table: "temp_cart"})
    dispatch(
      setConstants({
        companyCode: company_code,
        userId: userID,
        count: cart_count_Response,
      })
    );

    
}

useQuery({
  queryKey: ["login"],
  queryFn: () =>
  fetch_data_from_api_from_query(
    ApiList?.login,
    "login",
    {
      login_from: "web",
      password: atob(user_details?.password || ""),
      mobile_no: user_details?.mobile_no,
      long: 0,
      lat: 0,
      device_token: "",
      device_model: "",
      device_ip: "",
      device_id: "",
    }
  ).then(res => {
    if(res.ack === "1"){

      dispatch(set_inCartWishList({
        in_cart_id_dm: res.in_cart_id_dm,
        in_cart_id_im: res.in_cart_id_im,
        in_wishlist_id_dm: res.in_wishlist_id_dm,
        in_wishlist_id_im: res.in_wishlist_id_im,
      }))
    }else{
      dispatch(set_inCartWishList({
        in_cart_id_dm: [],
        in_cart_id_im: [],
        in_wishlist_id_dm: [],
        in_wishlist_id_im: [],
      }))
    }
    return res
  }),
  enabled: !!(userID && !inCartWishList),
});



useEffect(() => {
  
  fetch_cart_count()
  
}, []);
  
  //Clear filter params in all routes expect (matrix and FSV)
  if(location.pathname !== "/matrix-page" && !location.pathname.includes('/product-page')){
    delete_informationFromSessionID("filterParams")
  }


  if(!is_myc_web && login_on_startup_online){
    return <Navigate to="/login"/>
  }
  
  
    //Code for MYC 
  if(is_myc_web && location.pathname === "/home"){
        if(!is_myc_secure || is_myc_secure || userID){
            return <Navigate to="/matrix-page"/>
        }else{
          return <Navigate to="/login"/>
        }
  }

  else if(is_myc_web && (location.pathname === "/cart" || location.pathname === "/short-list" || location.pathname === "/checkout") && !userID){
        return <Navigate to="/login"/>
  }

  else if(is_myc_secure && (location.pathname.includes("/product-page")) && !userID){
      return <Navigate to="/login"/>
  }

    
   
    return (
      <>
      {children}
      </>
    );
  
};

export default ProtectedRoutes;
