import { useState } from "react";
import { RiLayoutMasonryFill } from "react-icons/ri";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import singleLayout from "../../../../../assets/images/single-layout.jpg";
import gridLayout from "../../../../../assets/images/grid-layout.jpg";
import ViewLayout from "./ViewLayout";


function ViewStyle() {
  const OPEN_DIRECTION = "bottom";
  
  const Layout = [
    {
      id: 1,
      type: "single-layout",
      image: singleLayout,
    },
    {
      id: 2,
      type: "grid-layout",
      image: gridLayout,
    },
  ];

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const view = (OPEN_DIRECTION) => (
    <Box
      sx={{
        width:
          OPEN_DIRECTION === "top" || OPEN_DIRECTION === "bottom"
            ? "auto"
            : 250,
      }}
      role="presentation"
      onClick={toggleDrawer(OPEN_DIRECTION, false)}
      onKeyDown={toggleDrawer(OPEN_DIRECTION, false)}
    >
      <section className="view-layout-modal">
        <div className="view-layout-header">
          <span className="text">View Style</span>
          <span
            className="close-btn"
            onClick={() => toggleDrawer(OPEN_DIRECTION, false)}
          >
            &times;
          </span>
        </div>
        <div className="view-layout-body">
          {
            Layout.map(view => <ViewLayout key={view.id} {...view}/>)
          }
        </div>
      </section>
    </Box>
  );

  return (
    <section className="view-style-option-btn d-sm-none d-flex">
      <div onClick={toggleDrawer(OPEN_DIRECTION, true)}>
        <RiLayoutMasonryFill className="view-style-icon" />
        <span className="view-style-text">View</span>
      </div>
      <SwipeableDrawer
        anchor={OPEN_DIRECTION}
        open={state[OPEN_DIRECTION]}
        onClose={toggleDrawer(OPEN_DIRECTION, false)}
        onOpen={toggleDrawer(OPEN_DIRECTION, true)}
      >
        {view(OPEN_DIRECTION)}
      </SwipeableDrawer>
    </section>
  );
}

export default ViewStyle;
