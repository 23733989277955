import React, { useEffect, useId, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { get_bulk_settings } from "../../../Component/Helper";
import { getCatMaster } from "../../../redux/Reducer/reducer";

function ButtonsSection({ data, dataField, register }) {
  const filter_size = get_bulk_settings([
    "filter_size_multiselect",
    "filter_size_multiselect_im",
  ]);
  const master = useSelector(getCatMaster);

  const filter_size_multiselect =
    master === "inventory_master"
      ? filter_size?.filter_size_multiselect_im === "Yes"
      : filter_size?.filter_size_multiselect === "Yes"

      // ? filter_size?.filter_size_multiselect_im === "Yes" && dataField === "size"
      // : filter_size?.filter_size_multiselect === "Yes" && dataField === "size";
  
  
  const id = useId();
  
  return (
    <div className="btn-container">
      <input
        type={filter_size_multiselect ? "checkbox" : "radio"}
        id={data[`${dataField}_value`] + id}
        defaultValue={data?.id}
        defaultChecked={data?.selected === "1"}
        {...register(dataField)}
        className="filter-btn-input"
        style={{ display: "block" }}
      />
      <label
        htmlFor={data[`${dataField}_value`] + id}
        className="filter-btn-label"
        
      >
        {data[`${dataField}_value`]}
        
      </label>
    </div>
  );
}

export default React.memo(ButtonsSection);
