import React from "react";


import { formatPriceWithRupee,
  get_bulk_settings,
  get_informationFromSessionID,
  get_property_settings, } from "../../../../../Component/Helper";

function ProductDetails({
  selectedProduct,
  selected_product_calc
  
}) {
  
  const { fsv_product_details_title, labour_amount_column_show_rupee_symbol } =
    get_bulk_settings([
      "fsv_product_details_title",
      "labour_amount_column_show_rupee_symbol",
      "labour_amount_column_show_rupee_symbol",
    ]);

    const selectType = get_property_settings("catalogue_selection_based_on")
    

    const karat = selectType === "dropdown" ? selected_product_calc?.karat?.value : selected_product_calc?.karat
    const size = selectType === "dropdown" ? selected_product_calc?.size?.value : selected_product_calc?.size
    const piece = selectType === "dropdown" ? selected_product_calc?.piece?.value : selected_product_calc?.piece;
    
    const calc_karat_rate= selected_product_calc?.calc_karat_rate
    const metal_rate= selected_product_calc?.metal_rate
    const calc_gross_weight= selected_product_calc?.calc_gross_weight
    const calc_net_weight= selected_product_calc?.calc_net_weight
    
    
   
  return (
    <div className="details-block my-5">
      <div className="heading mb-3">
        <h5 className="ec-single-title">
          {/* Product Details */}
          {fsv_product_details_title}
        </h5>
      </div>

      {selectedProduct?.label &&
        selectedProduct?.label.length > 0 &&
        selectedProduct?.label.map((item, i) => {
          return (
            <div key={i}>
              {selectedProduct.short_code[i] !== "total_cost" && (
                <div
                  className="d-flex justify-content-between row text-start product-details-bottom-group"
                  key={i}
                >
                  <div className="label w-50 text-start product-details-label">
                    <span>{item}</span>{" "}
                  </div>

                  <div className="w-50 text-end product-details-value">
                    {(selectedProduct.short_code[i] === "karat_cost" ||
                      selectedProduct.short_code[i] === "karat_rate") &&
                    labour_amount_column_show_rupee_symbol === "Yes" ? (
                      <span>
                        {" "}
                        {formatPriceWithRupee(
                          selectedProduct.short_code[i] === "karat_cost"
                            ? calc_karat_rate || selectedProduct?.values[i]
                            : selectedProduct.short_code[i] === "karat_rate"
                            ? metal_rate || selectedProduct?.values[i]
                            : selectedProduct?.values[i]
                        )}
                      </span>
                    ) : (
                      <span>
                        {" "}
                        {selectedProduct.short_code[i] === "gross_wt"
                          ? calc_gross_weight || selectedProduct?.values[i]
                          : selectedProduct.short_code[i] === "net_wt"
                          ? calc_net_weight || selectedProduct?.values[i]
                          : selectedProduct.short_code[i] === "default_karat_id"
                          ? karat || selectedProduct?.values[i]
                          : selectedProduct.short_code[i] === "default_size_id"
                          ? size || selectedProduct?.values[i]
                          : selectedProduct.short_code[i] === "default_piece_id"
                          ? piece || selectedProduct?.values[i]
                          : selectedProduct?.values[i]}
                        {/* {data?.values[i]}  */}
                      </span>
                    )}
                    {/* <span > {data?.values[i]}</span> */}
                  </div>
                  <br />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default ProductDetails;
