import React, { useState, useEffect } from "react";

import { RiCheckboxCircleFill } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import {useDispatch, useSelector} from "react-redux"
import { get_fsv_selected_subProducts, set_FSV_Handler } from "../../../../../redux/Reducer/reducer";
import { FSV_ACTIONS } from "../../../Helper";

function SelectSubproducts({ products }) {
  // const [subProduct, setSubProduct] = useState(products?.map(subProd => subProd?.category_name))
  const subProduct = useSelector(get_fsv_selected_subProducts)
  const dispatch = useDispatch()
  
  function handleSubProd(category_name) {
    
    if(subProduct.includes(category_name) && subProduct.length !== 1){
      dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_SELECTED_SUBPRODUCTS, payload: subProduct.filter(subProd => subProd !== category_name)}))
      // setSubProduct(preSubProduct => preSubProduct.filter(subProd => subProd !== category_name))
    }else{
      if(subProduct.length === 1 && subProduct.includes(category_name)) return
      // setSubProduct(preSubProduct => [...preSubProduct, category_name])
      dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_SELECTED_SUBPRODUCTS, payload: [...subProduct, category_name]}))
    }
  }

  useEffect(() => {
    dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_SELECTED_SUBPRODUCTS, payload: products?.map(subProd => subProd?.category_name)}))
  }, [])

  
  return (
    <div className="container select-subproduct">
      <div className="row product-details-inner-group-sm">
        <div className="col-lg-5 col-md-3 col-sm-5 align-self-center d-flex label product-details-label">
          Select:
        </div>
        <div className="col-sm-7 select-subproduct-checkbox">
          {products?.map((subProd) => (
            <div className="checkBoxSection" key={subProd?.category_name}>
              <input type="checkbox" id={subProd?.category_name} checked={subProduct.includes(subProd?.category_name)} onChange={() => handleSubProd(subProd?.category_name)}/>
              
              <label htmlFor={subProd?.category_name}>

              <RiCheckboxCircleFill className="checkedImg"/>
              <MdCancel className="canceledImg"/>
              </label>
              <label htmlFor={subProd?.category_name}>{subProd?.category_name} ({subProd?.search_item_code})</label>
            </div>
          ))}

          
        </div>
      </div>
    </div>
  );
}

export default SelectSubproducts;
