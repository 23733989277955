import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import useFetch from "../../../../../../Api/useFetch";
import { ApiList } from "../../../../../../Api/ApiList";
import Loader from "../../../../../../Component/Loader";
import Textfield from "../../../Textfield";
import Telephone from "../../../Telephone";
import { useForm, Controller } from "react-hook-form";
import "./Otp-style.css";
import { fetch_data_from_api } from "../../../../../../Api/APICalls";
import {useNavigate} from "react-router-dom"
import {
  ToastError,
  ToastSuccess,
  createSearchURLParams,
  createSelectArrayForReactSelect,
  get_informationFromSessionID,
  get_property_settings,
  get_userData_from_activeUser,
} from "../../../../../../Component/Helper";
import ReactSelectDropdown from "./ReactSelectDropdown";
import { MOBILE_REGEX } from "../../../../../../utils";
import OTPModal from "./OTPModal";


function AddFormModal({
  showFormModal,
  setShowFormModal,
  formHeader = "Add Retailer",
  watch,
  setValueForRetailer,
}) {
  const [countries, setCountries] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState({});
  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [showOtp, setShowOtp] = useState(false);
  const [otpResData, setOtpResData] = useState(null);
  const [resOtp, setResOtp] = useState("");
  const [userFormData, setUserFormData] = useState({});
  const navigate = useNavigate()
  const { data: add_retailer_form_data } = useFetch(
    `${ApiList?.Finalize}/${ApiList?.add_retailer_get_parameters}`,
    "add_retailer_get_parameters"
  );
  
  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (!showFormModal) return;
    setValue("country", defaultCountry);
    if (defaultCountry?.value) {
      fetchState(defaultCountry?.value);
    }
  }, [showFormModal]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      company_name: "",
      full_name: "",
      mobile_no: "",
      email_id: "",
      country: defaultCountry,
      state: null,
      city: null,
      pincode: "",
    },
  });

  if(!showFormModal) return null;
   

  const fetchCountries = async () => {
    const country_name = get_property_settings("country_name");
    const countries_list_response = await fetch_data_from_api(
      `${ApiList.UserRegistration}/${ApiList.get_loc_data}`,
      "get_loc_data",
      { table: "countries", id: "" }
    );
    let default_countries = countries_list_response.find(
      (country) => country.name === country_name
    );
    default_countries = {
      label: default_countries.name,
      value: default_countries.id,
    };
    setDefaultCountry(default_countries);
    setCountries(
      createSelectArrayForReactSelect(countries_list_response, "name", "id")
    );
  };

  const fetchState = async (id) => {
    const state_list_response = await fetch_data_from_api(
      `${ApiList.UserRegistration}/${ApiList.get_loc_data}`,
      "get_loc_data",
      { table: "states", id }
    );
    setState(
      createSelectArrayForReactSelect(state_list_response, "name", "id")
    );
  };

  const fetchCity = async (id) => {
    const cities_list_response = await fetch_data_from_api(
      `${ApiList.UserRegistration}/${ApiList.get_loc_data}`,
      "get_loc_data",
      { table: "cities", id }
    );
    setCities(
      createSelectArrayForReactSelect(cities_list_response, "name", "id")
    );
  };

  
  
  function params_for_add_retailer() {
    if(!otpResData) return {}
    const client_type = get_userData_from_activeUser().client_type || "1";
    const client_id = get_userData_from_activeUser().client_id || "";

    const add_retailer_params = {
      mode: otpResData.mode,
      distributor_id: userFormData.distributor_client_master_id
    }

    if(client_type === "4"){
        if(otpResData?.mode === "new_retailer"){
          add_retailer_params.retailer_id = "";

        }else if(otpResData?.mode === "associate_retailer"){
          add_retailer_params.retailer_id = client_id
        }
    }else{
      add_retailer_params.distributor_id = client_id
      add_retailer_params.retailer_id = otpResData.client_id
    }

    return {...userFormData, ...add_retailer_params}
  }
  
  async function add_retailer() {
    
   
    if(!otpResData) return {}
    const add_retailer_params = params_for_add_retailer()
    
    const add_retailer_response = await fetch_data_from_api(`${ApiList.Finalize}/${ApiList.add_retailer}`, "add_retailer", add_retailer_params)
    
    if(add_retailer_response?.ack === "1"){
      setShowOtp(false)
      setShowFormModal(false)
      

      //Reset data
      reset()
      setResOtp("")
      setUserFormData({})
      navigate(createSearchURLParams('checkout', {retailer_id: add_retailer_response?.final_result.id, distributor_id: add_retailer_params?.distributor_id}))
      //setValueForRetailer("retailer", {label: add_retailer_response?.final_result.retailer_name, value: add_retailer_response?.final_result.id})
      ToastSuccess(add_retailer_response?.msg);  
    }else{
        ToastError(add_retailer_response?.msg || "Somthing went wrong")
    }
  }

   
  
 
  const onSubmit = async (data) => {
  
    
    const distributor_client_master_id = watch("distributor")?.value || "";
    const country_code = get_informationFromSessionID("country_code") || "91";

    data.country = data.country ? data.country.value : "";
    data.state = data.state ? data.state.value : "";
    data.city = data.city ? data.city.value : "";
    data.distributor_client_master_id = distributor_client_master_id;
    data.country_code = `+${country_code}`;
    data.mobile_no = data.mobile_no.replace(country_code, "");

    setUserFormData(data);



    const add_retailer_check_sendOTP_response = await fetch_data_from_api(
      `${ApiList.Finalize}/${ApiList.add_retailer_check_sendOTP}`,
      "add_retailer_check_sendOTP",
      data
    );
    

    if (add_retailer_check_sendOTP_response?.ack === "1") {
      setShowOtp(true);
      setOtpResData(add_retailer_check_sendOTP_response)
      setResOtp(add_retailer_check_sendOTP_response?.mobile_key)
      
    }

    

  };

  
 

  
  
 

  return (
    <Modal
      show={showFormModal}
      onHide={() => {
        setShowFormModal(false);
        reset();
        setShowOtp(false);
      }}
      className="add-form-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{formHeader}</Modal.Title>
      </Modal.Header>
      
      {!showOtp ? (
        <Modal.Body className="add-form-modal-body">
          <div className="ec-register-form">
            <form
              style={{ display: "flex" }}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              {add_retailer_form_data.length > 0 &&
                add_retailer_form_data.map((paramsData, paramsDataIndex) => {
                  return (
                    <span
                      className="ec-register-wrap ec-register-half"
                      key={paramsDataIndex}
                    >
                      <label className="label">{paramsData.field_name}</label>
                      {paramsData.required === "1" ? (
                        <span class="required-field">*</span>
                      ) : (
                        ""
                      )}
                      {paramsData?.control_type === "Textfield" ? (
                        <Textfield
                          clsName="fieldValue"
                          paramsDataIndex={paramsDataIndex}
                          paramsData={paramsData}
                          formData={{}}
                          handleChange={() => {}}
                          register={register}
                        />
                      ) : paramsData?.control_type === "Telephone" ? (
                        <Controller
                          control={control}
                          name={paramsData?.short_code}
                          rules={{
                            required: {
                              value: true,
                              message: `${paramsData?.field_name} is required`,
                            },
                            pattern: {
                              value: MOBILE_REGEX,
                              message: "Invalid mobile no.",
                            },
                          }}
                          render={({ field }) => {
                            return (
                              <Telephone
                                clsName="fieldValue"
                                paramsDataIndex={paramsDataIndex}
                                paramsData={paramsData}
                                {...field}
                              />
                            );
                          }}
                        />
                      ) : paramsData?.control_type === "Country" ? (
                        <Controller
                          control={control}
                          name={paramsData?.short_code}
                          rules={{}}
                          render={({ field }) => {
                            return (
                              <ReactSelectDropdown
                                options={countries}
                                fetchData={fetchState}
                                {...field}
                              />
                            );
                          }}
                        />
                      ) : paramsData?.control_type === "State" ? (
                        <Controller
                          control={control}
                          name={paramsData?.short_code}
                          rules={{}}
                          render={({ field }) => {
                            return (
                              <ReactSelectDropdown
                                options={state}
                                fetchData={fetchCity}
                                {...field}
                              />
                            );
                          }}
                        />
                      ) : paramsData?.control_type === "City" ? (
                        <Controller
                          control={control}
                          name={paramsData?.short_code}
                          rules={{}}
                          render={({ field }) => {
                            return (
                              <ReactSelectDropdown
                                options={cities}
                                {...field}
                              />
                            );
                          }}
                        />
                      ) : null}
                      {errors[paramsData?.short_code]?.message && (
                        <div className="required-field errorText">
                          {errors[
                            paramsData?.short_code
                          ]?.message.toUpperCase()}
                        </div>
                      )}
                    </span>
                  );
                })}
              <span className="ec-register-wrap ec-register-btn mt-4">
                <button
                  tabIndex={
                    add_retailer_form_data && add_retailer_form_data.length
                  }
                  className="btn"
                  type="submit"
                >
                  add client
                </button>
              </span>
            </form>
          </div>
        </Modal.Body>
      ) : (
        <>
          <OTPModal
            resOtp={resOtp}
            add_retailer={add_retailer}
          />
        </>
      )}
    </Modal>
  );
}

export default AddFormModal;
