import React, { useEffect, useRef } from 'react'
import { convertToGrams, formatPriceWithRupee, get_property_settings } from '../../../Component/Helper';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { setCart } from '../../../redux/Reducer/reducer';
import { total_cart_products_cost } from './CartHelper';

function CartFooter({moveAll, type_of_cart, cartData}) {
  
  const navigate = useNavigate()
  const confirm_order_button_text = get_property_settings("confirm_order_button_text")
  const footerData = useSelector((state) => state?.reducer?.cart);
  const dispatch = useDispatch()
  const cartFooterRef = useRef(null)
  useEffect(() => {
    if(cartFooterRef?.current){
      sessionStorage.setItem("cartFooterHeight", cartFooterRef?.current?.offsetHeight)
    }
    if(!cartData) return 
    dispatch(setCart({
        ...footerData,
        total_cost : total_cart_products_cost(cartData) 
    }))
  }, [cartData])

  return (
    <div className="cart-footer py-2" ref={cartFooterRef}>
              <div className="container-fluid">
                <div className="details">
                  <div className="row">
                    <div className="col-6  text-left">
                      <div className="row">
                        <div className="col-6 pr-0  ">Gross Wt:</div>
                        <div className="col-4 p-0 font-weight-bold">
                          {footerData?.total_gross_wt
                            ? convertToGrams(footerData?.total_gross_wt)
                            : "-"}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 pr-0  ">Net Wt:</div>
                        <div className="col-4 p-0 font-weight-bold">
                          {footerData?.total_net_wt
                            ? convertToGrams(footerData?.total_net_wt)
                            : "-"}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 pr-0  ">Total:</div>
                        <div className="col-4 p-0 font-weight-bold">
                          {" "}
                          {footerData?.total_cost
                            ? `${formatPriceWithRupee(footerData?.total_cost)}`
                            : "-"}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 pr-0  ">Pcs:</div>
                        <div className="col-4 p-0 font-weight-bold">
                          {" "}
                          {footerData?.total_quantity
                            ? `${footerData?.total_quantity}`
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 pl-0 d-flex justify-content-end ">
                      <div className="chekout-btn d-flex align-self-center">
                        <div
                          className="btn-li me-0 px-sm-3"
                          onClick={() => {
                            type_of_cart === "temp_wishlist"
                              ? moveAll()
                              : navigate("/checkout", {state: {amount: footerData?.total_cost}});
                            // PlaceOrder(company_code, userID);
                          }}
                        >
                          {type_of_cart === "temp_wishlist"
                            ? "Move All to Cart"
                            : confirm_order_button_text}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default CartFooter