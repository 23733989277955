import React from 'react'

function Tag({status_name, foreground_color, background_color}) {
  
  return (
    <div className='product-tag-btn' style={{backgroundColor: background_color, color: foreground_color}}>
        {status_name}
    </div>
  )
}

export default Tag
