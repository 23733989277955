import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/TriologicLogo.png";
import { Link, useLocation } from "react-router-dom";
import HeaderUserMenu from "./HeaderUserMenu";
import { useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import SearchModal from "../../SearchModal/SearchModal";
import { getUserDetails, get_is_myc_web } from "../../../redux/Reducer/reducer";
import { IoLogOutOutline } from "react-icons/io5";
import { get_informationFromSessionID } from "../../Helper";
import ModalDialog from "../../ModalDialog";
import { logOutUser } from "../../Helper";

import ViewStyle from "./components/ViewStyle/ViewStyle";

function HeaderBottom({ logoImg, setOpenMobileMenu }) {
  const settings = useSelector((state) => state.reducer.settings);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const location = useLocation()
  const is_myc_web = useSelector(get_is_myc_web);
  const userID =
    useSelector(getUserDetails)?.userID ||
    get_informationFromSessionID("userID");
  const company_code = get_informationFromSessionID("companyCode");
  return (
    <>
      <ModalDialog
        show={showLogoutModal}
        setShow={setShowLogoutModal}
        action={logOutUser}
        toastMsg={"Your Successfully logout"}
      />
      <div className="ec-header-bottom">
        <section className="search-modal-container">
          {!is_myc_web && (
            <SearchModal
              showSearch={showSearch}
              setShowSearch={setShowSearch}
            />
          )}
        </section>
        <div className="header-bottom position-relative">
          <div className="row header-mob-nav">
            <div className="align-self-center d-sm-none d-block logo-container-mob">
              <a
                href=""
                className={`ec-header-btn ec-side-toggle burger-menu ${
                  is_myc_web ? "d-none" : "d-lg-none"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenMobileMenu(true);
                }}
              >
                <i className="fi fi-rr-menu-burger"></i>
              </a>

              {/* -- Ec Header Logo Start -- */}
              <div className="header-logo">
                <Link to="/home">
                  <img src={logoImg} alt="Site Logo" />
                </Link>
              </div>
              {/* -- Ec Header Logo End -- */}

              {location.pathname === "/matrix-page" ? <ViewStyle/> : null}
            </div>

            <div className="ec-flex justify-content-center justify-content-lg-between d-sm-flex  d-none ">
              {/* -- Ec Header Logo Start -- */}
              {settings && Object.keys(settings).length > 0 ? (
                <div className="align-self-center logo-container">
                  <div className="header-logo">
                    <Link to={`/home?company_code=${company_code}`}>
                      <img src={logoImg} alt="Site Logo" />
                    </Link>
                  </div>
                </div>
              ) : null}
              {/* -- Ec Header Logo End -- */}

              {/* -- Ec Header Search Start -- */}
              {/* <div className="align-self-center">
                            <div className="header-search">
                                <form className="ec-btn-group-form" action="#">
                                    <input className="form-control ec-search-bar" placeholder="Search products..."
                                        type="text"/>
                                    <button className="submit" type="submit"><i className="fi-rr-search"></i></button>
                                </form>
                            </div>
                        </div> */}
              {/* -- Ec Header Search End -- */}

              {/* -- Ec Header Button Start -- */}

              {/* -- Header User Start -- */}
              {!is_myc_web && userID && (
                <div className="align-self-center search">
                  <div
                    className="ec-header-bottons"
                    onClick={() => setShowSearch(true)}
                  >
                    <FaSearch />
                  </div>
                </div>
              )}
              {/* -- Header User End -- */}

              {/* -- Header User Start -- */}
              <div className="align-self-center">
                <div className="ec-header-bottons d-none d-lg-flex">
                  <HeaderUserMenu />
                  {is_myc_web && userID ? (
                    <IoLogOutOutline
                      className="logout-icon"
                      title="logout"
                      onClick={() => setShowLogoutModal(true)}
                    />
                  ) : null}
                </div>
              </div>
              {/* -- Header User End -- */}

              {/* -- Ec Header Button End -- */}

              {/* -- Header Top responsive Action -- */}
              <div
                className={`d-lg-none d-flex ${is_myc_web ? "ml-auto" : ""}`}
              >
                <div className="ec-header-bottons">
                  {/* -- Header User Start -- */}
                  <HeaderUserMenu />
                  {/* -- Header User End -- */}

                  {/* -- Header menu Start -- */}
                  <a
                    href=""
                    className="ec-header-btn ec-side-toggle d-lg-none"
                    onClick={(e) => {
                      e.preventDefault();
                      !is_myc_web && setOpenMobileMenu(true);
                    }}
                  >
                    {is_myc_web && userID ? (
                      <IoLogOutOutline
                        className="logout-icon logout-icon-right"
                        title="logout"
                        onClick={() => setShowLogoutModal(true)}
                      />
                    ) : (
                      <i className="fi fi-rr-menu-burger"></i>
                    )}
                  </a>
                  {/* -- Header menu End -- */}
                </div>
              </div>
              {/* -- Header Top responsive Action -- */}
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(HeaderBottom);
