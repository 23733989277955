import React, { useState } from "react";
import { ApiList } from "../../../Api/ApiList";
import {
  get_bulk_settings,
  get_informationFromSessionID,
  get_property_settings,
} from "../../Helper";
import { useLocation } from "react-router-dom";
import FooterCategories from "./FooterCategories";
import FooterLinkCard from "./FooterLinkCard";
import FooterPayment from "./FooterPayment";
import { useSelector } from "react-redux";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import FooterLocation from "./FooterLocation";
import FooterCopy from "./FooterCopy";
import { get_is_myc_web } from "../../../redux/Reducer/reducer";
import { useQuery } from "@tanstack/react-query";

function FooterSection() {
  const is_myc_web = useSelector(get_is_myc_web);
  const userID = get_informationFromSessionID("userID");
  const cartCount = useSelector((state) => state?.reducer?.constants?.count);
  let { cdn, company_logo, web_show_product_directory_footer, web_show_payment_methods_strip_footer } =
    get_bulk_settings([
      "cdn",
      "company_logo",
      "web_show_product_directory_footer",
      "web_show_payment_methods_strip_footer"
    ]) || {};
   
  web_show_product_directory_footer = web_show_product_directory_footer === "Yes";
  web_show_payment_methods_strip_footer = web_show_payment_methods_strip_footer === "Yes"
  const logoImg = `${cdn}uploads/company_logo/${company_logo}`;
  //const company_code = get_informationFromSessionID("companyCode")
  //const [links, setLinks] = useState([])
  const location = useLocation();

  const staticpages_query = useQuery({
    queryKey: ["staticpages"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        ApiList.fetchStaticpages,
        "FetchStaticpages"
      ),
    initialData: [],
    enabled: !is_myc_web,
  });

  const cartFooterHeight = sessionStorage.getItem("cartFooterHeight")
    ? `${sessionStorage.getItem("cartFooterHeight")}px`
    : "125px";

  return (
    <footer
      className={`ec-footer footer-main w-100 bottom-0`}
      style={{
        marginBottom: `${
          ((location.pathname === "/cart" && Number(cartCount?.count) > 0) ||
            (location.pathname === "/short-list" &&
              Number(cartCount?.shortlist_count) > 0)) &&
          userID
            ? cartFooterHeight
            : "0"
        }`,
      }}
    >
      <div className="footer-container">
        {is_myc_web || !web_show_product_directory_footer ? null : (
          <FooterCategories />
        )}
        {is_myc_web ? null : (
          <div className="footer-top section-space-footer-p">
            <div className="container-fluid">
              <div className={`row ${staticpages_query?.isSuccess && staticpages_query?.data?.length === 0 ? "justify-content-center" : ""}`}>
                {/* -- Address--  */}
                <FooterLocation logoImg={logoImg} />

                {/* -- Policies --  */}

                {staticpages_query?.isSuccess && (
                  <FooterLinkCard links={staticpages_query?.data} />
                )}
              </div>
            </div>
          </div>
        )}
        {(is_myc_web || !web_show_payment_methods_strip_footer) ? null : <FooterPayment />}
        <FooterCopy />
      </div>
    </footer>
  );
}

export default React.memo(FooterSection);
