import React from 'react'
import { get_property_settings } from '../../Helper'
import NoImg from "../../../assets/images/no_image_available.png"

function BrandingBasedSection({title, btnName, imgName}) {
  const cdn = get_property_settings("cdn");
  const img = imgName ? `${cdn}uploads/branding_image/featured/${imgName}` : NoImg;
  return (
    <div className='branding-based-section'>
      <img src={img} alt={title} />
      <div className='branding-details'>
        <h2 className='branding-title'>{title}</h2>
        <p className='branding-body'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est harum, animi fuga dicta modi totam!</p>
        {btnName && <button className='branding-button'>
            {btnName}
        </button>}
      </div>
    </div>
  )
}

export default BrandingBasedSection
