import { createSlice, createSelector } from "@reduxjs/toolkit";
import { check_is_myc_web } from "../../utils";
import { get_bulk_settings } from "../../Component/Helper";
import { FSV_ACTIONS } from "../../Pages/Product/Helper";

export const initialState = {
  basket: [],
  settings: {},
  products: [],
  constants: {},
  cart: {},
  trigger_cart_btn: false,
  trigger_shortlist_btn: false,
  homeScreen: {},
  categories: [],
  isLoggedIn: { status: false, userID: "" },
  distributor: [],
  cat_master: "design_master",
  is_myc_web: check_is_myc_web(),
  is_allow_access_myc_to_user: false,
  // is_myc_web: false,
  catalogue_data: {},
  matrixViewLayout: window.innerWidth > 350 ? 'grid-layout' : 'single-layout', //grid-layout, single-layout
  FSV_Handler: {
    FSV_Products: [],
    FSV_Selected_Product: {},
    FSV_Products_Calc: {},
    FSV_Remark: "",
    FSV_Quantity: 0,
    FSV_Seleteced_Subproduct: []
  },
  inCartWishList: null,
  whatsAppMsg: "",
};

//Selector
export const getBasketTotal = (basket) =>
  basket?.reduce((amount, item) => item.price + amount, 0);

const reducer = createSlice({
  name: "reducer",
  initialState,
  reducers: {
    getSettingData: (state, action) => {
      state.settings = action.payload;
    },
    setProductsData: (state, action) => {
      state.products = action.payload;
    },
    addItemsToBasket: (state, action) => {
      state.basket = action.payload;
    },
    setConstants: (state, action) => {
      state.constants = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },

    set_inCartWishList: (state, action) => {
      state.inCartWishList = action.payload
    },

    setHomeScreen: (state, action) => {
      state.homeScreen = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setDistributor: (state, action) => {
      state.distributor = action.payload;
    },
    setCatMaster: (state, action) => {
      state.cat_master = action.payload;
    },

    set_is_allow_access_myc_to_user: (state, action) => {
      state.is_allow_access_myc_to_user = action.payload;
    },

    set_catalogue_data: (state, action) => {
      const set_is_secure_for_temp = {
        ...action.payload,
        my_catalogue_data: {
          ...action.payload.my_catalogue_data,
          is_secured: "1",
          allow_new_registration: "1",
        },
      };
      // state.catalogue_data = set_is_secure_for_temp
      state.catalogue_data = action.payload;
    },

    setWhatsAppMessage: (state, action) => {
      state.whatsAppMsg = action.payload;
    },

    set_matrixViewLayout: (state, action) => {
        state.matrixViewLayout = action.payload
    },

    set_trigger_cart_btn: (state, action) => {
      state.trigger_cart_btn = action.payload
    },

    set_trigger_shortlist_btn: (state, action) => {
      state.trigger_shortlist_btn = action.payload
    },

    set_FSV_Handler: (state, action) => {
      
      const { type, payload } = action.payload;

      switch (type) {
        case FSV_ACTIONS.SET_PRODUCTS: {
          state.FSV_Handler.FSV_Products = payload;
          break;
        }

        case FSV_ACTIONS.SET_SELECTED_PRODUCT: {
          if (typeof payload === "number") {
            state.FSV_Handler.FSV_Selected_Product =
              state.FSV_Handler.FSV_Products[payload];
          } else {
            state.FSV_Handler.FSV_Selected_Product = payload;
          }
          break;
        }

        case FSV_ACTIONS.SET_PRODUCT_CALC: {
          if(payload?.action && payload?.action === "reset"){
            state.FSV_Handler.FSV_Products_Calc = {};
          }
          else if (Object.keys(state.FSV_Handler.FSV_Products_Calc).length === 0) {
            state.FSV_Handler.FSV_Products_Calc = {[payload?.product_name]: payload?.data} || {};
          } else {
            if(!payload?.product_name) return
            
            state.FSV_Handler.FSV_Products_Calc = {
              ...state.FSV_Handler.FSV_Products_Calc,
              [payload?.product_name]: {
                ...state.FSV_Handler.FSV_Products_Calc[payload?.product_name],
                ...payload?.data,
              },
            };

            
          }

          break;
        }


        case FSV_ACTIONS.SET_REMARKS: {
          state.FSV_Handler.FSV_Remark = payload
          break;
        }

        case FSV_ACTIONS.SET_QUANTITY: {
          state.FSV_Handler.FSV_Quantity = payload
          break;
        }

        case FSV_ACTIONS.SET_SELECTED_SUBPRODUCTS: {
            state.FSV_Handler.FSV_Seleteced_Subproduct = payload
            break;
        }

        default:
          return state;
      }
    },
  },
});
export const {
  getSettingData,
  setProductsData,
  addItemsToBasket,
  setConstants,
  set_inCartWishList,
  setCart,
  setIsLoggedIn,
  setHomeScreen,
  setCategories,
  setDistributor,
  setCatMaster,
  set_catalogue_data,
  set_is_allow_access_myc_to_user,
  setWhatsAppMessage,
  set_FSV_Handler,
  set_matrixViewLayout,
  set_trigger_cart_btn,
  set_trigger_shortlist_btn
} = reducer.actions;
export default reducer.reducer;

export const get_inCartWishList = (state) => state?.reducer?.inCartWishList;
export const getUserDetails = (state) => state?.reducer?.isLoggedIn;
// export const getCategories = (state) => state?.reducer?.categories;
export const getCategories = createSelector(
  [(state) => state?.reducer?.categories],
  (categories) => categories
);
export const getDistributor = (state) => state?.reducer?.distributor;
export const getCatMaster = (state) => state?.reducer?.cat_master;

export const getAllSettings = (state) => state?.reducer?.settings;
export const get_properties_from_settings = (state, requiredProperties) => {
  const setttings = state?.reducer?.settings;
  return get_bulk_settings(requiredProperties, setttings);
};

//Matrix Layout
export const get_martix_view_layout = (state) => state?.reducer?.matrixViewLayout;

//get cart / shortlist trigger
export const get_trigger_shortlist_btn = (state) => state?.reducer?.trigger_shortlist_btn;
export const get_trigger_cart_btn = (state) => state?.reducer?.trigger_cart_btn;

//FSV
export const get_fsv_products = (state) =>
  state?.reducer?.FSV_Handler?.FSV_Products;
export const get_fsv_selected_product = (state) =>
  state?.reducer?.FSV_Handler?.FSV_Selected_Product;
export const get_fsv_products_calc = (state) =>
  state?.reducer?.FSV_Handler?.FSV_Products_Calc;
export const get_fsv_remark = (state) => state?.reducer?.FSV_Handler?.FSV_Remark
export const get_fsv_quantity = (state) => state?.reducer?.FSV_Handler?.FSV_Quantity
export const get_fsv_selected_subProducts = (state) => state?.reducer?.FSV_Handler?.FSV_Seleteced_Subproduct
export const get_fsv_selected_product_property = (state, property) => state?.reducer?.FSV_Handler?.FSV_Selected_Product[property]


//MYC
export const get_is_myc_web = (state) => state?.reducer?.is_myc_web;
export const get_myc_catalogue_data = (state) =>
  state?.reducer?.catalogue_data?.my_catalogue_data;
export const get_myc_catalogue_id = (state) =>
  state?.reducer?.catalogue_data?.my_catalogue_id;
export const get_myc_is_secured = (state) => {
  const is_myc_web = state?.reducer?.is_myc_web;
  const is_secured =
    state?.reducer?.catalogue_data?.my_catalogue_data?.is_secured;
  return is_myc_web && is_secured === "1";
};
export const get_myc_allow_new_registration = (state) => {
  const is_myc_web = state?.reducer?.is_myc_web;
  const is_allow_new_registration =
    state?.reducer?.catalogue_data?.my_catalogue_data?.allow_new_registration;
  return is_myc_web && is_allow_new_registration === "1";
};
export const get_myc_catalogue_title = (state) =>
  state?.reducer?.catalogue_data?.my_catalogue_data?.catalogue_name;
export const get_myc_allow_access_status = (state) => state?.reducer?.is_allow_access_myc_to_user;

//HomeScreen
export const getFeatured_list = (state) =>
  state?.reducer?.homeScreen?.featured_list;
export const getBranding = (state) => state?.reducer?.homeScreen?.branding;
export const getUSP = (state) => state?.reducer?.homeScreen?.usp;
export const getTestimonial = (state) =>
  state?.reducer?.homeScreen?.testimonial;
export const getLocation = (state) => state?.reducer?.homeScreen?.location;
