import React from "react";
import { Link } from "react-router-dom";
import { createSearchURLParams } from "../../Helper";
import Loader from "../../Loader";

function FooterLinkCard({ links }) {
  let dummyShowMenu = [];

  if(!Array.isArray(links)) return null
  
  return links?.map((link, linkIndex) => {
    const show_submenu = Boolean(Number(link.show_submenu));
    if (!dummyShowMenu.length) {
      dummyShowMenu = links;
    }

    return (
      <div className="col-sm-12 col-lg-3 ec-footer-account" key={linkIndex}>
        <div className="ec-footer-widget footer-link-container">
          {show_submenu && (
            <Link
              // to={`/static-page/${link?.item
              //   ?.replace(/ /g, "-")
              //   .toLowerCase()}?id=${link.static_pages_id}`}
              to={
                createSearchURLParams(`static-page/${link?.item
                  ?.replace(/ /g, "-")
                  .toLowerCase()}`,
                  {id: link.static_pages_id}
                  )
              }
              state={{ id: link?.static_pages_id }}
            >
              <h4 className="ec-footer-heading">{link?.item}</h4>
            </Link>
          )}

          <div className="ec-footer-links">
            <ul className="align-items-center">
              {show_submenu
                ? link.submenu.map((menu, index) => (
                    <li
                      className="ec-footer-link"
                      key={index}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <Link
                        to={createSearchURLParams(
                          `static-page/${link?.item
                            ?.replace(/ /g, "-")
                            .toLowerCase()}`,
                          { id: menu?.static_pages_id }
                        )}
                        state={{ id: menu?.static_pages_id }}
                      >
                        {menu?.item}
                      </Link>
                    </li>
                  ))
                : linkIndex === 0 &&
                  dummyShowMenu.map((menu) => (
                    <li className="ec-footer-link">
                      <Link
                        to={createSearchURLParams(
                          `static-page/${menu.item
                            .replace(/ /g, "-")
                            .toLowerCase()}`,
                          { id: menu?.static_pages_id }
                        )}
                        state={{ id: menu.static_pages_id }}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        {menu.item}
                      </Link>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      </div>
    );
  });
}

export default React.memo(FooterLinkCard);
