import axios from "axios";
import CryptoJS from "crypto-js";
import { BASE_URL } from "../Api/ApiList";
import { toast } from "react-toastify";

// const is_development = process.env.NODE_ENV === "development"
const is_development = false;

export function formatPriceWithRupee(price, currencySymbol = true) {
  const numericPrice = parseFloat(price);
  if (isNaN(numericPrice)) {
    return "";
  }
  return `${currencySymbol ? "₹" : ""}${new Intl.NumberFormat("en-IN").format(
    price
  )}`;
  // return `₹${numericPrice.toLocaleString()}`;
}
export function formatPriceWithPercentage(price) {
  const numericPrice = parseFloat(price);
  if (isNaN(numericPrice)) {
    return "";
  }
  return `${numericPrice.toLocaleString()}%`;
}

export function convertToGrams(value) {
  const grams = Math.ceil(parseFloat(value) * 1000) / 1000;

  return `${grams.toFixed(3)}gms`;
}

export function UrlToString(url) {
  const slashIndexStart = url.indexOf("/") + 1;
  const slashIndexEnd = url.indexOf("/", slashIndexStart);
  if (slashIndexEnd !== -1) {
    return url.slice(slashIndexStart, slashIndexEnd);
  } else {
    return url.replace("/", "");
  }
  // return ""; // Return an empty string if there are no two slashes.
}

export function isValidEmail(email) {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex.test(email);
}

export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return encrypted;
};

export const decryptData = (data) => {
  const encrypted = data;
  const decrypted = CryptoJS.AES.decrypt(
    encrypted,
    process.env.REACT_APP_SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted);
};

function local_encryptData(data) {
  if (!data) return null;
  if (is_development) {
    return JSON.stringify(data);
  }
  return encryptData(data);
}

function local_decryptData(data) {
  try {
    if (!data) return null;
    if (is_development) {
      return JSON.parse(data);
    }
    return decryptData(data);
  } catch (e) {
    localStorage.clear();
    window.location.reload();
  }
}

const axiosInstance = axios.create({
  baseURL: BASE_URL, // Set your base URL
  // headers: {
  //   Authorization: JSON.parse(localStorage.getItem("auth_token")),
  //   "Content-Type": "application/json", // Set the content type
  // },
});
export const fetchSecureData = async (url, obj, funName) => {
  const paramsObj = decryptData(obj.params);

  const key = Object.keys(paramsObj);
  const values = Object.values(paramsObj);
  let params = key.reduce((acc, d, index) => {
    return index === 0
      ? acc.concat(`${d}=${values[index]}`)
      : acc.concat(`&${d}=${values[index]}`);
  }, "");

  // const { company_code } = params;
  const api_url = decryptData(obj.api_url);
  //

  if (params && api_url) {
    const apiUrl = `${api_url}/?${params}`;
  }

  try {
    const response = await axiosInstance.post(url, obj);

    if (response.status === 200) {
      const apiUrl = `${api_url}/?${params}`;

      if (url.includes("Matrix")) {
      }
      if (
        typeof decryptData(response.data) === "object" ||
        url.includes("StaticPage")
      ) {
        return response.data;
      } else {
        return encryptData("No Data Found");
      }
    } else {
      console.error(decryptData(obj.api_url), response);
    }
  } catch (error) {
    //if there is no register mobile no. fetch response
    if (url.includes("fetchotp")) {
      const api_url = decryptData(obj.api_url);
      const paramsObj = decryptData(obj.params);
      const key = Object.keys(paramsObj);
      const values = Object.values(paramsObj);
      let params = key.reduce((acc, d, index) => {
        return index === 0
          ? acc.concat(`${d}=${values[index]}`)
          : acc.concat(`&${d}=${values[index]}`);
      }, "");

      let apiUrl;
      if (params && api_url) {
        apiUrl = `${api_url}/?${params}`;
        //
      }

      const res = await fetch(apiUrl);
      const data = await res.json();

      if (typeof data === "object" && data.ack) {
        return encryptData(data);
      }
    } else {
      ToastError(decryptData(error?.response?.data));
      throw error;
    }
  }
};

export function createSelectArrayForReactSelect(
  arr,
  arr_obj_key_for_label,
  arr_obj_key_for_value
) {
  if (!arr || !Array.isArray(arr) || (Array.isArray(arr) && arr.length === 0))
    return [];

  //If there is an object in array
  if (
    arr &&
    Array.isArray(arr) &&
    arr_obj_key_for_label &&
    arr_obj_key_for_value
  ) {
    return arr.map((obj) => ({
      label: obj[arr_obj_key_for_label],
      value: obj[arr_obj_key_for_value],
    }));
  }

  //If there is string in array
  else if (arr && Array.isArray(arr)) {
    return arr.map((value) => ({ label: value, value })) || [];
  } else {
    return [];
  }
}

export function createSelectOptionForReactSelect(value) {
  if (!value) return {};
  
  return { label: value, value };
}

export function hasPropertyInObj(obj) {
  return Object.keys(obj).length > 0;
}

export const ToastError = (message, time) => {
  //
  toast.error(message, { autoClose: time ? time : 5000 });
};
export const ToastSuccess = (message, time) => {
  //
  toast.success(message, { autoClose: time ? time : 2000 })
  
};

export const debounce = (func, delay) => {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
};

export function logOutUser() {
  const sessionID = local_decryptData(sessionStorage.getItem("sessionID"));
  let companyCode = sessionID.companyCode;
  let catalogueID = sessionID.catalogueID;
  let r = sessionID.r;
  let user_id = sessionID.userID;

  const activeUsers = local_decryptData(localStorage.getItem("activeUsers"));

  //delete setting and user
  // const updatedSetting = settings.filter(setting => setting.companyCode !== companyCode);
  const updatedActiveUsers = activeUsers.filter(
    (users) => users.companyCode !== companyCode && users.userID !== user_id
  );

  // localStorage.setItem("settings", JSON.stringify(updatedSetting))
  const encrypted_local_data = local_encryptData(updatedActiveUsers);
  localStorage.setItem("activeUsers", encrypted_local_data);

  sessionStorage.clear();
  const encrypted_session_data = local_encryptData({
    companyCode,
    catalogueID,
    r,
  });
  sessionStorage.setItem("sessionID", encrypted_session_data);
}

export function get_informationFromSessionID(key) {
  const sessionID = local_decryptData(sessionStorage.getItem("sessionID"));
  if (sessionID) {
    return sessionID[key];
  } else {
    return null;
  }
}

export const checkCcandCid = (msg) => {
  const companyCode = get_informationFromSessionID("companyCode");

  if (!companyCode) {
    ToastError("Please Provide Company Code.");
    return false;
  } else {
    return true;
  }
};

export function createSearchURLParams(pageURL, searchParamsObj, hash) {
  let searchParamsString = "";
  if (searchParamsObj?.company_code) {
    delete searchParamsObj?.company_code;
  }
  //let myc_id = get_informationFromSessionID("rs")
  const companyCode = get_informationFromSessionID("companyCode");
  const keys = Object.keys(searchParamsObj);
  const values = Object.values(searchParamsObj);

  keys.forEach((key, index) => {
    if (index === 0) {
      searchParamsString += `company_code=${companyCode}&${key}=${values[index]}`;
    }
    else {
      searchParamsString += `&${key}=${values[index]}`;
    }
  });

  return `/${pageURL}?${searchParamsString}${hash ? "#" + hash : ""}`;
}

export function set_informationToSessionID(data) {
  const sessionID = local_decryptData(sessionStorage.getItem("sessionID"));
  if (sessionID) {
    const encrypted_session_data = local_encryptData({ ...sessionID, ...data });
    sessionStorage.setItem("sessionID", encrypted_session_data);
  } else {
    const encrypted_session_data = local_encryptData({ ...data });
    sessionStorage.setItem("sessionID", encrypted_session_data);
  }
}

export function delete_informationFromSessionID(key) {
  const sessionID = local_decryptData(sessionStorage.getItem("sessionID"));
  delete sessionID[key];
  const encrypted_session_data = local_encryptData(sessionID);
  sessionStorage.setItem("sessionID", encrypted_session_data);
}

export function get_userInfoFromLocal(key) {
  let getUserData = local_decryptData(localStorage.getItem("activeUsers"));
  const activeUserID = get_informationFromSessionID("userID");
  if (!getUserData || !activeUserID) return null;
  getUserData = getUserData.find(
    (data) => data.userID === activeUserID
  )?.userData;
  if (getUserData) {
    return getUserData[key];
  } else {
    return null;
  }
}

export function get_designStatus(master) {
  const tableParams = master || "design_master";
  const userID = get_informationFromSessionID("userID");

  if (userID) {
    if (tableParams === "design_master") {
      return get_userInfoFromLocal("selected_design_status_master") || "1";
    } else {
      return get_userInfoFromLocal("selected_inventory_status_master") || "1";
    }
  } else {
    if (tableParams === "design_master") {
      return get_property_settings("selected_design_status_master") || "1";
    } else {
      return get_property_settings("selected_inventory_status_master") || "1";
    }
  }
}

export function set_activeUser() {
  //When user login
  //Add new user
  //add catlogID for exisiting user and exisiting companyID
  const companyCode = get_informationFromSessionID("companyCode");
  const catalogueID = get_informationFromSessionID("catalogueID");
  const userID = get_informationFromSessionID("userID");
  //Active Users
  const activeUsers = local_decryptData(localStorage.getItem("activeUsers"));

  if (!activeUsers) {
    const encrypted_local_data = local_encryptData([
      { companyCode, catalogueID: catalogueID ? [catalogueID] : [], userID },
    ]);
    localStorage.setItem("activeUsers", encrypted_local_data);
  } else {
    const ccAnduid = activeUsers.findIndex(
      (user) => user.userID === userID && user.companyCode === companyCode
    );

    if (ccAnduid > -1) {
      if (
        catalogueID &&
        !activeUsers[ccAnduid].catalogueID?.includes(catalogueID)
      ) {
        activeUsers[ccAnduid].catalogueID = [
          ...activeUsers[ccAnduid]?.catalogueID,
          catalogueID,
        ];
      }
    } else {
      activeUsers.push({
        companyCode,
        catalogueID: catalogueID ? [catalogueID] : null,
        userID,
      });
    }
    const encrypted_local_data = local_encryptData(activeUsers);
    localStorage.setItem("activeUsers", encrypted_local_data);
  }
}

export function set_userData_to_activeUser(data) {
  const companyCode = get_informationFromSessionID("companyCode");
  const userID = get_informationFromSessionID("userID");

  //Active Users
  const activeUsers = local_decryptData(localStorage.getItem("activeUsers"));

  const ccAnduid = activeUsers.findIndex(
    (user) => user.userID === userID && user.companyCode === companyCode
  );

  if (ccAnduid > -1) {
    activeUsers[ccAnduid].userData = data;
  } else {
  }

  const encrypted_local_data = local_encryptData(activeUsers);
  localStorage.setItem("activeUsers", encrypted_local_data);
}

export function get_userData_from_activeUser() {
  const sessionID = local_decryptData(sessionStorage.getItem("sessionID"));
  const activeUsers = local_decryptData(localStorage.getItem("activeUsers"));
  const userData =
    activeUsers &&
    activeUsers.find(
      (user) =>
        user.companyCode === sessionID.companyCode &&
        user.userID === sessionID.userID
    )?.userData;
  if (userData) {
    return userData;
  } else {
    return {};
  }
}

export function get_inCartWishList() {
  const sessionID = local_decryptData(sessionStorage.getItem("sessionID"));
  const activeUsers = local_decryptData(localStorage.getItem("activeUsers"));
  const inCartWishList =
    activeUsers &&
    activeUsers.find(
      (user) =>
        user.companyCode === sessionID.companyCode &&
        user.userID === sessionID.userID
    )?.inCartWishList;
  if (inCartWishList) {
    return inCartWishList;
  } else {
    return {};
  }
}

export function get_product_status_details(status_details) {
  if (!status_details) return null;
  if (Array.isArray(status_details) && status_details.length > 0) {
  } else if (typeof status_details === "object") {
    return status_details;
  }
}

export function set_productID_inCartWishList(
  typeOfCart,
  design_inventory,
  product_id,
  action,
  inCartWishList
) {
  const CartWishList_property =
    typeOfCart === "wishlist" && design_inventory === "design_master"
      ? "in_wishlist_id_dm"
      : typeOfCart === "wishlist" && design_inventory === "inventory_master"
      ? "in_wishlist_id_im"
      : typeOfCart === "cart" && design_inventory === "design_master"
      ? "in_cart_id_dm"
      : typeOfCart === "cart" && design_inventory === "inventory_master"
      ? "in_cart_id_im"
      : "";

  let updated_inCartWishList = {};
  if (action === "add") {
    updated_inCartWishList = {
      ...inCartWishList,
      [CartWishList_property]: [
        ...inCartWishList[CartWishList_property],
        product_id,
      ],
    };
    //set_inCartWishList(updated_inCartWishList);
  } else if (action === "remove") {
    updated_inCartWishList = {
      ...inCartWishList,
      [CartWishList_property]: inCartWishList[CartWishList_property].filter(
        (id) => id !== product_id
      ),
    };
    //set_inCartWishList(updated_inCartWishList);
  }

  return updated_inCartWishList;
}

export function set_inCartWishList(data) {
  const sessionID = local_decryptData(sessionStorage.getItem("sessionID"));
  const activeUsers = local_decryptData(localStorage.getItem("activeUsers"));
  const findUser = activeUsers.findIndex(
    (user) =>
      user.companyCode === sessionID.companyCode &&
      user.userID === sessionID.userID
  );
  activeUsers[findUser].inCartWishList = data;
  const encrypted_local_data = local_encryptData(activeUsers);
  localStorage.setItem("activeUsers", encrypted_local_data);
}

export function set_settings(data) {
  const companyCode = get_informationFromSessionID("companyCode");
  const settings = local_decryptData(localStorage.getItem("settings"));

  if (!settings || (Array.isArray(settings) && settings.length === 0)) {
    const encrypted_local_data = local_encryptData([
      { settings: data, companyCode: companyCode },
    ]);
    localStorage.setItem("settings", encrypted_local_data);
  } else {
    const savedCompany = settings.map((setting) => setting.companyCode);

    if (!savedCompany.includes(companyCode)) {
      const encrypted_local_data = local_encryptData([
        ...settings,
        { settings: data, companyCode: companyCode },
      ]);
      localStorage.setItem("settings", encrypted_local_data);
    } else {
      const updatedSetting = settings.map((setting) => {
        if (setting.companyCode === companyCode) {
          return { ...setting, settings: data };
        } else {
          return setting;
        }
      });
      const encrypted_local_data = local_encryptData(updatedSetting);
      localStorage.setItem("settings", encrypted_local_data);
    }
  }
}

export function get_settings() {
  const sessionID = local_decryptData(sessionStorage.getItem("sessionID"));
  const settings = local_decryptData(localStorage.getItem("settings"));

  if (settings && sessionID) {
    return settings.find(
      (setting) => setting.companyCode === sessionID.companyCode
    )?.settings;
  } else {
    return [];
  }
}

export function get_property_settings(property_name) {
  const setting = get_settings();

  if (setting) {
    return setting[property_name];
  } else {
    return null;
  }
}

export function get_bulk_settings(
  required_setting_properties_arr,
  settings = null
) {
  const setting = settings || get_settings();
  let obj = {};
  if (setting) {
    required_setting_properties_arr.forEach((settingProperty) => {
      obj[settingProperty] = setting[settingProperty] || "";
    });
  }

  return obj;
}

export function checkUserAlreadyLogin() {
  const sessionID = local_decryptData(sessionStorage.getItem("sessionID"));
  const activeUsers = local_decryptData(localStorage.getItem("activeUsers"));
  if (!sessionID) return false;
  const findUser =
    activeUsers &&
    activeUsers.find((user) => {
      return user.companyCode === sessionID.companyCode;
    });

  if (findUser) {
    set_informationToSessionID({ userID: findUser.userID });
    set_activeUser();
    return true;
  } else {
    set_informationToSessionID({ userID: "" });
  }
}

// export function set_data_to_session_storage(key, data) {
//   sessionStorage.setItem(key, JSON.stringify(data))
// }

// export function get_data_from_session_storage(key) {
//   return JSON.stringify(sessionStorage.getItem(key))
// }

export function checkUserAlreadyLogOut() {
  const sessionID = local_decryptData(sessionStorage.getItem("sessionID"));
  const activeUsers = local_decryptData(localStorage.getItem("activeUsers"));

  if (sessionID.userID) {
    const findUser =
      activeUsers &&
      activeUsers.find(
        (user) =>
          user.companyCode === sessionID.companyCode &&
          user.userID === sessionID.userID
      );
    if (!findUser) {
      delete_informationFromSessionID("userID");
    }
  }
}

export const CSS_StyleSettings = [
  "button_background_color",
  "button_foreground_color",
  "button_boarder_color",
  "update_btn_bg_color",
  "update_btn_fg_color",
  "btn_primary_color",
  "btn_primary_foreground_color",
  "login_register_bg_color",
  "login_register_fg_color",
  "nav_secondary_bar_button_border",
  "btn_secondary_foreground_color",
  "btn_secondary_border_color",
  "btn_secondary_color",
  "fsv_bottom_toolbar_bg_color",
  "cart_group_by_bg_color",
  "cart_group_by_label_color",
  "button_unselected_bg_color",
  "button_unselected_fg_color",
  "button_unselected_border_color",
  "cart_summary_section_detail_fg_color",
  "cart_summary_section_bg_color",
  "highlight_color",
  "homescreen_category_height_ratio",
  "web_company_logo_max_height",
  "web_company_logo_height",
  "web_default_font_css_value",
  "matrix_title_bar_bg_color",
  "matrix_title_bar_fg_color",
];

export function setSettingStylesToRootElement(data) {
  if (!data && !data.constructor === Object)
    throw new Error("Not vaild setting data");

  CSS_StyleSettings.forEach((style) => {
    function styleValue() {
      switch (style) {
        case "web_default_font_css_value":
          return data[style] ? data[style] : "Arial";
        default:
          return data[style];
      }
    }

   

    // document.documentElement.style.setProperty(`--${style}`, data[style]);
    document.documentElement.style.setProperty(`--${style}`, styleValue());
    if (style === "highlight_color") {
      document.documentElement.style.setProperty(
        `--${style}-opac`,
        data[style]?.replace(")", " ,0.2)")
      );
    }
  });
}

export function get_design_inventory_and_realated_cart_property(
  design_inventory
) {
  let property = "";
  let typeOfID = "";
  if (design_inventory === "design_master") {
    property = "in_cart_id_dm";
    typeOfID = "design_master_id";
  } else if (design_inventory === "inventory_master") {
    property = "in_cart_id_im";
    typeOfID = "inventory_master_id";
  }

  return { typeOfID, property }; //return {typeOfID: design_master_id || inventory_master_id, property: in_cart_id_dm || in_cart_id_im}
}

export function get_which_master_from_FSV(FSV_DATA) {
  let which_master = "";
  let master_id = "";
  if (FSV_DATA?.constructor === Object) {
    for (const key in FSV_DATA) {
      if (key === "design_master_id" || key === "inventory_master_id") {
        which_master = key.replace("_id", "");
        master_id = key;
        break;
      }
    }
  }

  return { which_master, master_id };

  //It will return {which_master:design_master || inventory_master, master_id: design_master_id, inventory_master_id}
}

export function get_values_base_on_short_code_from_FSV(
  short_code_array,
  values_array,
  required_short_code_array
) {
  const obj = {};
  if (Array.isArray(required_short_code_array)) {
    short_code_array.forEach((code, index) => {
      if (required_short_code_array.includes(code)) {
        obj[code] = values_array[index];
      }
    });
  } else {
    short_code_array.forEach((code, index) => {
      obj[code] = values_array[index];
    });
  }

  return obj;
}

export function get_material_datails_from_FSV(
  material,
  required_property_array
) {
  if (material.length === 0) return [];
  const arr = [];

  const materials = material[0];

  for (const mat in materials) {
    const material = materials[mat];
    if (Array.isArray(material)) {
      const property = material[0];

      const matObj = required_property_array.reduce((obj, mat_property) => {
        if (mat_property === "material_id") {
          obj[mat_property] = property["material_master_id"] || "";
        } else if (mat_property === "material_table_id") {
          obj[mat_property] = property["id"] || "";
        } else {
          obj[mat_property] = property[mat_property] || "";
        }

        return obj;
      }, {});

      arr.push(matObj);
    }
  }

  return arr;
}

/********************  Event Listner  ******************************************** */
export function closeMenuWhenClickedOutside(event, state, setState, target) {
  event.stopPropagation();

  const isTargetHit = target.find((value) => {
    try {
      if (!event.target || !event.target?.className) {
        return null;
      } else if (event.target?.className) {
        return event.target?.className.includes(value);
      }
    } catch (e) {
      return null;
    }
  });

  if (!state || isTargetHit) return;

  if (state) {
    setState(false);
  }
}

export const LOCAL_CART_WISH_LIST_ACTIONS = {
  transferAll: "transferAll",
  transfer: "transfer",
  deleteAll: "deleteAll",
  delete: "delete",
};

export function update_inCartWishList_in_local_from_cart(
  cartData,
  action,
  transferTo,
  target_product_ID
) {
  //ACTION: transferAll, transfer, deleteAll, delete
  let dm_ids = []; //design master id's
  let im_ids = []; //inventory master id's

  let Local_Cart_Wish_list = get_inCartWishList();

  cartData.forEach((data) => {
    data.product_details.forEach((product) => {
      if (product?.which_master === "design_master") {
        if (
          action === LOCAL_CART_WISH_LIST_ACTIONS.delete ||
          action === LOCAL_CART_WISH_LIST_ACTIONS.transfer
        ) {
          if (product?.design_master_id === target_product_ID) {
            dm_ids.push(target_product_ID);
          }
        }

        if (
          action === LOCAL_CART_WISH_LIST_ACTIONS.deleteAll ||
          action === LOCAL_CART_WISH_LIST_ACTIONS.transferAll
        ) {
          dm_ids.push(product?.design_master_id);
        }
      } else if (product?.which_master === "inventory_master") {
        if (
          action === LOCAL_CART_WISH_LIST_ACTIONS.delete ||
          action === LOCAL_CART_WISH_LIST_ACTIONS.transfer
        ) {
          if (product?.inventory_master_id === target_product_ID) {
            im_ids.push(target_product_ID);
          }
        }

        if (
          action === LOCAL_CART_WISH_LIST_ACTIONS.deleteAll ||
          action === LOCAL_CART_WISH_LIST_ACTIONS.transferAll
        ) {
          im_ids.push(product?.inventory_master_id);
        }
      }
    });
  });

  if (
    transferTo === "cart" &&
    action === LOCAL_CART_WISH_LIST_ACTIONS.transferAll
  ) {
    set_inCartWishList({
      ...Local_Cart_Wish_list,
      in_wishlist_id_dm: [...Local_Cart_Wish_list.in_wishlist_id_dm, ...dm_ids],
      in_wishlist_id_im: [...Local_Cart_Wish_list.in_wishlist_id_im, ...im_ids],
      in_cart_id_dm: [],
      in_cart_id_im: [],
    });
  } else if (
    transferTo === "cart" &&
    action === LOCAL_CART_WISH_LIST_ACTIONS.transfer
  ) {
    set_inCartWishList({
      ...Local_Cart_Wish_list,
      in_wishlist_id_dm: [...Local_Cart_Wish_list.in_wishlist_id_dm, ...dm_ids],
      in_wishlist_id_im: [...Local_Cart_Wish_list.in_wishlist_id_im, ...im_ids],
      in_cart_id_dm: [],
      in_cart_id_im: [],
    });
  } else if (
    transferTo === "wishlist" &&
    action === LOCAL_CART_WISH_LIST_ACTIONS.transferAll
  ) {
    set_inCartWishList({
      ...Local_Cart_Wish_list,
      in_cart_id_dm: [...Local_Cart_Wish_list.in_cart_id_dm, ...dm_ids],
      in_cart_id_im: [...Local_Cart_Wish_list.in_cart_id_im, ...im_ids],
      in_wishlist_id_dm: [],
      in_wishlist_id_im: [],
    });
  } else if (transferTo === "delete") {
    set_inCartWishList({
      ...Local_Cart_Wish_list,
      in_cart_id_dm: [...Local_Cart_Wish_list.in_cart_id_dm, ...dm_ids],
      in_cart_id_im: [...Local_Cart_Wish_list.in_cart_id_im, ...im_ids],
      in_wishlist_id_dm: [],
      in_wishlist_id_im: [],
    });
  }
}

//**************************************************** Function for Checkout page ******************************************************** */
export function depended_child_func(
  paramsData,
  current_selected_value,
  set_depended_child
) {
  const dependant_shortcodes = paramsData?.dependant_shortcodes;

  //1. return null if there is no dependant_shortcodes (ethier it's in string OR array) No need to execute the function
  if (typeof dependant_shortcodes === "string" && dependant_shortcodes === "")
    return null; //for string
  if (Array.isArray(dependant_shortcodes) && dependant_shortcodes.length === 0)
    return null; //for array

  //2. dependant_shortcodes is string
  if (typeof dependant_shortcodes === "string") {
    const currentValue = current_selected_value || paramsData?.default_select;
    if (!currentValue) return null;

    const child_short_code = dependant_shortcodes + "_" + currentValue;
    update_depended_child(dependant_shortcodes, child_short_code);

    //2. dependant_shortcodes is Array
  } else if (Array.isArray(dependant_shortcodes)) {
    const currentValue = current_selected_value || paramsData?.default_select;
    if (!currentValue) return null;

    dependant_shortcodes.forEach((dependant_shortcode) => {
      const child_short_code = dependant_shortcode + "_" + currentValue;
      update_depended_child(dependant_shortcode, child_short_code);
    });
  }

  function update_depended_child(dependant_shortcode, child_short_code) {
    set_depended_child((child) => {
      if (Object.keys(child).length === 0) {
        return {
          child_short_code: [child_short_code],
          dependant_shortcodes: [dependant_shortcode],
          required: "1",
        };
      }
      //Here remove prev save dependant_shortcode and set new child_short_code
      return {
        child_short_code: [
          ...child?.child_short_code.filter(
            (value) => !value.includes(dependant_shortcode)
          ),
          child_short_code,
        ],
        dependant_shortcodes: [
          ...new Set([...child?.dependant_shortcodes, dependant_shortcode]),
        ],
        required: "1",
      };
    });
  }

  //return {child_short_code: ["gold_color_As-Per-Product-Selection", gold_color_22K, stamp_As-Per-Product-Selection, stamp_14K], required: 1 || 0}
}

export function get_plain_value_from_inputs(value) {
  if (!value) return "";
  if (typeof value === "string") return value;
  //if value is came from react select
  return value?.value || "";
}

export function calculatedDOM_Height(...args) {
  let height = args.reduce((total, curValue) => {
    total += Number(curValue);
    return total;
  }, 0);

  const headerHeight = Number(sessionStorage.getItem("headerHeight")) || 0;
  const HeaderMenuHeight = Number(sessionStorage.getItem("HeaderMenu")) || 0;
  return window.innerHeight - (headerHeight + HeaderMenuHeight + height);
}
