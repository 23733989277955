
import Slider from "react-slick";

const defaultSettings = {
    dot: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
    
    

};




function ReactSlider({children, additionalSetting, responsive, className}) {
  
  return (
    // <div className="react-global-slider">
        <Slider {...defaultSettings} {...additionalSetting}
        responsive={responsive || null}
        className={className || ""}
        >
            {children}
        </Slider> 
    // </div>
  )
}

export default ReactSlider
