import React, { useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import {
  createSearchURLParams,
  
} from "../../Helper";

import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";

function MultiListMenu({ cat_name, splitArray, id }) {
  const listRef = useRef(null);
  const megaMenuRef = useRef(null);
  const horizontalMenu = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.horizontal_menu
  );

  useEffect(() => {
    const BoundingClientRect = megaMenuRef?.current.getBoundingClientRect();
    if (BoundingClientRect.left < 0) {
      if (horizontalMenu?.status) {
        const windowWidth = window.innerWidth;
        const margin =
          (windowWidth - BoundingClientRect?.width) / 2 +
          Math.abs(BoundingClientRect.left);

        const translateX = `translateX(calc(-50% + ${margin}px)`;
        megaMenuRef.current.style.transform = translateX;
      } else {
        megaMenuRef?.current?.classList.add("fixd-left-overflow");
      }
    } else if (BoundingClientRect.right > window.innerWidth) {
      if (horizontalMenu?.status) {
        const windowWidth = window.innerWidth;
        const margin =
          (windowWidth - BoundingClientRect?.width) / 2 +
          Math.abs(BoundingClientRect.right - windowWidth);

        const translateX = `translateX(calc(-50% - ${margin}px)`;
        megaMenuRef.current.style.transform = translateX;
      } else {
        megaMenuRef?.current?.classList.add("fixd-right-overflow");
      }
    }
  }, []);

  const hasSubcategory = Array.isArray(splitArray) && splitArray.length > 0;

  const ParentMenu_Redirection = () => {
    if (hasSubcategory) {
      return createSearchURLParams("home", { cat_id: id });
    } else {
      return createSearchURLParams("matrix-page", {
        cat_id: id,
        mode: "filter",
        page: 0,
      });
    }
  };

  const ChildMenu_Redirection = (data) => {
    if (Array.isArray(data.subcategory) && data.subcategory.length > 0) {
      return createSearchURLParams("home", { cat_id: data.id });
    } else {
      return createSearchURLParams("matrix-page", {
        cat_id: data.id,
        mode: "filter",
        page: 0,
      });
    }
  };

  return (
    <li className="dropdown position-static">
      <Link to={ParentMenu_Redirection()}>{cat_name}</Link>
      <ul
        className="mega-menu d-block"
        ref={megaMenuRef}
        style={
          horizontalMenu?.status
            ? horizontalMenu?.custom_dropdown_style?.mega_menu
            : {}
        }
      >
        <li
          className="d-flex"
          ref={listRef}
          style={
            horizontalMenu?.status
              ? horizontalMenu?.custom_dropdown_style?.columns
              : {}
          }
        >
          {splitArray.map((arr, index) => (
            <ul className="d-block" key={index}>
              {arr.map((data, i) => (
                <li key={i}>
                  <Link
                    to={ChildMenu_Redirection(data)}
                    className="categoryLinks"
                  >
                    {data.cat_name}
                  </Link>
                </li>
              ))}
            </ul>
          ))}
        </li>
      </ul>
    </li>
  );
}

function SingleListMenu({ cat_name, subcategory, id }) {
  
  const subMenuRef = useRef(null);
 

  useEffect(() => {
    if (!subMenuRef.current) return;
    const BoundingClientRect = subMenuRef?.current.getBoundingClientRect();
    if (BoundingClientRect.left < 0) {
      subMenuRef?.current?.classList.add("fixd-left-overflow");
    }

    if (BoundingClientRect.right > window.innerWidth) {
      subMenuRef?.current?.classList.add("fixd-right-overflow");
    }
  }, []);

  const ParentMenu_Redirection = () => {
    if (hasSubcategory) {
      return createSearchURLParams("home", { cat_id: id });
    } else {
      return createSearchURLParams("matrix-page", {
        cat_id: id,
        mode: "filter",
        page: 0,
      });
    }
  };

  const ChildMenu_Redirection = (data) => {
    if (Array.isArray(data?.subcategory) && data?.subcategory?.length > 0) {
      
      return createSearchURLParams("home", { cat_id: data.id });
    } else {
      
        return createSearchURLParams("matrix-page", {
          cat_id: data.id,
          mode: "filter",
          page: 0,
        })
      
    }
  };

  const hasSubcategory = subcategory.length > 0;
  return (
    <li className="dropdown">
      <Link to={ParentMenu_Redirection()}>{cat_name}</Link>

      {subcategory.length > 0 && (
        <ul
          className={`sub-menu`}
          ref={subMenuRef}
        >
          {subcategory.map((data, index) => (
            <li key={index}>
              <Link to={ChildMenu_Redirection(data)} className="categoryLinks">
                {data.cat_name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}

function MultiListMenuFn(subcategory, LIST_MAX_COUNT) {
  const splitArray = [];
  let tempArr = [];

  subcategory?.forEach((arr) => {
    if (tempArr.length < LIST_MAX_COUNT) {
      tempArr.push(arr);
    } else if (tempArr.length === LIST_MAX_COUNT) {
      splitArray.push(tempArr);
      tempArr = [];
      tempArr.push(arr);
    } else {
      tempArr.push(arr);
    }
  });

  splitArray.push(tempArr);

  return splitArray;
}

function MenuDropdownList({ cat_name, subcategory, setShowCategoryModal, id }) {
  const LIST_MAX_COUNT = 6;
  const isMultiListMenu = subcategory.length > 6;
  const horizontal_menu = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.horizontal_menu
  );
  
  if (horizontal_menu?.status) {
    // return subcategory.map((subCat) => (
    //   <HorizontalMenu
    //     {...subCat}
    //     key={subCat.id}
    //     setShowCategoryModal={setShowCategoryModal}
    //     horizontal_menu={horizontal_menu}
    //   />
    // ));
    return (
      <HorizontalMenu
        cat_name={cat_name}
        subcategory={subcategory}
        setShowCategoryModal={setShowCategoryModal}
        id={id}
        horizontal_menu={horizontal_menu}
      />
    );
  }

  const splitArray = MultiListMenuFn(subcategory, LIST_MAX_COUNT);

  return isMultiListMenu ? (
    <MultiListMenu
      splitArray={splitArray}
      cat_name={cat_name}
      setShowCategoryModal={setShowCategoryModal}
      id={id}
    />
  ) : (
    <SingleListMenu
      subcategory={subcategory}
      cat_name={cat_name}
      setShowCategoryModal={setShowCategoryModal}
      id={id}
    />
  );
}

function HorizontalMenu({
  cat_name,
  subcategory,
  setShowCategoryModal,
  id,
  horizontal_menu,
}) {
  const LIST_MAX_COUNT = horizontal_menu?.LIST_MAX_COUNT || 6;
  const isMultiListMenu = subcategory?.length > 6;
  const splitArray = MultiListMenuFn(subcategory, LIST_MAX_COUNT);

  return isMultiListMenu ? (
    <MultiListMenu
      splitArray={splitArray}
      cat_name={cat_name}
      setShowCategoryModal={setShowCategoryModal}
      id={id}
    />
  ) : (
    <SingleListMenu
      subcategory={subcategory}
      cat_name={cat_name}
      setShowCategoryModal={setShowCategoryModal}
      id={id}
    />
  );
}

export default MenuDropdownList;
