import React, { useEffect, useRef } from "react";
import { get_informationFromSessionID, ToastSuccess } from "../../../../Component/Helper";
import {useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import { get_fsv_products, get_fsv_products_calc, get_fsv_quantity, get_fsv_remark, get_fsv_selected_product, get_fsv_selected_subProducts, set_trigger_cart_btn, set_trigger_shortlist_btn, setConstants } from "../../../../redux/Reducer/reducer";
import { set_cart_params } from "../../Helper";
import { fetch_data_from_api } from "../../../../Api/APICalls";
import { ApiList } from "../../../../Api/ApiList";

function FSVFooter() {
  const navigate = useNavigate()
  const FSVFooterRef = useRef()
  const selected_subProducts = useSelector(get_fsv_selected_subProducts)
  const selectedProduct = useSelector(get_fsv_selected_product)
  const products_and_subproducts = useSelector(get_fsv_products)
  const product_calc = useSelector(get_fsv_products_calc)
  const remarks = useSelector(get_fsv_remark) || ""
  const quantity = useSelector(get_fsv_quantity) || "1"
  const dispatch = useDispatch();
  const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === "development";
  
  async function addToCart(type) {
    const userId = get_informationFromSessionID("userID")
    if (!userId) {
      return navigate("/login");
    }
    
    const add_to_cart_params = []

    if(selected_subProducts?.length > 0){
      selected_subProducts.forEach(subProd => {
        products_and_subproducts.forEach(prod => {
          if(prod.category_name === subProd){
            add_to_cart_params.push(set_cart_params(prod, product_calc[prod.category_name], type, quantity, remarks))
          }
        })
      })
    }else{
      
      add_to_cart_params.push(set_cart_params(selectedProduct, product_calc[selectedProduct.category_name], type, quantity, remarks))
    }

    IS_DEVELOPMENT_MODE && console.log(add_to_cart_params)
    const encoded_add_to_cart_params = encodeURI(
      JSON.stringify(add_to_cart_params)
    )
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      .replace(/%5B/g, "[")
      .replace(/%5D/g, "]");

    const Add_Cart_Wishlist_Response = await fetch_data_from_api(
      `${ApiList.cart}/${ApiList.addToCart}`,
      "add-to-cart",
      {
        Add_Cart: encoded_add_to_cart_params,
      }
    );
    
    if (Add_Cart_Wishlist_Response.constructor === Object) {
      if(window.innerWidth <= 575){

        type === "cart" && dispatch(set_trigger_cart_btn(true))
        type === "wishlist" && dispatch(set_trigger_shortlist_btn(true))
      }else{

        ToastSuccess(Add_Cart_Wishlist_Response.msg);
      }

      dispatch(
        setConstants({
          count: Add_Cart_Wishlist_Response?.cart_total,
        })
      );
    }
    
  }

  useEffect(() => {
    if(!FSVFooterRef?.current) return
    sessionStorage.setItem("FSVFooterHeight", FSVFooterRef?.current?.offsetHeight)
  },[])

  return (
    
      
        <div className="ec-single-qty d-flex position-sticky bottom-0 bg-white fsv-footer" ref={FSVFooterRef}>
          {/* <div className="qty-plus-minus">
                                                        <input className="qty-input" type="text" name="ec_qtybtn" value="1" />
                                                    </div> */}
          <div>
            <div className="ec-single-cart my-3 text-end cart-footer-btn">
              <button
                className="btn secondary_button_border btn_secondary_foreground_color btn_secondary_color border_radius btn-secondary"
                onClick={() => addToCart("wishlist")}
              >
                ADD TO SHORTLIST
              </button>
            </div>
          </div>
          <div>
            <div className="ec-single-cart my-3 text-end cart-footer-btn">
              <button
                className="btn btn-primary button_background_color"
                onClick={() => addToCart("cart")}
              >
                ADD TO CART
              </button>
            </div>
          </div>
        </div>
     
    
  );
}

export default FSVFooter;
