import { EMAIL_REGEX } from "../../../../utils";

function Textfield({field, register, errors}) {
    const {field_name, required, short_code} = field;
    
  return (
    <div>
      <label htmlFor="name">{field_name}
      {required === "1" && <span className="required-field">*</span>}
      </label>
      <input type="text" id="name" {...register(short_code, {required: {
        value: required === "1",
        message: `${field_name} is required`
        },
        pattern: {
          value: field_name === "Email" && EMAIL_REGEX,
          message: "Invaild Email ID"
        }
        
      }
        
        )}/>
      {errors[short_code]?.message && <p className="error-msg">{errors[short_code]?.message}</p>}
    </div>
  )
}

export default Textfield
