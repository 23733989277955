import { useEffect, useState } from "react";
import Select from "react-select";
import { fetch_data_from_api } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import {
  createSelectArrayForReactSelect,
  get_property_settings,
} from "../../../Component/Helper";

function Selectfield({ formParams, clsName, Controller, control, errors, options}) {
  
 return (
    <span className={clsName}>
      <label className="label">
        {formParams.field_name}
        {formParams?.required === "1" && (
          <span className="required-field">*</span>
        )}
      </label>
      <Controller
        name={formParams?.short_code}
        control={control}
        rules={{
          required: {
            value: formParams?.required === "1",
            message: `${formParams?.field_name} is required`
          },
        }}
        render={({ field }) => (
          <Select
            // tabIndex={i + 1}
            menuPlacement="top"
            placeholder={`Enter ${formParams.field_name}`}
            options={options || []}
            className="react-select-container r-container"
            classNamePrefix="react-select r-select"
            {...field}
          />
        )}
      />

      <div className="required-field">
        {errors[formParams?.short_code]?.message || ""}
      </div>
    </span>
  );
}

export default Selectfield;
