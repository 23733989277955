import React, { useEffect, useState } from "react";
import { depended_child_func } from "../../../../../Component/Helper";

function ButtonFlow({ clsName, paramsData, paramsDataIndex, default_select_value, register, setValue, set_depended_child, depended_child }) {
  const [btnValue, setBtnValue] = useState(default_select_value)
  const default_required = paramsData?.required === "1"
  const depended_child_required = depended_child?.child_short_code.includes(paramsData?.short_code) && depended_child?.required
  useEffect(()=>{
    
    setValue(paramsData?.short_code, default_select_value)
  }, [])
  return (
    <div className={clsName}>
      <ul className="buttonflow overflow-scroll example">
        {paramsData.control_value.length > 0 &&
          paramsData.control_value.map((value, i) => {
            return (
              
                <button
                  defaultValue={value}
                  key={i}
                  onClick={(e) => {
                    e.preventDefault()
                    setValue(paramsData?.short_code, value)
                    setBtnValue(value)
                    depended_child_func(paramsData, value, set_depended_child)
                  }}
                  className={
                    
                    btnValue === value ? `selected` : "not-selected"
                    // formData[paramsData?.short_code] === value ? `selected` : "not-selected"
                  }
                  id="btn-span"
                  tabIndex={paramsDataIndex + 1}
                  {...register(paramsData?.short_code, {
                    required: {
                      value: depended_child_required || default_required,
                      message: `${paramsData?.short_code} is required`
                    }
                  })}
                >
                  <div>
                    <span className="text-nowrap">{value}</span>
                  </div>
                </button>
              
            );
          })}
      </ul>
    </div>
  );
}

export default ButtonFlow;
