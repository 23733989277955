import React from 'react'

function FooterCopy() {
    return (
        <div className="footer-bottom">
            <div className="container-fluid">
                <div className="row align-items-center">
                    {/* -- Footer Copyright Start -- */}
                    <div className="col footer-copy">
                        <div className="footer-bottom-copy ">
                            <div className="ec-copy">
                                <span>
                                    Copyright © {new Date().getFullYear()}.
                                </span>
                                <span>
                                    Powered by <a href="http://triologic.com/" target="_blank">Triologic Enterprises Pvt. Ltd.</a>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default FooterCopy
