import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import verified from "../assets/images/verified.png";

const Notifier = ({ show, onHide, header, showFooter, title, subtitle }) => {
  return (
    <Modal
      show={show}
      centered
      // onExit={() => setShow(false)}
      close
      onHide={onHide}
      className="notifier"
    >
      <ModalHeader closeButton>
        <ModalTitle className="fw-bold fs-3">{header}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <img src={verified} alt="" className="verified" />
        {title && (
          <>
            <h3>
              <b>{title}</b>
            </h3>
          </>
        )}
        <span className="label">{subtitle}</span>
      </ModalBody>
      {showFooter && (
        <ModalFooter>
          <button
            className="btn btn-primary button_background_color modal-btn"
            //   onClick={() => {
            //     deleteItem(id, company_code, type);
            //   }}
          >
            {" "}
            Yes
          </button>
          <button
            className="btn btn-primary button_background_color modal-btn"
            onClick={onHide}
          >
            No
          </button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default Notifier;
